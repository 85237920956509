import React, {useState, useEffect} from 'react';
import { Button, Tooltip, Box } from '@Components/';
import useAuthApi from '@Apis/useAuthApi';
import {useSelector} from "react-redux";
import {getVersionData} from "@Redux/version/selector";
import {parseDate} from "@Util/moment";
import {isEmpty} from "@Util/utils";

const NavBottom = React.memo(() => {
    const [version, setVersion] = useState('');
    const { getLogoutApi } = useAuthApi();
    const reduxVersion = useSelector(getVersionData);

    const handleLogoutClick = (e) => {
        logOutApi();
    };

    const logOutApi = async () => {
        await getLogoutApi();
    };

    useEffect(
        () => {
            if (!isEmpty(reduxVersion)) {
                const { env, version, utc } = reduxVersion || {};
                const text = `${env} :: ${version} | ${parseDate(utc, 'YYYY-MM-DD HH:mm:ss')} `;
                setVersion(text);
            }
        },
        // eslint-disable-next-line
        [reduxVersion]
    );

    return (
        <Box className="nav-bottom">
            <Tooltip title={version} disableFocusListener>
                <Button variant="contained" size="large" onClick={handleLogoutClick} square >
                    登出
                </Button>
            </Tooltip>
        </Box>
    );
});

export default NavBottom;

import React from 'react';
import {Box, IconButton} from '@Components/';
import NavLogo from '@Images/nav_logo.svg';
import {Bars as BarsIcon} from '@SvgIcon/';
import clsx from 'clsx';

const TopBarStart = (props) => {
    const {className, onToggleMenu} = props;
    

    return (
        <Box className={clsx('top-bar-start', className)}>
            <IconButton onClick={() => typeof onToggleMenu === 'function' && onToggleMenu()}>
                <BarsIcon/>
            </IconButton>
            <Box className={'logo-wrap'}>
                <Box component={'img'} src={NavLogo} className={'logo'} alt={'Curves總部'}/>
            </Box>
        </Box>
    );
};

export default TopBarStart;

import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Card, Button, Form, TextField, FormControlLabel, Switch, Stack } from '@Components/';
import usePromotionPlanApi from '@Apis/usePromotionPlanApi';
import { usePromotionPlanContext } from '@Local/PromotionPlan/Components/PromotionPlanContext';
import { PROMOTION_PLAN_OVERVIEW_ROUTER } from '@Router/routerPath';
import { isEmpty, objectToQueryString } from '@Util/utils';
import { useHistory } from 'react-router-dom';

const PromotionPlanSearchBar = (props) => {
    const { className } = props;
    const history = useHistory();
    const { setSourceData, planType, bookmark, setBookmarkNum, setRefresh } = usePromotionPlanContext();
    const { getPromotionPlanOverviewApi } = usePromotionPlanApi();
    const filterRef = useRef(null);
    const hasExpiredRef = useRef(null);

    const getParams = (bookmark) => {
        const result = { ...bookmark, planType };

        if (filterRef.current && filterRef.current.getResult) {
            Object.assign(result, { filter: filterRef.current.getResult() });
        }

        const hasExpiredEl = hasExpiredRef.current;
        if (hasExpiredEl) {
            Object.assign(result, { hasExpired: hasExpiredEl.checked });
        }

        return result;
    };

    /** 更新網址上的搜尋條件 **/
    const updateUrlWithSearchQuery = (params) => {
        let url = PROMOTION_PLAN_OVERVIEW_ROUTER;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const handlePromotionPlanOverviewApi = async (params) => {
        let resp = await getPromotionPlanOverviewApi(params);
        if (resp) {
            setSourceData(resp);
        }
        updateUrlWithSearchQuery(params);
    };

    const handleSubmitForm = () => {
        const params = getParams();
        handlePromotionPlanOverviewApi(params);
        setBookmarkNum(1);
    };

    useEffect(
        () => {
            const params = getParams(bookmark);
            handlePromotionPlanOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex, planType],
    );

    useEffect(() => {
        setRefresh(handleSubmitForm);
        // eslint-disable-next-line
    }, []);

    return (
        <Card className={clsx('search-bar', className)}>
            <Card.CardContent className={'px-4 py-3'}>
                <Form onSubmit={handleSubmitForm}>
                    <Grid className={className} container spacing={3}>
                        <Grid item xs={6}>
                            <TextField ref={filterRef} defaultValue={''} InputProps={{ placeholder: '搜尋項目名稱或項目編號' }} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <Stack className={'flex-auto'} spacing={3}>
                                <FormControlLabel control={<Switch defaultChecked={false} inputRef={hasExpiredRef} />} prefixLabel={'包含未生效項目'} />
                                <Button type={'submit'} variant={'contained'}>
                                    搜尋
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default PromotionPlanSearchBar;

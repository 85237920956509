import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const NoPushWhey = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#D02952', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M2.2,12c0-5.4,4.4-9.8,9.8-9.8
	c2.4,0,4.5,0.8,6.2,2.2c-0.1,0-0.2,0.1-0.3,0.2l-1.1,1.1l0-0.3h-3v0h-1.1v0H7.1l0.8,9.2L4.7,18c-0.1,0.1-0.1,0.2-0.2,0.3
	C3.1,16.5,2.2,14.4,2.2,12z M13.3,9.3h-4L9.7,13l-0.7,0.7L8.2,6.6h7.6l0,0.3L13.3,9.3z M15.5,9.9l-0.8,7.5H9.3l-0.1-1.2l0.7-0.7
	l0.1,1.2h4l0.5-5.8L15.5,9.9z M12,21.8c-2.4,0-4.5-0.8-6.2-2.2c0.1,0,0.2-0.1,0.3-0.2l2.1-2.1l0.1,1.2h7.6l0.8-9.7L19.3,6
	c0.1-0.1,0.1-0.2,0.2-0.3c1.4,1.7,2.2,3.9,2.2,6.2C21.8,17.4,17.4,21.8,12,21.8z"
            />
        </SvgIcon>
    );
});

export default NoPushWhey;

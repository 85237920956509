import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { AutocompleteField, Button, Card, Form, MenuItem, SelectField, Stack } from '@Components/';
import { refIsRequiredError, isEmpty, objectToQueryString, paramsToObject } from '@Util/utils';
import { useClubManagementContext } from '@Local/ClubManagement/Component/ClubManagementContext';
import { CLUB_MANAGEMENT_MEMBERSHIP_ROUTER } from '@Router/routerPath';

const ClubManagementMembershipSearchBar = (props) => {
    const { className } = props;
    const { setSourceData, setRefresh } = useClubManagementContext();
    const history = useHistory();
    const [paramsData, setParamsData] = useState({});
    const { clubID, memberID } = paramsData;
    const [options, setOptions] = useState({});
    const { clubOptions = [], memberOptions = [] } = options;
    const clubRef = useRef(null);
    const memberRef = useRef(null);
    const preloadRef = useRef(false);

    const updateUrlWithSearchQuery = (params) => {
        let url = CLUB_MANAGEMENT_MEMBERSHIP_ROUTER;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getOptionsApi = () => {
        let resp = false;
        if (resp) {
            setOptions(resp);
            const { location } = history;
            const search = location.search;
            const params = search ? paramsToObject(search) : getParams();
            if (!isEmpty(params)) {
                setParamsData(params);
            }
            preloadRef.current = false;
            getOverviewApi(params);
        }
    };

    const getParams = () => {
        let result = {};
        if (clubRef.current && clubRef.current.getResult) {
            Object.assign(result, { clubID: clubRef.current.getResult() });
        }
        if (memberRef.current && memberRef.current.getResult) {
            Object.assign(result, { memberID: memberRef.current.getResult() });
        }
        return result;
    };

    const getOverviewApi = (params) => {
        let resp = false;

        if (resp) {
            setSourceData(resp);
        }
        updateUrlWithSearchQuery(params);
        preloadRef.current = true;
        console.log(params, 'getOverviewApi');
    };

    const handleSearchOnClick = (e) => {
        const isError = refIsRequiredError([clubRef, memberRef]);
        if (!isError) {
            const params = getParams();
            getOverviewApi(params);
        }
    };

    useEffect(
        () => {
            getOptionsApi();
            setRefresh(handleSearchOnClick);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={clsx('main-search-bar', className)}>
            <Card.CardContent className={'px-4 py-3'}>
                <Form onSubmit={handleSearchOnClick}>
                    <Stack spacing={2} alignItems={'center'}>
                        <Stack className={'w-100'} spacing={2} justifyContent={'start'}>
                            <SelectField key={clubID} ref={clubRef} label={'店鋪'} defaultValue={clubID} direction="row" displayEmpty fullWidth>
                                {clubOptions
                                    .filter((item) => !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                            </SelectField>
                            <AutocompleteField
                                key={memberID}
                                ref={memberRef}
                                label={'會員'}
                                defaultValue={memberID}
                                options={memberOptions}
                                optionKeys={['name', 'mobile', 'no']}
                                primaryKey={'id'}
                                inputProps={{
                                    placeholder: '請輸入會員姓名/手機號碼/會員編號，擇一即可',
                                }}
                                // onChange={handleChangeMember}
                                direction={'row'}
                                fullWidth
                                required
                            />
                        </Stack>
                        <Button className={'white-space-nowrap'} type={'submit'} variant={'contained'}>
                            搜尋
                        </Button>
                    </Stack>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default ClubManagementMembershipSearchBar;

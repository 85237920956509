import React, {useRef} from 'react';
import {Dialog, DialogContent, Grid, DialogActions} from '@mui/material';
import {GradientColorHeader} from "@Local/Components/Dialog";
import {PointsAuditCountsTable} from '@Local/Points/Audit';
import usePointsRewardAudit from "@Apis/usePointsRewardAudit";
import {Button, TextField} from "@Components/";

const PointsAuditCountsDialog = React.forwardRef((props, ref) => {
    const {open: openProps = false, onClose: onCloseProps, sourceData: sourceDataProps = {}, refresh} = props;
    if (!openProps) return null;
    const {postPointRewardAuditIsPassApi} = usePointsRewardAudit();
    const {
        title = '',
        selected = [],
        nextStatus = 0,
        visibleMemo = false,
    } = sourceDataProps || {};
    const memoRef = useRef(null);

    const getParams = () => {
        return {
            recordIDs: selected.map(({ recordID }) => recordID),
            memo: memoRef.current ? memoRef.current.getResult() : '',
            nextStatus
        };
    };
    
    const handleConfirm = () => {
        doAuditApi(getParams());
    };

    const doAuditApi = async (params) => {
        const resp = await postPointRewardAuditIsPassApi(params, title);
        if (resp) {
            refresh();
            onCloseProps();
        }
    };

    return (
        <Dialog PaperProps={{className: 'wx-90rem'}} open={openProps} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>{title}</GradientColorHeader>
            <DialogContent>
                <PointsAuditCountsTable sourceData={selected}/>
                {visibleMemo && <Grid xs={12} item>
                    <TextField ref={memoRef} label="駁回說明" rows={4} multiline fullWidth/>
                </Grid>}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleConfirm}>
                    確認
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointsAuditCountsDialog;
import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material/';
import { Form, Card, Button, TextField, Stack, FormControlLabel, Switch } from '@Components/';
import usePointsActivityApi from '@Apis/usePointsActivityApi';
import { usePointsContext } from '@Local/Points/Components/PointsContext';
import { initBookmark } from '@Local/Points/staticData';

const PointsActivitySearchBar = (props) => {
    const { setSourceData, pageModel, setPageModel, setRefreshRef } = usePointsContext();
    const { getPointsActivityOverviewApi } = usePointsActivityApi();
    const filterRef = useRef(null);
    const isVisibleRef = useRef(null);
    const preloadRef = useRef(false);

    const handleRefresh = () => {
        const params = filterRef.current.getResult();
        doSearchOverviswApi({ name: params, isAll: isVisibleRef.current.checked, ...pageModel });
    };

    const handleSearchOnClick = () => {
        const params = filterRef.current.getResult();
        doSearchOverviswApi({ name: params, isAll: isVisibleRef.current.checked, ...initBookmark() });
    };

    const doSearchOverviswApi = async (params) => {
        const resp = await getPointsActivityOverviewApi(params);
        if (resp) {
            const { list, ...other } = resp;
            setSourceData(list);
            setPageModel(other);
            preloadRef.current = true;
        }
    };

    useEffect(
        () => {
            if (preloadRef.current) {
                handleSearchOnClick();
            }
        },
        // eslint-disable-next-line
        [pageModel.pageIndex]
    );

    useEffect(
        () => {
            handleSearchOnClick();
        },
        // eslint-disable-next-line
        []
    );

    setRefreshRef(handleRefresh);

    return (
        <Form onSubmit={handleSearchOnClick}>
            <Card className="my-3">
                <Card.CardContent className="p-4 px-2">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextField ref={filterRef} InputProps={{ placeholder: '請輸入活動名稱' }} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <Stack spacing={2}>
                                <FormControlLabel
                                    className={'m-0'}
                                    control={<Switch inputProps={{ ref: isVisibleRef }} defaultChecked={true} />}
                                    prefixLabel={'是否生效'}
                                    labelPlacement={'start'}
                                />
                                <Button className="ml-2" variant="contained" type="submit">
                                    搜尋
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
        </Form>
    );
};

export default PointsActivitySearchBar;

import React from 'react';
import useControlled from '@Util/hook/useControlled';
import useForkRef from '@Util/hook/useForkRef';
import RadioGroupContext from './RadioGroupContext';
import FormGroup from '../formGroup/FormGroup';

const RadioGroup = React.forwardRef((props, ref) => {
    const { inputRef, className, defaultValue: defaultValuePorp, value: valueProp, onChange, color, children, name: propsName, ...other } = props;
    const rootRef = React.useRef(null);
    const name = propsName;
    const [value, setValueState] = useControlled({
        controlled: valueProp,
        default: defaultValuePorp,
    });

    //Allen Check
    React.useImperativeHandle(
        inputRef,
        () => ({
            getResult: () => {
                let source = {};
                source[name || 'radioGroupName'] = value;
                return {
                    ...source,
                };
            },
        }),
        [value, name]
    );

    const handleRef = useForkRef(ref, rootRef);

    const handleChange = (event) => {
        setValueState(event.target.value);
        onChange && onChange(event, event.target.value);
    };

    const defaultProperty = {
        role: 'radiogroup',
        className,
        ref: handleRef,
        ...other,
    };

    const childValue = { onChange: handleChange, value, color };

    return (
        <RadioGroupContext.Provider value={childValue}>
            <FormGroup {...defaultProperty}>{children}</FormGroup>
        </RadioGroupContext.Provider>
    );
});

export default RadioGroup;

import React, { useRef, useState } from 'react';
import { Box, Typography } from '@Components/';
import ClubManagementMembershipSearchBar from '@Local/ClubManagement/ClubManagementMembershipSearchBar';
import ClubManagementMembershipContent from '@Local/ClubManagement/ClubManagementMembershipContent';
import ClubManagementDialogBox from '@Local/ClubManagement/Dialog/ClubManagementDialogBox';
import { ClubManagementProvider } from '@Local/ClubManagement/Component/ClubManagementContext';

const ClubManagementMembershipPage = () => {
    const [sourceData, setSourceData] = useState({});
    const dialogRef = useRef();
    const refreshRef = useRef();

    const childContext = {
        sourceData,
        setSourceData,
        dialogRef,
        refresh: refreshRef,
        setRefresh: (fn) => (refreshRef.current = fn),
    };

    return (
        <ClubManagementProvider value={childContext}>
            <Box className={'container main-container-spacing'}>
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        會員會籍管理
                    </Typography>
                </Box>
                <ClubManagementMembershipSearchBar className={'mb-1'} />
                <ClubManagementMembershipContent />
                <ClubManagementDialogBox ref={dialogRef} />
            </Box>
        </ClubManagementProvider>
    );
};

export default ClubManagementMembershipPage;

import React from 'react';
import clsx from 'clsx';
import SelectInput from './SelectInput';
import OutlinedInput from '../outlinedInput/OutlinedInput';
import useFormControl from '../formControl/useFormControl';
import formControlState from '../formControl/formControlState';
import { ArrowDropDown as ArrowDropDownIcon } from '@SvgIcon/';
import useForkRef from '@Util/hook/useForkRef';

const Select = React.forwardRef((props, ref) => {
    const {
        autoWidth = false,
        children,
        className,
        displayEmpty = false,
        disabled = false,
        id,
        input,
        inputProps,
        label,
        labelId,
        multiple = false,
        onClose,
        onOpen,
        open,
        readOnly = false,
        renderValue,
        variant: variantProps = 'outlined',
        IconComponent = ArrowDropDownIcon,
        MenuProps,
        SelectDisplayProps,
        ...other
    } = props;

    const inputComponent = SelectInput;
    const formControl = useFormControl();
    const fcs = formControlState({
        props,
        formControl,
        states: ['variant'],
    });

    const variant = fcs.variant || variantProps;
    const InputComponent = <OutlinedInput />;
    const inputComponentRef = useForkRef(ref, InputComponent.ref);

    return React.cloneElement(InputComponent, {
        inputComponent,
        inputProps: {
            children,
            IconComponent,
            variant,
            type: undefined, // We render a select. We can ignore the type provided by the `Input`.
            disabled,
            readOnly,
            multiple,
            ...{
                autoWidth,
                displayEmpty,
                labelId,
                MenuProps,
                onClose,
                onOpen,
                open,
                renderValue,
                SelectDisplayProps: { id, ...SelectDisplayProps },
            },
            ...inputProps,
            ...(input ? input.props.inputProps : {}),
        },
        notched: true,
        ref: inputComponentRef,
        className: clsx(InputComponent.props.className, className),
        ...other,
    });
});

export default Select;

import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, MenuItem, SelectField, Stack, Typography } from '@Components/';
import MemberPanel from '@Local/ClubManagement/Component/MemberPanel';
import ClubManagementMemberMembershipItemTable from '@Local/ClubManagement/ClubManagementMemberMembershipItemTable';
import ClubManagementDialogBox from '@Local/ClubManagement/Dialog/ClubManagementDialogBox';
import { useHistory } from 'react-router-dom';
import { CLUB_MANAGEMENT_MEMBER_MEMBERSHIP_ROUTER } from '@Router/routerPath';
import { displayDateText } from '@Util/moment';

const fakeMembershipItemCategoryOptions = [
    { value: 99, label: '全部' },
    { value: 1, label: '創建類' },
    { value: 2, label: '贈送類' },
    { value: 3, label: '展延類' },
    { value: 4, label: '暫停類' },
    { value: 5, label: '終止類' },
];

const ClubManagementMembershipPage = (props) => {
    const { match } = props;
    const { membershipID } = match.params || {};
    const history = useHistory();
    const [sourceData, setSourceData] = useState({});
    const { membershipTypeText = '續約', startDate = ' 2022/07/01', endDate = '2024/08/02' } = sourceData;
    const [options, setOptions] = useState({});
    const { membershipItemCategoryOptions = fakeMembershipItemCategoryOptions } = options;
    const dialogRef = useRef(null);

    const getMembershipApi = () => {
        let resp = false;
        if (resp) {
            setSourceData(resp);
        }
    };

    const getOptionsApi = () => {
        let resp = false;
        if (resp) {
            setOptions(resp);
            getMembershipApi(membershipID);
        }
    };

    const goBack = () => {
        history.push(CLUB_MANAGEMENT_MEMBER_MEMBERSHIP_ROUTER);
    };

    useEffect(
        () => {
            getOptionsApi();
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Box className={'container main-container-spacing'}>
            <Box className={'btn-group my-2'}>
                <Button variant={'contained'} color={'primary'} onClick={goBack}>
                    返回會員會籍合約
                </Button>
            </Box>
            <MemberPanel sourceData={sourceData} className={'mb-1'} />
            <Card>
                <Card.CardContent className={'p-3'}>
                    <Stack className={'mb-2'} spacing={'3'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography className={'font-weight-bold'} variant={'h4'}>
                            {`#${membershipID}, ${membershipTypeText}, ${displayDateText(startDate, endDate)}`}
                        </Typography>
                        <Stack spacing={3} alignItems={'center'}>
                            <SelectField label={'分類'} defaultValue={'99'} direction={'row'}>
                                {membershipItemCategoryOptions.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </SelectField>
                            <Button variant={'contained'} color={'primary'} onClick={() => dialogRef.current && dialogRef.current.openMembershipItemDialog()}>
                                新增項目
                            </Button>
                        </Stack>
                    </Stack>
                    <ClubManagementMemberMembershipItemTable sourceData={sourceData} dialogRef={dialogRef} />
                </Card.CardContent>
            </Card>
            <ClubManagementDialogBox ref={dialogRef} />
        </Box>
    );
};

export default ClubManagementMembershipPage;

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import routers from '@Router/router';
import { List, ListItemButton, ListItemText, Collapse, Skeleton, Stack, ListItemIcon, Box } from '@Components/';
import {
    UserHome as UserHomeIcon,
    UserAlt as UserAltIcon,
    UserPlus as UserPlusIcon,
    DocumentFile as DocumentFileIcon,
    PaperPlane as PaperPlaneIcon,
    DollarSign as DollarSignIcon,
    Developer as DeveloperIcon,
    ChartColumnSolid as ChartColumnSolidIcon,
    Ruler as RulerIcon,
    HumanResource as HumanResourceIcon,
    Points as PointsIcon,
} from '@SvgIcon/';
import useNavigationApi from '@Apis/useNavigationApi';
import { flatDeepArray } from '@Util/utils';
import { setMenusData } from '@Redux/menus/action';
// import router from '@Router/router';

/**
 * 對應左側Menu Icon
 */
const menuIconMap = {
    home: <UserHomeIcon />,
    member: <UserAltIcon />,
    analysis: <ChartColumnSolidIcon />,
    customer: <UserPlusIcon />,
    document: <DocumentFileIcon />,
    message: <PaperPlaneIcon />,
    accountBook: <DollarSignIcon />,
    measure: <RulerIcon />,
    developer: <DeveloperIcon />,
    hr: <HumanResourceIcon />,
    points: <PointsIcon />,
};

const checkRouter = (target, key) => {
    return target.find(({ frontKey }) => frontKey === key);
};

/**
 * 子層選單
 */
const SubNavComponent = (props) => {
    const { onCloseMenu } = props;
    const { sourceData = [], isOpen = false } = props;

    return (
        <React.Fragment>
            {sourceData.length !== 0 && (
                <Collapse in={isOpen}>
                    <List component="div" className="list-sub" disablePadding>
                        {sourceData.map(({ frontKey, menuName, isVisible }) => {
                            let isComponent = null;
                            const isExist = routers.find(({ frontKey: _key }) => frontKey === _key);
                            if (isVisible && isExist) {
                                const item = checkRouter(routers, frontKey);
                                let newProperty = {};
                                // 給href就會顯示出來
                                if (item && item.href) {
                                    newProperty = {
                                        component: NavLink,
                                        to: item.href,
                                        activeClassName: 'list-item-button-selected',
                                        onClick: onCloseMenu,
                                    };
                                } else {
                                    return null;
                                }
                                const defaultProperty = {
                                    key: frontKey,
                                    ...newProperty,
                                };
                                isComponent = (
                                    <ListItemButton {...defaultProperty}>
                                        <ListItemText primary={menuName} />
                                    </ListItemButton>
                                );
                            }
                            return isComponent;
                        })}
                    </List>
                </Collapse>
            )}
        </React.Fragment>
    );
};

/**
 * 主選單
 */
const NavContent = (props) => {
    const { onCloseMenu } = props;
    const _dispatch = useDispatch();
    const { getMeMenusApi } = useNavigationApi();
    const [menuList, setMenuList] = useState([]);
    const [openAry, setOpenAry] = useState({});

    const handleItemCollapseClick = (key) => (e) => {
        setOpenAry((prev) => {
            let newState = {};
            if (openAry[key] === undefined) newState[key] = true;
            else newState[key] = !openAry[key];
            return {
                ...newState,
            };
        });
    };

    const getInitData = async () => {
        const resp = await getMeMenusApi();
        if (resp) {
            const { menus = [] } = resp;
            setMenuList(menus);
            const flatMenu = flatDeepArray(menus);
            _dispatch(setMenusData(flatMenu));
        }
    };

    useEffect(
        () => {
            getInitData();
            return () => console.info('重刷頁面或Component掛掉,才會看到此訊息');
        },
        // eslint-disable-next-line
        [],
    );

    // 開發專用區
    const devMenu = React.useMemo(
        () => {
            const { hostname } = window.location;
            if (hostname === 'localhost' || /192.168.0/.test(hostname)) {
                const { developerRouter } = require('@Router/DevelopmentRouter');
                const { menuName, children, frontKey } = developerRouter;
                const nestKey = frontKey;
                return (
                    <React.Fragment>
                        <ListItemButton onClick={handleItemCollapseClick(nestKey)}>
                            <ListItemIcon>
                                <DeveloperIcon />
                            </ListItemIcon>
                            <ListItemText primary={menuName} />
                        </ListItemButton>
                        <Collapse in={openAry[nestKey]}>
                            <List component="div" className="list-sub" disablePadding>
                                {children.map((target) => {
                                    const key = `menu-list-sub-${target.frontKey}`;
                                    return (
                                        <ListItemButton key={key} component={NavLink} to={target.href} activeClassName={`list-item-button-selected`}>
                                            <ListItemText primary={target.menuName} />
                                        </ListItemButton>
                                    );
                                })}
                            </List>
                        </Collapse>
                    </React.Fragment>
                );
            } else {
                return null;
            }
        },
        // eslint-disable-next-line
        [openAry],
    );

    return (
        <Box className={'nav-frame'}>
            <Box className="nav-contnet">
                {menuList.length !== 0 ? (
                    <List component="nav">
                        {menuList.map(({ menuName, frontKey, children, iconClass, isVisible }) => {
                            const nestKey = frontKey;
                            const item = checkRouter(routers, frontKey);
                            let newProperty = {};
                            if (item) {
                                if (item.href) {
                                    newProperty = Object.assign(newProperty, {
                                        component: NavLink,
                                        to: item.href,
                                        activeClassName: 'list-item-button-selected',
                                        onClick: onCloseMenu,
                                    });
                                } else {
                                    newProperty = {
                                        onClick: handleItemCollapseClick(nestKey),
                                    };
                                }
                            } else {
                                return null;
                            }
                            return (
                                <React.Fragment key={nestKey}>
                                    {isVisible ? (
                                        <React.Fragment>
                                            <ListItemButton {...newProperty}>
                                                <ListItemIcon>{menuIconMap[iconClass]}</ListItemIcon>
                                                <ListItemText primary={menuName} />
                                            </ListItemButton>
                                            {Array.isArray(children) && (
                                                <SubNavComponent sourceData={children} isOpen={openAry[nestKey]} onCloseMenu={onCloseMenu} />
                                            )}
                                        </React.Fragment>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                        {devMenu}
                    </List>
                ) : (
                    <Stack className="m-2" direction="column">
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                        <Skeleton height={60} />
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

export default NavContent;

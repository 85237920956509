import React, {useImperativeHandle, useState} from 'react';
import {Box, Typography, Stack, Button} from '@Components/';
import {isEmpty} from '@Util/utils';
import {usePointsContext} from '@Local/Points/Components/PointsContext';

const PointsAuditCountsBox = React.forwardRef((props, ref) => {
    const {onRefresh: refreshProps} = props;
    const {auditStatusMapFn, getDialog} = usePointsContext();
    const [sourceData, setSourceData] = useState([]);
    const auditStatusMap = auditStatusMapFn();

    const handleOnPassTrial = () => {
        if (isEmpty(sourceData)) return;

        const dialog = getDialog();
        dialog.openPointAuditDialog(
            {
                title: '核可點數',
                selected: sourceData,
                nextStatus: auditStatusMap.pass,
                visibleMemo: false
            },
            () => refreshProps()
        );
    };

    const handleOnDenyTrial = () => {
        if (isEmpty(sourceData)) return;
        const dialog = getDialog();
        dialog.openPointAuditDialog(
            {
                title: '駁回點數',
                selected: sourceData,
                nextStatus: auditStatusMap.deny,
                visibleMemo: true
            },
            () => refreshProps()
        );
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                setSelectedList: (target) => {
                    setSourceData(target);
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Box className="p-4">
            <Box className="points-audit-edit-box">
                <Typography variant="h6">請選擇批次審核名單</Typography>
                <Stack justifyContent="space-between">
                    <Typography variant="h6">
                        選擇筆數：<Typography component="span">{sourceData.length}</Typography>
                    </Typography>
                    {sourceData.length !== 0 && (
                        <Stack spacing="2">
                            <Button variant="contained" color="error" onClick={handleOnDenyTrial}>
                                駁回
                            </Button>
                            <Button variant="contained" color="info" onClick={handleOnPassTrial}>
                                核可
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Box>
    );
});

export default PointsAuditCountsBox;

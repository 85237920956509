import React from 'react';
import { debounce, ownerWindow } from '@Util/utils';
import useEnhancedEffect from '@Util/hook/useEnhancedEffect';
import useForkRef from '@Util/hook/useForkRef';

// const staticClass = `textarea-autosize`;
const styles = {
    shadow: {
        // Visibility needed to hide the extra text area on iPads
        visibility: 'hidden',
        // Remove from the content flow
        position: 'absolute',
        // Ignore the scrollbar width
        overflow: 'hidden',
        height: 0,
        top: 0,
        left: 0,
        // Create a new layer, increase the isolation of the computed values
        transform: 'translateZ(0)',
    },
};

const getStyleValue = (computedStyle, property) => {
    return parseInt(computedStyle[property], 10) || 0;
};

const TextareaAutosize = React.forwardRef((props, ref) => {
    const { onChange, maxRows, minRows = 1, style, value, ...other } = props;
    const { current: isControlled } = React.useRef(value != null);
    const inputRef = React.useRef(null);
    const handleRef = useForkRef(ref, inputRef);
    const shadowRef = React.useRef(null);
    const renders = React.useRef(0);
    const [state, setState] = React.useState({});

    const syncHeight = React.useCallback(() => {
        const input = inputRef.current;
        const containerWindow = ownerWindow(input);
        const computedStyle = containerWindow.getComputedStyle(input);
        // If input's width is shrunk and it's not visible, don't sync height.
        if (computedStyle.width === '0px') return;

        const inputShallow = shadowRef.current;
        inputShallow.style.width = computedStyle.width;
        inputShallow.value = input.value || props.placeholder || 'x';
        if (inputShallow.value.slice(-1) === '\n') {
            // Certain fonts which overflow the line height will cause the textarea
            // to report a different scrollHeight depending on whether the last line
            // is empty. Make it non-empty to avoid this issue.
            inputShallow.value += ' ';
        }

        const boxSizing = computedStyle['box-sizing'];
        const padding = getStyleValue(computedStyle, 'padding-bottom') + getStyleValue(computedStyle, 'padding-top');
        const border = getStyleValue(computedStyle, 'border-bottom-width') + getStyleValue(computedStyle, 'border-top-width');

        // The height of the inner content
        const innerHeight = inputShallow.scrollHeight;

        // Measurement height of a textarea with a single row
        inputShallow.value = 'x';
        const singleRowHeight = inputShallow.scrollHeight;

        // The height of the outer content
        let outerHeight = innerHeight;
        if (minRows) outerHeight = Math.max(Number(minRows) * singleRowHeight, outerHeight);
        if (maxRows) outerHeight = Math.min(Number(maxRows) * singleRowHeight, outerHeight);
        outerHeight = Math.max(outerHeight, singleRowHeight);

        // Take the box sizing into account for applying this value as a style.
        const outerHeightStyle = outerHeight + (boxSizing === 'border-box' ? padding + border : 0);
        const overflow = Math.abs(outerHeight - innerHeight) <= 1;

        setState((prevState) => {
            // Need a large enough difference to update the height.
            // This prevents infinite rendering loop.
            if (
                renders.current < 20 &&
                ((outerHeightStyle > 0 && Math.abs((prevState.outerHeightStyle || 0) - outerHeightStyle) > 1) || prevState.overflow !== overflow)
            ) {
                renders.current += 1;
                return {
                    overflow,
                    outerHeightStyle,
                };
            }
            return prevState;
        });
    }, [maxRows, minRows, props.placeholder]);

    React.useEffect(() => {
        const handleResize = debounce(() => {
            renders.current = 0;
            syncHeight();
        });
        const containerWindow = ownerWindow(inputRef.current);
        containerWindow.addEventListener('resize', handleResize);
        let resizeObserver;

        if (typeof ResizeObserver !== 'undefined') {
            resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(inputRef.current);
        }

        return () => {
            handleResize.clear();
            containerWindow.removeEventListener('resize', handleResize);
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [syncHeight]);

    useEnhancedEffect(() => {
        syncHeight();
    });

    React.useEffect(() => {
        renders.current = 0;
    }, [value]);

    const handleChange = (event) => {
        renders.current = 0;
        !isControlled && syncHeight();
        onChange && onChange(event);
    };

    return (
        <React.Fragment>
            <textarea
                ref={handleRef}
                onChange={handleChange}
                rows={minRows}
                style={{
                    height: state.outerHeightStyle,
                    // Need a large enough difference to allow scrolling.
                    // This prevents infinite rendering loop.
                    overflow: state.overflow ? 'hidden' : null,
                    ...style,
                }}
                {...other}
            />
            <textarea
                aria-hidden
                readOnly
                className={other.className}
                tabIndex={-1}
                ref={shadowRef}
                style={{
                    ...styles.shadow,
                    ...style,
                    padding: 0,
                }}
            />
        </React.Fragment>
    );
});

export default TextareaAutosize;

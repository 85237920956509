import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import {
    GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_API,
    GET_POINTS_REDEEM_ITEMS_OVERVIEW_API,
    GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API,
    PUT_POINTS_REDEEM_ITEMS_REDEEMITEMID_API,
    POST_POINTS_REDEEM_ITEMS_API,
    POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_THUMBNAIL_IMAGE_API,
    POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_DESCRIPTION_IMAGE_API,
    POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API,
} from '@Apis/apiPath';
import { useSnackbar } from 'notistack';

// 兌換品
const usePointsRedeemItemApi = () => {
    const Axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();

    const getPointsProductGiftItemDataApi = useCallback(
        async (redeemItemID) => {
            const resp = await Axios.get(GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_API(redeemItemID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const postPointsProductGiftItemDataApi = useCallback(
        async (params) => {
            const resp = await Axios.post(POST_POINTS_REDEEM_ITEMS_API, params);
            resp && enqueueSnackbar('新增贈品成功', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const putPointsProductGiftItemDataApi = useCallback(
        async (params, redeemItemID) => {
            const resp = await Axios.put(PUT_POINTS_REDEEM_ITEMS_REDEEMITEMID_API(redeemItemID), params);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const postPointsProductGiftItemImageApi = async (params, redeemItemID) => {
        const resp = await Axios.post(POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_THUMBNAIL_IMAGE_API(redeemItemID), params, {
            headerOption: { headers: { 'Content-Type': 'multipart/form-data' } },
        });
        return resp;
    };

    const postPointsProductGiftItemImageDescriptionApi = async (params, redeemItemID) => {
        const resp = await Axios.post(POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_DESCRIPTION_IMAGE_API(redeemItemID), params, {
            headerOption: { headers: { 'Content-Type': 'multipart/form-data' } },
        });
        return resp;
    };

    const getPointsProductGiftItemOverviewApi = useCallback(
        async (params) => {
            const resp = await Axios.get(GET_POINTS_REDEEM_ITEMS_OVERVIEW_API, params);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getProductGiftItemRecordApi = useCallback(
        async (params, redeemItemID) => {
            const resp = await Axios.get(GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API(redeemItemID), params);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const postProductGiftItemInventoryApi = useCallback(
        async (params, redeemItemID) => {
            const resp = await Axios.post(POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API(redeemItemID), params);
            resp && enqueueSnackbar('修改庫存量成功', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getPointsProductGiftItemOverviewApi,
        getProductGiftItemRecordApi,
        getPointsProductGiftItemDataApi,
        postPointsProductGiftItemDataApi,
        putPointsProductGiftItemDataApi,
        postPointsProductGiftItemImageApi,
        postPointsProductGiftItemImageDescriptionApi,
        postProductGiftItemInventoryApi,
    };
};

export default usePointsRedeemItemApi;

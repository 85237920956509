import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const NoAppointment = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#6E488B', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M11.2,16.4h-2L5.8,11c-0.2-0.4-0.4-0.7-0.5-0.8c0,0.4,0,0.9,0,1.5v4.7H3.6V7.8h2.1L9,13.1
		c0.2,0.3,0.4,0.6,0.5,0.9v0c0-0.3,0-0.7,0-1.3V7.8h1.8V16.4z"
            />
            <path
                d="M20.8,12.2c-0.2,2.8-1.6,4.3-4.3,4.4c-2.7-0.2-4.1-1.6-4.3-4.4c0.1-2.9,1.6-4.4,4.3-4.5
		C19.2,7.8,20.6,9.3,20.8,12.2z M18.7,12.2c-0.1-1.8-0.8-2.7-2.1-2.8c-1.4,0.1-2.1,1-2.2,2.8c0.1,1.8,0.8,2.7,2.2,2.8
		C17.9,14.9,18.6,14,18.7,12.2z"
            />
        </SvgIcon>
    );
});

export default NoAppointment;

import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent, Slide } from '@mui/material';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { Card } from '@Components/';
import { isEmpty } from '@Util/utils';
import { initBookmark } from '@Local/Points/staticData';
import usePointsRedeemItemApi from '@Apis/usePointsRedeemItemApi';
import { PointsProductGiftRecordTable } from '@Local/Points/ProductGift/';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PointsProductGiftRecordDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: sourceDataProps = {} } = props;
    if (isEmpty(sourceDataProps) || !openProps) return null;
    const { getProductGiftItemRecordApi } = usePointsRedeemItemApi();
    const [sourceData, setSourceData] = useState([]);
    const [pageModel, setPageModel] = useState({ ...initBookmark() });
    const preloadRef = useRef(false);

    const handlePageChange = (num) => {
        setPageModel((prev) => ({ ...prev, pageIndex: num }));
    };

    const getProductGiftRecordDataApi = async (params, redeemItemID) => {
        const resp = await getProductGiftItemRecordApi({ ...params, pageModel }, redeemItemID);
        if (resp) {
            const { list, ...other } = resp;
            setSourceData(list);
            setPageModel(other);
            preloadRef.current = true;
        }
    };

    useEffect(
        () => {
            if (preloadRef.current) {
                getProductGiftRecordDataApi({ redeemItemID: sourceDataProps.itemID }, sourceDataProps.itemID);
            }
        },
        // eslint-disable-next-line
        [pageModel.pageIndex]
    );

    useEffect(
        () => {
            if (isEmpty(sourceData)) {
                getProductGiftRecordDataApi({ redeemItemID: sourceDataProps.itemID }, sourceDataProps.itemID);
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Dialog open={openProps} TransitionComponent={Transition} fullScreen>
            <GradientColorHeader onClose={onCloseProps}>手動庫存清單</GradientColorHeader>
            <DialogContent>
                <Card>
                    <Card.CardContent className={'p-4'}>
                        <PointsProductGiftRecordTable sourceData={sourceData} onPageChange={handlePageChange} {...pageModel} />
                    </Card.CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
});

export default PointsProductGiftRecordDialog;

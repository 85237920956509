import {useCallback} from 'react';
import useAxios from '@Apis/useAxios';
import {useSnackbar} from 'notistack';
import {
    GET_POINTS_REWARDS_OVERVIEW_OPTIONS_API,
    GET_POINTS_REWARDS_OVERVIEW_API,
    POST_PONITS_REWARDS_API,
    PUT_PONITS_REWARDS_REWARDID_API,
    GET_POINTS_REWARDS_REWARDID_RECORDS_API,
    GET_POINTS_REWARDS_EXPORT_API,
    GET_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API,
    POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API,
    POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_UNREDEEMED_API,
    POST_POINTS_REWARDS_REWARDID_ACCUMULATE_API,
    POST_POINTS_REWARDS_REWARDID_REDEEM_STATUS_REDEEMRECORDID_API,
    POST_POINTS_REWARDS_REWARDID_REDEEM_COMBINE_API,
    DELETE_POINTS_REWARDS_OVERVIEW_CACHE_API,
    DELETE_POINTS_REWARDS_REWARDID_RECORDS_CACHE_API,
    GET_POINTS_REWARDS_APPEND_OPTIONS_API, DELETE_PONITS_REWARDS_REWARDID_API, GET_POINTS_REWARDS_EXPORT_ALL_API,
} from '@Apis/apiPath';

const usePointsRewardsApi = () => {
    const Axios = useAxios();
    const {enqueueSnackbar} = useSnackbar();

    const getPointsRewardsOverviewOptionsApi = useCallback(
        async (params = {}) => {
            const resp = await Axios.get(GET_POINTS_REWARDS_OVERVIEW_OPTIONS_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增會員兌換主檔options
    const getPointsRewardsAppendOptionsApi = useCallback(
        async (params) => {
            const resp = await Axios.get(GET_POINTS_REWARDS_APPEND_OPTIONS_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 新增會員兌換主檔
    const postPointRewardsAddMember = useCallback(
        async (params) => {
            const resp = await Axios.post(POST_PONITS_REWARDS_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 編輯點數主檔
    const putPointRewardsReviseMemberApi = useCallback(
        async (params, rewardID) => {
            const resp = await Axios.put(PUT_PONITS_REWARDS_REWARDID_API(rewardID), params);
            resp && enqueueSnackbar('更新成功', {variant: 'success'});
            return resp;
        },
        // eslint-disable-next-line
        []
    );
    
    const deletePointsRewardApi = useCallback(
        async (params, rewardID) => {
            const resp = await Axios.delete(DELETE_PONITS_REWARDS_REWARDID_API(rewardID), params);
            resp && enqueueSnackbar('刪除成功', {variant: 'success'});
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardsOverviewApi = useCallback(
        async (params) => {
            const resp = await Axios.get(GET_POINTS_REWARDS_OVERVIEW_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardsRecordApi = useCallback(
        async (params, rewardID) => {
            const resp = await Axios.get(GET_POINTS_REWARDS_REWARDID_RECORDS_API(rewardID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardsActivityRedeemCartApi = useCallback(
        async (params, rewardID, activityID) => {
            const resp = await Axios.get(GET_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API(rewardID, activityID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsRewardsActivityRedeemCartApi = useCallback(
        async (params, rewardID, activityID) => {
            const resp = await Axios.post(POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API(rewardID, activityID), params);
            resp && enqueueSnackbar('更新成功', {variant: 'success'});
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsRewardsActivityUnredeemedApi = useCallback(
        async (params, rewardID, activityID) => {
            const resp = await Axios.post(POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_UNREDEEMED_API(rewardID, activityID), params);
            resp && enqueueSnackbar('更新成功', {variant: 'success'});
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsRewardsIdAccumulateApi = useCallback(
        async (params, rewardID) => {
            const resp = await Axios.post(POST_POINTS_REWARDS_REWARDID_ACCUMULATE_API(rewardID), params);
            resp && enqueueSnackbar('簽收成功', {variant: 'success'});
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsRewardsRedeemStatusApi = useCallback(
        async (params, rewardID, redeemRecordID) => {
            const resp = await Axios.post(POST_POINTS_REWARDS_REWARDID_REDEEM_STATUS_REDEEMRECORDID_API(rewardID, redeemRecordID), params);
            resp && enqueueSnackbar('修改簽收成功', {variant: 'success'});
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 點數合併
    const postPointMergeApi = useCallback(
        async (params, rewardID) => {
            const resp = await Axios.post(POST_POINTS_REWARDS_REWARDID_REDEEM_COMBINE_API(rewardID), params);
            resp && enqueueSnackbar(`合併成功`, {variant: 'success'});
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 清除點數總覽 分店舖
    const deletePointRewardsCacheApi = useCallback(
        async (params) => {
            const resp = await Axios.delete(DELETE_POINTS_REWARDS_OVERVIEW_CACHE_API, params);
            resp && enqueueSnackbar(`已刷新總覽清單`, {variant: 'success'});
        },
        // eslint-disable-next-line
        []
    );

    // 清除點數總覽 分對象
    const deletePointRewardsRewardIDCacheApi = useCallback(
        async (rewardID) => {
            const resp = await Axios.delete(DELETE_POINTS_REWARDS_REWARDID_RECORDS_CACHE_API(rewardID));
            resp && enqueueSnackbar(`已刷新累兌點紀錄`, {variant: 'success'});
        },
        // eslint-disable-next-line
        []
    );

    // 下載excel
    const getPointRewardsExportApi = useCallback(
        async (params) => {
            await Axios.get(GET_POINTS_REWARDS_EXPORT_API, params, {headerOption: {responseType: 'blob'}});
        },
        // eslint-disable-next-line
        []
    );

    
    // 下載excel全店舖兌換
    const getPointRewardsExportAllApi = useCallback(
        async (params) => {
            await Axios.get(GET_POINTS_REWARDS_EXPORT_ALL_API, params, {headerOption: {responseType: 'blob'}});
        },
        // eslint-disable-next-line
        []
    );

    return {
        getPointsRewardsOverviewOptionsApi,
        getPointsRewardsOverviewApi,
        postPointRewardsAddMember,
        putPointRewardsReviseMemberApi,
        deletePointsRewardApi,
        getPointsRewardsAppendOptionsApi,
        getPointsRewardsRecordApi,
        getPointsRewardsActivityRedeemCartApi,
        postPointsRewardsActivityRedeemCartApi,
        postPointsRewardsActivityUnredeemedApi,
        postPointsRewardsIdAccumulateApi,
        postPointsRewardsRedeemStatusApi,
        postPointMergeApi,
        deletePointRewardsCacheApi,
        deletePointRewardsRewardIDCacheApi,
        getPointRewardsExportApi,
        getPointRewardsExportAllApi,
    };
};

export default usePointsRewardsApi;

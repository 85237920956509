import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material/';
import { Form, Card, Button, TextField } from '@Components/';
import usePointsRedeemItemApi from '@Apis/usePointsRedeemItemApi';
import { usePointsContext } from '@Local/Points/Components/PointsContext';
import { initBookmark } from '@Local/Points/staticData';

const PointsProductGiftSearchBar = React.memo((props) => {
    const { setSourceData, pageModel, setPageModel, setRefreshRef } = usePointsContext();
    const { getPointsProductGiftItemOverviewApi } = usePointsRedeemItemApi();
    const nameRef = useRef(null);
    const preloadRef = useRef(false);

    const handleRefresh = () => {
        const params = nameRef.current.getResult();
        doSearchProductGift({ namd: params, ...pageModel });
    };

    const handleSearchOnClick = () => {
        const params = nameRef.current.getResult();
        doSearchProductGift({ namd: params, ...initBookmark() });
    };

    const doSearchProductGift = async (params) => {
        const resp = await getPointsProductGiftItemOverviewApi(params);
        if (resp) {
            const { list, ...other } = resp;
            setSourceData(list);
            setPageModel(other);
        }
        preloadRef.current = true;
    };

    useEffect(
        () => {
            if (preloadRef.current) {
                handleRefresh();
            }
        },
        // eslint-disable-next-line
        [pageModel.pageIndex]
    );

    useEffect(
        () => {
            handleSearchOnClick();
        },
        // eslint-disable-next-line
        []
    );

    setRefreshRef(handleRefresh);

    return (
        <Form onSubmit={handleSearchOnClick}>
            <Card className="my-3">
                <Card.CardContent className="p-4 px-2">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextField ref={nameRef} InputProps={{ placeholder: '請輸入贈品名稱/SKU(選一即可)' }} fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <Button className="ml-2" variant="contained" type="submit">
                                搜尋
                            </Button>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
        </Form>
    );
});

export default PointsProductGiftSearchBar;

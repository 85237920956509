import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const NoPushProbiotics = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#D02952', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M2.3,12
	c0-5.4,4.4-9.8,9.8-9.8c2.4,0,4.5,0.8,6.2,2.2c-0.1,0-0.2,0.1-0.3,0.2l-4.1,4.1L11.2,7c-0.8-0.6-1.8-0.8-2.8-0.6
	c-2,0.4-3.3,2.3-2.9,4.4c0.2,1,0.7,1.8,1.6,2.4l1.5,1L4.7,18c-0.1,0.1-0.1,0.2-0.2,0.3C3.1,16.5,2.3,14.4,2.3,12z M14.6,10.8
	l1.6,1.1c1.1,0.8,1.4,2.3,0.7,3.4c-0.4,0.5-0.9,0.9-1.6,1.1c-0.6,0.1-1.3,0-1.9-0.4l-2.2-1.5c0,0-0.1-0.1-0.1-0.1L14.6,10.8z
	 M8.5,11.1l1.5,1c0.1,0.1,0.2,0.2,0.2,0.3l-0.8,0.8c0,0-0.1,0-0.1-0.1l-1.5-1c-1-0.6-1.6-1.8-1.3-2.9c0.1-0.3,0.4-0.5,0.8-0.4
	c0.3,0.1,0.5,0.4,0.4,0.7C7.6,10.2,7.9,10.8,8.5,11.1z M12,21.8c-2.4,0-4.5-0.8-6.2-2.2c0.1,0,0.2-0.1,0.3-0.2l4.1-4.1l2.6,1.7
	c1.7,1.2,4,0.8,5.2-0.9c1.2-1.7,0.8-4-0.9-5.2c-0.1,0-0.1-0.1-0.2-0.1l-1.4-0.9l3.8-3.8c0.1-0.1,0.1-0.2,0.2-0.3
	c1.4,1.7,2.2,3.9,2.2,6.2C21.8,17.4,17.4,21.8,12,21.8z"
            />
        </SvgIcon>
    );
});

export default NoPushProbiotics;

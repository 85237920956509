const STATUS_SUCCESS_CODE = '0000';
const STATUS_LOGOUT_CODE = '9001';
const STATUS_PERMISSION_DENIED_CODE = '0006';

const CodePack = {
    STATUS_SUCCESS_CODE,
    STATUS_LOGOUT_CODE,
    STATUS_PERMISSION_DENIED_CODE,
};

// 例外處理 彈跳錯誤訊息
export const ExceptionsPack = [];

export default CodePack;

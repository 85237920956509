import React, { useImperativeHandle, useState, useRef } from 'react';
import { PromotionPlanDialog } from '@Local/PromotionPlan/Dialog/';
import useForkRef from '@Util/hook/useForkRef';

const PromotionPlanDialogBox = React.forwardRef((props, ref) => {
    const dialogRef = useRef(null);
    const combinedRef = useForkRef(ref, dialogRef);
    const [openDialog, setOpenDialog] = useState('');
    const [sourceData, setSourceData] = useState({});
    const refreshRef = useRef(null);

    const doRefresh = () => {
        if (refreshRef.current && typeof refreshRef.current === 'function') refreshRef.current();
    };

    useImperativeHandle(
        combinedRef,
        () => {
            return {
                openPromotionPlanDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('promotionPlan');
                    refreshRef.current = _refreshFn;
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        [],
    );

    const handleCloseDialog = () => {
        setOpenDialog(null);
        setSourceData(null);
        refreshRef.current = null;
    };

    return (
        <React.Fragment>
            <PromotionPlanDialog open={openDialog === 'promotionPlan'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
        </React.Fragment>
    );
});

export default PromotionPlanDialogBox;

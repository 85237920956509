import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Stack, Typography, Checkbox } from '@Components/';
import { PointsAuditCountsBox, SkipAuditBox } from '@Local/Points/Audit';
import { ListInfo, ScrollerArrowButton, Tablenization } from '@Local/Components/';
import { auditMap, initBookmark } from '@Local/Points/staticData';
import { usePointsContext } from '@Local/Points/Components/PointsContext';
import usePointsRewardAudit from '@Apis/usePointsRewardAudit';
import useGroupCheckbox from '@Util/hook/useGroupCheckbox';
import useMessageDialog from '@Util/hook/useMessageDialog';

const ShowMemoBox = (props) => {
    const { message } = props;
    const setMessageDialog = useMessageDialog();
    const handleMessageOnClick = () => {
        if (!message) return null;
        setMessageDialog({
            open: true,
            title: `累點說明`,
            msg: message,
            buttonText: `關閉`,
        });
    };
    return <Box className="text-ellipsis" onClick={handleMessageOnClick}>{message}</Box>;
};

const onTrialHeaderRow = [
    {
        cellKey: '',
        headerLabel: ({ indeterminateRef, selectedAllRef, handleCheckAllChange }) => {
            return <Checkbox indeterminateRef={indeterminateRef} inputRef={(el) => (selectedAllRef.current = el)} onChange={handleCheckAllChange} />;
        },
        align: 'center',
        isIcon: true,
        isFixed: true,
        isFixedLeft: true,
        width: 5,
        formatCell: (_v, _d, { checkRef, onCheckedChange, rowIndex }) => {
            return (
                <Checkbox
                    onChange={onCheckedChange}
                    value={rowIndex}
                    inputRef={(el) => {
                        if (el && checkRef.current && checkRef.current.indexOf(el) === -1) {
                            checkRef.current.push(el);
                        }
                    }}
                />
            );
        },
    },
    {
        cellKey: 'clubName',
        headerLabel: '店舖',
        align: 'center',
        isFixed: true,
        isFixedLeft: true,
    },
    {
        cellKey: 'displayName',
        headerLabel: '會員',
        align: 'center',
        isFixed: true,
        isFixedLeft: true,
        formatCell: (value, { cellPhone }) => {
            return (
                <React.Fragment>
                    <Typography>{value}</Typography>
                    <Typography variant="body2">{cellPhone}</Typography>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'accumulatedPoints',
        headerLabel: '點數',
        align: 'center',
    },
    {
        cellKey: 'accumulatedDesc',
        headerLabel: '累點說明',
        width: 40,
        maxWidth: 40,
        formatCell: (value) => {
            return <ShowMemoBox message={value} />;
        },
    },
    {
        cellKey: 'accumulatedStatusText',
        headerLabel: '審核狀態',
        align: 'center',
    },
    {
        cellKey: 'memo',
        headerLabel: '備註',
        align: 'center',
        width: 40,
        maxWidth: 40,
    },
];

const passTrialHeaderRow = [
    {
        cellKey: 'clubName',
        headerLabel: '店舖',
        align: 'center',
        isFixed: true,
        isFixedLeft: true,
    },
    {
        cellKey: 'displayName',
        headerLabel: '會員',
        align: 'center',
        isFixed: true,
        isFixedLeft: true,
        formatCell: (value, { cellPhone }) => {
            return (
                <React.Fragment>
                    <Typography>{value}</Typography>
                    <Typography variant="body2">{cellPhone}</Typography>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'accumulatedPoints',
        headerLabel: '點數',
        align: 'center',
    },
    {
        cellKey: 'accumulatedDesc',
        headerLabel: '累點說明',
        width: 40,
        maxWidth: 40,
        formatCell: (value) => {
            return <ShowMemoBox message={value} />;
        },
    },
    {
        cellKey: 'accumulatedStatusText',
        headerLabel: '審核狀態',
        align: 'center',
    },
    {
        cellKey: 'memo',
        headerLabel: '備註',
        align: 'center',
        width: 40,
        maxWidth: 40,
    },
];

const PointsAuditTable = (props) => {
    const { headerLabelType, path } = props;
    const { getPointRewardAuditOverviewApi, deletePointAuditCacheApi } = usePointsRewardAudit();
    const { setRefreshRef, getSkipAudit } = usePointsContext();
    const [pageModel, setPageModel] = useState({ pageSize: 1, pageIndex: 1, totalCount: 0, ...initBookmark() });
    const [sourceData, setSourceData] = useState([]);
    const scrollerArrowRef = useRef(null);
    const pointAuditCountsBoxRef = useRef(null);
    const preloadRef = useRef(null);
    const [handleCheckAllChange, handleCheckedChange, selectedAllRef, selectedListRef, indeterminateRef] = useGroupCheckbox(sourceData);
    const status = Object.keys(auditMap).find((target) => auditMap[target] === path);

    const handleRefreshOnClick = () => doCleanCacheApi();

    const handlePageOnChange = (_e, num) => {
        setPageModel((prev) => ({ ...prev, pageIndex: num }));
    };

    const handleAuditCounts = (type) => (e, checked) => {
        let target = [];
        if (type === 'ALL') {
            handleCheckAllChange(e, checked);
            if (checked) target = sourceData;
        } else if (type === 'ONE') {
            handleCheckedChange(e, checked);
            // Allen to do 盡量不要用index當作比對抓取值 需改
            const selectList = selectedListRef.current.reduce((acc, { checked, value }) => {
                let newAcc = [...acc];
                if (checked) newAcc.push(value);
                return newAcc;
            }, []);
            target = sourceData.filter((_par, index) => selectList.find((_p) => String(index) === String(_p)));
        }
        if (pointAuditCountsBoxRef.current != null)
            pointAuditCountsBoxRef.current.setSelectedList(target);
    };

    const getOverviewApi = async (params) => {
        const resp = await getPointRewardAuditOverviewApi(params);
        if (resp) {
            const { list, ...other } = resp;
            setPageModel({ ...other });
            setSourceData(list);
        }
        preloadRef.current = true;
    };

    const doCleanCacheApi = async () => {
        await deletePointAuditCacheApi();
        setTimeout(() => getOverviewApi({ status, ...initBookmark() }), 50);
    };

    const getHeaderLabel = useCallback(
        () => {
            let label;
            if (['PASSTRIAL', 'NONTRIAL'].includes(headerLabelType)) {
                label = passTrialHeaderRow;
            } else if (headerLabelType === 'ONTRIAL') {
                label = onTrialHeaderRow;
            }
            return label;
        },
        // eslint-disable-next-line
        [headerLabelType]
    );

    useEffect(
        () => {
            getOverviewApi({ status, ...initBookmark() });
        },
        // eslint-disable-next-line
        [path]
    );

    useEffect(
        () => {
            if (preloadRef.current) {
                getOverviewApi({ status, ...pageModel });
            }
        },
        // eslint-disable-next-line
        [pageModel.pageIndex]
    );
    setRefreshRef(() => getOverviewApi({ status, ...initBookmark() }));

    return (
        <React.Fragment>
            {sourceData.length !== 0 && headerLabelType === 'ONTRIAL' && 
                <PointsAuditCountsBox ref={pointAuditCountsBoxRef} onRefresh={handleRefreshOnClick}/>}
            <Box className="mb-4 px-3">
                <Stack direction="column">
                    <Stack alignItems="center" justifyContent={headerLabelType === 'ONTRIAL' ? 'space-between' : 'end'}>
                        {headerLabelType === 'ONTRIAL' && (
                            <SkipAuditBox skipAudit={getSkipAudit()} />
                        )}
                        <ListInfo
                            className="mb-2"
                            pageSize={pageModel.pageSize}
                            pageIndex={pageModel.pageIndex}
                            totalCount={pageModel.totalCount}
                            isCurrentPageFirstIndex
                            isCurrentPageLastIndex
                            onRefresh={handleRefreshOnClick}
                        />
                    </Stack>
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization
                            cardProps={{ className: 'theme-gray' }}
                            tableContainerRef={scrollerArrowRef}
                            cellWidth={16}
                            onChange={handlePageOnChange}
                            page={pageModel.pageIndex}
                            count={pageModel.totalPage}
                            isPagination
                            isFixed
                        >
                            <Tablenization.TableRowHeader
                                headerRow={getHeaderLabel()}
                                handleCheckAllChange={handleAuditCounts('ALL')}
                                selectedAllRef={selectedAllRef}
                                indeterminateRef={indeterminateRef}
                            />
                            <Tablenization.TableRowBody
                                headerRow={getHeaderLabel()}
                                rowsData={sourceData}
                                checkRef={selectedListRef}
                                onCheckedChange={handleAuditCounts('ONE')}
                            />
                        </Tablenization>
                        <ScrollerArrowButton parentRef={scrollerArrowRef} />
                    </Box>
                </Stack>
            </Box>
        </React.Fragment>
    );
};

export default PointsAuditTable;

import React, { useRef, useState } from 'react';
import { Box, IconButton, ListItemText, Menu, MenuItem } from '@Components/';
import { ScrollerArrowButton, Tablenization } from '@Local/Components';
import { MoreVertical as MoreVerticalIcon } from '@SvgIcon/';
import clsx from 'clsx';
import { isEmpty } from '@Util/utils';
import { displayDateText, parseDate } from '@Util/moment';
import MemoControllerBox from '@Local/Components/MemoControllerBox';
import { DateTimeStandard } from '../../Components/pickers/enums/DateTimeStandard';

const ActionMenuButton = ({ menuData = [], actionButtonProps = {}, actionMenuProps = {}, textAlign = 'left', enabled = true, ...other }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickActionButton = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // 為啟用與沒有操作項就不顯示
    if (!enabled || menuData.length < 1) return null;

    return (
        <React.Fragment>
            <IconButton {...actionButtonProps} onClick={handleClickActionButton}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu className={clsx('more-label-box-menu', `align-${textAlign}`)} anchorEl={anchorEl} open={open} onClose={(e) => handleCloseMenu(null)}>
                {menuData.map(({ text, ...others }) => {
                    return (
                        <MenuItem {...others} key={`more_label_${text}`}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

const headerRow = [
    {
        cellKey: 'membershipItemTypeText',
        headerLabel: '會籍項目',
    },
    {
        cellKey: 'status',
        headerLabel: '狀態',
        align: 'center',
    },
    {
        cellKey: 'startDate',
        headerLabel: '期間',
        formatCell: (startDate, { endDate }) => {
            return displayDateText(startDate, endDate);
        },
    },
    {
        cellKey: 'month',
        headerLabel: '已使用 / 月數',
        align: 'center',
        formatCell: (month = 0, { usedMonth = 0 }) => {
            if (month === 0 && usedMonth === 0) return '-';
            return `${usedMonth} / ${month}`;
        },
    },
    {
        cellKey: 'day',
        headerLabel: '已使用 / 天數',
        align: 'center',
        formatCell: (day = 0, { usedDay = 0 }) => {
            if (day === 0 && usedDay === 0) return '-';
            return `${usedDay} / ${day}`;
        },
    },
    {
        cellKey: 'suspensionDate',
        headerLabel: '暫停扣款月份',
        align: 'center',
        formatCell: (value) => {
            return displayDateText(value);
        },
    },
    {
        cellKey: 'recoveryDate',
        headerLabel: '恢復扣款月份',
        align: 'center',
        formatCell: (value) => {
            return displayDateText(value);
        },
    },
    {
        cellKey: 'notes',
        headerLabel: '備註',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'備註'} />;
        },
    },
    {
        cellKey: 'createDate',
        headerLabel: '建單日期',
        formatCell: (value) => {
            return parseDate(value, DateTimeStandard.DisplayFullBySlash);
        },
    },
    {
        cellKey: 'updatedDate',
        headerLabel: '最後更新日',
        formatCell: (value) => {
            return parseDate(value, DateTimeStandard.DisplayFullBySlash);
        },
    },
    {
        cellKey: 'updater',
        headerLabel: '最後更新者',
        align: 'center',
    },

    {
        cellKey: 'more',
        headerLabel: '',
        width: 6.8,
        formatCell: (_, { isModify = true, membershipItemType }, { dialogRef }) => {
            let menuData = [];

            if (isModify) {
                menuData = [
                    ...menuData,
                    {
                        text: '修改會籍項目',
                        onClick: (e) => {
                            if (dialogRef && dialogRef.current) {
                                dialogRef.current.openMembershipItemDialog({ membershipItemType });
                            }
                        },
                    },
                ];
            }

            if (isEmpty(menuData)) return null;

            return <ActionMenuButton menuData={menuData} />;
        },
    },
];

const fakeData = [
    {
        membershipItemType: 11,
        membershipItemTypeText: '暫停',
        status: '有效',
        startDate: '2022/08/15',
        endDate: '2022/09/14',
        month: 1,
        usedMonth: 1,
        day: 0,
        usedDay: 0,
        suspensionDate: '2022/09',
        recoveryDate: '2022/10',
        notes: '醫生指示不宜運動',
        createDate: '2022/08/16 16:03',
        updatedDate: '2022/11/19 13:54',
        updater: 'sbn',
    },
    {
        membershipItemType: 5,
        membershipItemTypeText: '續約',
        status: '有效',
        startDate: '2022/07/01',
        endDate: '',
        month: 0,
        usedMonth: 0,
        day: 0,
        usedDay: 0,
        suspensionDate: '',
        recoveryDate: '',
        notes: '備註',
        createDate: '2022/06/15 13:54',
        updatedDate: '2022/06/15 13:54',
        updater: 'sbn',
    },
];

const ClubManagementMemberMembershipItemTable = (props) => {
    const { sourceData, dialogRef } = props;
    const { list = fakeData } = sourceData || {};
    const scrollerArrowRef = useRef(null);

    return (
        <Box className={'scroll-arrow-container overview'}>
            <Tablenization cardProps={{ className: 'theme-gray' }} tableContainerRef={scrollerArrowRef}>
                <Tablenization.TableRowHeader headerRow={headerRow} />
                <Tablenization.TableRowBody headerRow={headerRow} rowsData={list} dialogRef={dialogRef} />
            </Tablenization>
            <ScrollerArrowButton parentRef={scrollerArrowRef} />
        </Box>
    );
};

export default ClubManagementMemberMembershipItemTable;

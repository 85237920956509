import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const ListUl = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M1.875,1.875A1.875,1.875,0,1,0,3.75,3.75,1.875,1.875,0,0,0,1.875,1.875Zm0,6.25A1.875,1.875,0,1,0,3.75,10,1.875,1.875,0,0,0,1.875,8.125Zm0,6.25A1.875,1.875,0,1,0,3.75,16.25a1.875,1.875,0,0,0-1.875-1.875Zm17.5.625H6.875a.625.625,0,0,0-.625.625v1.25a.625.625,0,0,0,.625.625h12.5A.625.625,0,0,0,20,16.875v-1.25A.625.625,0,0,0,19.375,15Zm0-12.5H6.875a.625.625,0,0,0-.625.625v1.25A.625.625,0,0,0,6.875,5h12.5A.625.625,0,0,0,20,4.375V3.125A.625.625,0,0,0,19.375,2.5Zm0,6.25H6.875a.625.625,0,0,0-.625.625v1.25a.625.625,0,0,0,.625.625h12.5A.625.625,0,0,0,20,10.625V9.375A.625.625,0,0,0,19.375,8.75Z"
                transform="translate(2 2.125)"
            />
        </SvgIcon>
    );
});

export default ListUl;

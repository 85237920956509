import React, { useRef } from 'react';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Box, Button, TextField } from '@Components/';
import { refIsRequiredError } from '@Util/utils';
import usePointsRewardsApi from '@Apis/usePointsRewardsApi';

const PointReviseMemberDialog = React.forwardRef((props, ref) => {
    const { open: openProps, onClose: onCloseProps, sourceData: memberDataProps, refresh } = props;
    if (!openProps) return null;
    const { putPointRewardsReviseMemberApi } = usePointsRewardsApi();
    const displayNameRef = useRef(null);
    const cellPhoneRef = useRef(null);
    const memberNoRef = useRef(null);
    const { cellPhone, displayName, memberNo, rewardID, clubID } = memberDataProps;

    const getParams = () => {
        return {
            displayName: displayNameRef.current.getResult(),
            cellPhone: cellPhoneRef.current.getResult(),
            memberNo: memberNoRef.current.getResult(),
            clubID,
        };
    };

    const handleConfirmClick = () => {
        if (!refIsRequiredError(displayNameRef, cellPhoneRef, memberNoRef)) {
            const params = getParams();
            doReviseMemberApi(params, rewardID);
        }
    };

    const doReviseMemberApi = async (params, rID) => {
        const resp = await putPointRewardsReviseMemberApi(params, rID);
        if (resp) {
            refresh();
            onCloseProps();
        }
    };

    return (
        <Dialog PaperProps={{ className: 'wx-60rem' }} open={openProps} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>編輯會員資訊</GradientColorHeader>
            <DialogContent>
                <Box className="p-2">
                    <Grid container spacing={2}>
                        <Grid xs={12} item>
                            <TextField ref={displayNameRef} defaultValue={displayName} label="會員姓名" fullWidth required />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField ref={cellPhoneRef} defaultValue={cellPhone} label="會員手機" maskType="MOB" fullWidth />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField ref={memberNoRef} defaultValue={memberNo} label="會員編號" fullWidth required />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={handleConfirmClick}>
                    確認
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointReviseMemberDialog;

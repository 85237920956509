import { CLUB_MANAGEMENT_MEMBER_MEMBERSHIP_ROUTER, CLUB_MANAGEMENT_MEMBERSHIP_ROUTER } from '@Router/routerPath';
import { ClubManagementMembershipPage } from '@Local/ClubManagement';
import ClubManagementMemberMembershipPage from '@Local/ClubManagement/ClubManagementMemberMembershipItemPage';

const clubManagementRouter = [
    {
        description: '店鋪管理',
        frontKey: 'CXX990001',
    },
    {
        description: '會員會籍搜尋頁',
        frontKey: 'CXX990002',
        static: true,
        exact: true,
        path: CLUB_MANAGEMENT_MEMBER_MEMBERSHIP_ROUTER,
        href: CLUB_MANAGEMENT_MEMBER_MEMBERSHIP_ROUTER,
        as: ClubManagementMembershipPage,
    },
    {
        description: '會員會籍頁',
        frontKey: 'CXX990003',
        static: true,
        path: CLUB_MANAGEMENT_MEMBERSHIP_ROUTER,
        href: CLUB_MANAGEMENT_MEMBERSHIP_ROUTER,
        as: ClubManagementMemberMembershipPage,
    },
];

export default clubManagementRouter;

import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, useLocation, useHistory } from 'react-router-dom';
import { getMenusData } from '@Redux/menus/selector';
import { PrivateRoute } from '@Components/';
import NavigationPage from '@Local/Navigation/NavigationPage';
import { NotFoundPage } from '@Local/Error/';
import routerMap from '@Router/router';
import { HQ_ROOT_ROUTER, ENTRANCE_ROOT_ROUTER } from '@Router/routerPath';
import { getIsAndroid, isEmpty, flatDeepArray } from '@Util/utils';
import TopBar from '@Local/TopBar/TopBar';

const EmptyComponent = () => <></>;

const checkRootPath = (pathname) => {
    const regExpString = new RegExp(`^/${ENTRANCE_ROOT_ROUTER}[/]?$`);
    return regExpString.test(pathname);
};

const Main = (props) => {
    const [isMounted, setMounted] = React.useState(false);
    const reduxMenus = useSelector(getMenusData);
    const { path: rootPath } = props;
    let { pathname } = useLocation();
    let history = useHistory();

    React.useEffect(
        () => {
            checkRootPath(pathname) && history.push(HQ_ROOT_ROUTER);
        },
        // eslint-disable-next-line
        [pathname],
    );

    const devPrivateRoute = React.useMemo(
        () => {
            const { hostname } = window.location;
            if (hostname === 'localhost' || /192.168.0/.test(hostname)) {
                const { developerRouter } = require('@Router/DevelopmentRouter');
                const devRouters = flatDeepArray([developerRouter]);
                return devRouters.reduce((acc, { path: path__, as: as__, ...others }) => {
                    let sourceAry = [...acc];
                    if (path__) {
                        sourceAry.push(<PrivateRoute key={path__} path={path__} as={as__} {...others} />);
                    }
                    return sourceAry;
                }, []);
            } else {
                return null;
            }
        },
        // eslint-disable-next-line
        [],
    );

    React.useEffect(
        () => {
            if (!isEmpty(reduxMenus)) setMounted(true);
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [reduxMenus],
    );

    return (
        <div className="main-container">
            {isMounted && (
                <Switch>
                    {routerMap.map(({ frontKey, path: pathRouter, exact, as: Component, ...others }) => {
                        if (!pathRouter) return null;
                        const defaultProperty = {
                            path: pathRouter,
                            exact,
                            as: Component,
                            ...others,
                        };
                        let isExist = reduxMenus.find(({ frontKey: _key }) => frontKey === _key);
                        if (/^CXX99[\d].+$/g.test(frontKey) && window.location.hostname === 'localhost') {
                            isExist = { isExist: false }; //專門給正在開發且測試的頁面使用
                        }
                        return isExist ? <PrivateRoute key={frontKey} {...defaultProperty} isAuth={isExist.isAuth} /> : null;
                    })}
                    {devPrivateRoute}
                    {/*   Todo   */}
                    <PrivateRoute path={`${rootPath}/404`} as={NotFoundPage} />
                    {/* <PrivateRoute path={`${rootPath}/pdf-notfound`} as={NotFoundPDFPage} /> */}
                    <PrivateRoute path="*" as={checkRootPath(pathname) ? EmptyComponent : NotFoundPage} />
                </Switch>
            )}
        </div>
    );
};

const LandingPage = (props) => {
    const navRef = useRef({});

    useEffect(
        () => {
            if (getIsAndroid()) {
                document.body.classList.add('mobile');
            }
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Router>
            <TopBar navRef={navRef} />
            <NavigationPage ref={navRef} />
            <Main />
        </Router>
    );
};

export default LandingPage;

import React, { useRef } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { Button, TextField } from '@Components/';
import { isEmpty, refIsRequiredError } from '@Util/utils';
import usePointsRewardsApi from '@Apis/usePointsRewardsApi';

const PointsGrantDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: memberData = {}, refresh } = props;
    if (isEmpty(memberData) || !openProps) return null;
    const { postPointsRewardsIdAccumulateApi } = usePointsRewardsApi();
    const pointsNumRef = useRef(null);
    const empoloyeeIdRef = useRef(null);
    const employeeNameRef = useRef(null);
    const reasonRef = useRef(null);

    const getParams = () => {
        let params = {
            clubID: memberData.clubID,
            accumulatedPoints: pointsNumRef.current.getResult(),
            accumulatedDesc: reasonRef.current.getResult(),
            employeeName: '',
            employeeID: 0,
        };

        return params;
    };

    const handleOnComfirm = () => {
        if (!refIsRequiredError(pointsNumRef, empoloyeeIdRef, employeeNameRef, reasonRef)) {
            doPointsGrantApi(getParams(), memberData.rewardID);
        }
    };

    const doPointsGrantApi = async (params, rewardID) => {
        const resp = await postPointsRewardsIdAccumulateApi(params, rewardID);
        if (resp) {
            refresh();
            onCloseProps && onCloseProps();
        }
    };

    return (
        <Dialog open={openProps}>
            <GradientColorHeader onClose={onCloseProps}>會員補點</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={12} item>
                        <TextField ref={pointsNumRef} label="補點點數" type={'number'} defaultValue={0} fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField ref={reasonRef} 
                                   label="補點原因(2024年起僅循環訂單第一盒店鋪出貨才可以補點)" 
                                   placeholder="訂單編號或新訂購的品項與數量+訂單成立日期" 
                                   rows={3} multiline fullWidth required />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleOnComfirm}>
                    確認
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointsGrantDialog;

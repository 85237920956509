// postEditPointAuditSkipAuditApi
import React, { useRef } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { ReadTextField } from '@Local/Components/';
import { Button, TextField } from '@Components/';
import { refIsRequiredError } from '@Util/utils';
import usePointsRewardAudit from '@Apis/usePointsRewardAudit';

const PointsAuditSkipAuditDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: sourceDataProps = {}, refresh } = props;
    if (!openProps) return null;
    const { postEditPointAuditSkipAuditApi } = usePointsRewardAudit();
    const { skipAudit: skipAuditProps } = sourceDataProps;
    const skipAuditRef = useRef(null);

    const getParams = () => {
        let params = {
            skipAudit: skipAuditRef.current.getResult(),
        };
        return params;
    };

    const handleOnConfirm = () => {
        if (!refIsRequiredError(skipAuditRef)) {
            doAddPointsActivityApi(getParams());
        }
    };

    const doAddPointsActivityApi = async (params) => {
        const resp = await postEditPointAuditSkipAuditApi(params);
        if (resp) {
            refresh();
            onCloseProps();
        }
    };

    return (
        <Dialog open={openProps} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>{`修改過審點數`}</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={12} item>
                        <ReadTextField label="當前過審點數" children={skipAuditProps} />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField ref={skipAuditRef} label="修改過審點數" InputProps={{ placeholder: '請輸入點數' }} fullWidth required />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleOnConfirm}>
                    確認
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointsAuditSkipAuditDialog;

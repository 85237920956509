import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormControl, FormLabel, FormHelperText, FormControlLabel, Stack, RadioGroup, Radio } from '@Components/';

const RadioField = React.forwardRef((props, ref) => {
    const {
        children,
        label,
        defaultValue: propsDefaultValue = '',
        value: propsValue,
        onChange: propsChange,
        row = false,
        isError: propsError = false,
        helperText = '此選項為必填，請選擇其中一項',
        required = false,
        fullWidth = false,
        disabled = false,
        className,
        origin,
        gap = false,
        readOnly: readOnlyProps = false,
        name: nameProp = '',
        labelDirection = 'column',
    } = props;
    const radioGruopRef = useRef([]);
    const [isError, setError] = useState(propsError);

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => {
                    let source = required;
                    if (required && radioGruopRef && radioGruopRef.current) {
                        source = !radioGruopRef.current.some((target) => target.checked === true);
                    }
                    setError(source);
                    return source;
                },
                getResult: () => {
                    const source = radioGruopRef.current.map((target) => {
                        if (!target) return null;
                        return {
                            key: target.name || null,
                            value: target.value || null,
                            checked: !!target.checked,
                        };
                    });
                    return source;
                },
                getName: () => nameProp,
            };
        },
        // eslint-disable-next-line
        [isError]
    );

    useEffect(() => {
        setError(propsError);
    }, [propsError]);

    return (
        <FormControl
            required={required}
            error={isError}
            fullWidth={fullWidth}
            disabled={disabled}
            className={clsx('field', className)}
            direction={labelDirection}
            readOnly={readOnlyProps}
        >
            {label && <FormLabel>{label}</FormLabel>}
            <Stack direction="column">
                <RadioGroup row={row} onChange={propsChange} defaultValue={propsDefaultValue} value={propsValue} origin={origin} gap={gap}>
                    {React.Children.map(children, (child, idx) => {
                        if (!React.isValidElement(child)) return null;
                        return React.cloneElement(child, {
                            value: child.props.value,
                            label: child.props.label,
                            name: child.props.name,
                            readOnly: child.props.readOnly || readOnlyProps,
                            control: <Radio inputRef={(el) => (radioGruopRef.current[idx] = el)} />,
                        });
                    })}
                </RadioGroup>
                {isError && <FormHelperText>{helperText}</FormHelperText>}
            </Stack>
        </FormControl>
    );
});

RadioField.Radio = function Radio(props) {
    const { name: propsNme, value: propsValue, label: propsLabel, readOnly: readOnlyProps = false, control } = props;
    return <FormControlLabel value={propsValue} label={propsLabel} name={propsNme} control={control} readOnly={readOnlyProps} />;
};

export default RadioField;

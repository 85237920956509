import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import {
    GET_CYCLE_PURCHASE_TASKS_OVERVIEW_OPTIONS_API,
    GET_CYCLE_PURCHASE_TASKS_OVERVIEW_API,
    GET_CYCLE_PURCHASE_TASKS_API,
    DELETE_CYCLE_PURCHASE_TASKS_API,
    POST_CYCLE_PURCHASE_TASKS_UPLOAD_EXCEL_API,
    POST_CYCLE_PURCHASE_TASKS_SETTLE_API,
} from '@Apis/apiPath';

const useCyclePurchaseApi = () => {
    const Axios = useAxios();

    const getCyclePurchaseTasksOverviewOptionsApi = useCallback(
        async () => {
            return await Axios.get(GET_CYCLE_PURCHASE_TASKS_OVERVIEW_OPTIONS_API);
        },
        // eslint-disable-next-line
        [],
    );

    const getCyclePurchaseTasksOverviewApi = useCallback(
        async (params) => {
            return await Axios.get(GET_CYCLE_PURCHASE_TASKS_OVERVIEW_API, params);
        },
        // eslint-disable-next-line
        [],
    );

    const getCyclePurchaseTasksApi = useCallback(
        async (taskID) => {
            return await Axios.get(GET_CYCLE_PURCHASE_TASKS_API(taskID));
        },
        // eslint-disable-next-line
        [],
    );

    const deleteCyclePurchaseTasksApi = useCallback(
        async (taskID) => {
            return await Axios.delete(DELETE_CYCLE_PURCHASE_TASKS_API(taskID));
        },
        // eslint-disable-next-line
        [],
    );

    const postCyclePurchaseTasksUploadExcelApi = useCallback(
        async (params, formData) => {
            const { shippingMonth, shippingDay } = params;
            return await Axios.post(POST_CYCLE_PURCHASE_TASKS_UPLOAD_EXCEL_API(shippingMonth, shippingDay), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        },
        // eslint-disable-next-line
        [],
    );

    const postCyclePurchaseTasksSettleApi = useCallback(
        async (taskID) => {
            return await Axios.post(POST_CYCLE_PURCHASE_TASKS_SETTLE_API(taskID));
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getCyclePurchaseTasksOverviewOptionsApi,
        getCyclePurchaseTasksOverviewApi,
        getCyclePurchaseTasksApi,
        deleteCyclePurchaseTasksApi,
        postCyclePurchaseTasksUploadExcelApi,
        postCyclePurchaseTasksSettleApi,
    };
};

export default useCyclePurchaseApi;

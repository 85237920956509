import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const ErrorCircle = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <rect width="24" height="24" opacity="0" />
            <path
                d="M10.375.375a10,10,0,1,0,10,10A10,10,0,0,0,10.375.375ZM15.278,13a.484.484,0,0,1,0,.685l-1.6,1.593a.484.484,0,0,1-.685,0l-2.621-2.645L7.75,15.278a.484.484,0,0,1-.685,0l-1.593-1.6a.484.484,0,0,1,0-.685l2.645-2.621L5.472,7.75a.484.484,0,0,1,0-.685l1.6-1.6a.484.484,0,0,1,.685,0l2.621,2.649L13,5.472a.484.484,0,0,1,.685,0l1.6,1.6a.484.484,0,0,1,0,.685l-2.649,2.621Z"
                transform="translate(1.625 1.625)"
            />
        </SvgIcon>
    );
});

export default ErrorCircle;

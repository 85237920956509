import React from 'react';
import { Box } from '@Components/';
import TopBarStart from '@Local/TopBar/TopBarStart';
import TopBarEnd from '@Local/TopBar/TopBarEnd';

const TopBar = (props) => {
    const { navRef } = props;
    return (
        <Box className={'top-bar'}>
            <TopBarStart onToggleMenu={() => navRef.current && navRef.current.triggerToggleMenu()} />
            <TopBarEnd />
        </Box>
    );
};

export default TopBar;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const ArrowUp = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M13.2,0.5c-0.7-0.7-1.8-0.7-2.4,0L2.2,9.1c-0.7,0.7-0.7,1.8,0,2.4s1.8,0.7,2.4,0l5.6-5.7v16.4
	c0,0.9,0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7V5.9l5.6,5.6c0.7,0.7,1.8,0.7,2.4,0c0.7-0.7,0.7-1.8,0-2.4L13.2,0.5L13.2,0.5z"
            />
        </SvgIcon>
    );
});

export default ArrowUp;

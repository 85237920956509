import React from 'react';
import clsx from 'clsx';
import ButtonBase from '../button/ButtonBase';


const edgeMap = ['start', 'end'];
const sizeMap = ['large', 'small'];
const colorMap = ['inherit', 'primary', 'secondary', 'success', 'error', 'warning', 'add'];

const IconButton = React.forwardRef((props, ref) => {
    const {
        style,
        className,
        edge = false,
        disabled = false,
        disableFocusRipple = false,
        size = 'medium',
        color = 'default',
        children,
        onClick: propsOnClick,
        ...others
    } = props;
    let classNames = 'icon-button';
    if (edgeMap.indexOf(edge) !== -1 && !disabled) classNames = clsx(classNames, `icon-button-edge-${edge}`);
    if (sizeMap.indexOf(size) !== -1 && !disabled) classNames = clsx(classNames, `icon-button-size-${size}`);
    if (colorMap.indexOf(color) !== -1 && !disabled) classNames = clsx(classNames, `icon-button-color-${color}`);
    if (disabled) classNames = clsx(classNames, `icon-button-disabled`);

    const handleOnClick = (e) => {
        propsOnClick && propsOnClick(e);
    };

    let defaultProperty = {
        style,
        className: clsx(classNames, className),
        onClick: handleOnClick,
        disabled,
        focusRipple: !disableFocusRipple,
        centerRipple: true,
        ref,
        ...others,
    };
    if (propsOnClick) defaultProperty.type = 'button';

    return (
        <ButtonBase {...defaultProperty}>
            {React.Children.map(children, (child, idx) => {
                if (!React.isValidElement(child)) return null;
                return React.cloneElement(child, { fontSize: size, color, ...child.props });
            })}
        </ButtonBase>
    );
});

export default IconButton;

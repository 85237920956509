import React, { useRef } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { Button, TextField, DateField, FormControlLabel, Switch } from '@Components/';
import { isEmpty, refIsRequiredError } from '@Util/utils';
import { parseDate } from '@Util/moment';
import usePointsActivityApi from '@Apis/usePointsActivityApi';

const PointsActivityBoxDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: sourceDataProps = {}, refresh } = props;
    if (!openProps) return null;
    const { postPointsActivityOverviewApi, putPointsActivityOverviewApi } = usePointsActivityApi();
    const {
        title = '',
        description = '',
        startDate = '',
        endDate = '',
        redeemStart = '',
        redeemEnd = '',
        activityID = 0,
        isVisible = false,
    } = sourceDataProps || {};
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const redeemStartRef = useRef(null);
    const redeemEndRef = useRef(null);
    const isVisibleRef = useRef(null);

    const getParams = () => {
        let params = {
            title: titleRef.current.getResult(),
            description: descriptionRef.current.getResult(),
            startDate: startDateRef.current.getResult(),
            endDate: endDateRef.current.getResult(),
            redeemStart: redeemStartRef.current.getResult(),
            redeemEnd: redeemEndRef.current.getResult(),
            activityID: activityID,
            isVisible: isVisibleRef.current.checked,
        };

        return params;
    };

    const handleConfirm = () => {
        if (!refIsRequiredError(titleRef, startDateRef, endDateRef, redeemStartRef, redeemEndRef)) {
            const params = getParams();
            if (isEmpty(sourceDataProps)) {
                doAddPointsActivityApi(params);
            } else {
                doEditPointsActivityApi(params, activityID);
            }
        }
    };

    const doAddPointsActivityApi = async (params) => {
        const resp = await postPointsActivityOverviewApi(params);
        if (resp) {
            refresh();
            onCloseProps();
        }
    };

    const doEditPointsActivityApi = async (params, aID) => {
        const resp = await putPointsActivityOverviewApi(params, aID);
        if (resp) {
            refresh();
            onCloseProps();
        }
    };

    return (
        <Dialog PaperProps={{ className: 'wx-60rem' }} open={openProps} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>{`${isEmpty(sourceDataProps) ? `新增` : `編輯`}點數活動`}</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={12} item>
                        <TextField ref={titleRef} label="活動名稱" defaultValue={title} InputProps={{ placeholder: '請輸入活動名稱' }} fullWidth required />
                    </Grid>
                    <Grid xs={6} item>
                        <DateField ref={startDateRef} defaultValue={startDate ? parseDate(startDate) : ''} label="活動起始時間" fullWidth required />
                    </Grid>
                    <Grid xs={6} item>
                        <DateField ref={endDateRef} defaultValue={endDate ? parseDate(endDate) : ''} label="活動結束時間" fullWidth required />
                    </Grid>
                    <Grid xs={6} item>
                        <DateField ref={redeemStartRef} defaultValue={redeemStart ? parseDate(redeemStart) : ''} label="兌換起始日" fullWidth required />
                    </Grid>
                    <Grid xs={6} item>
                        <DateField ref={redeemEndRef} defaultValue={redeemEnd ? parseDate(redeemEnd) : ''} label="兌換結束日" fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <FormControlLabel
                            className={'m-0'}
                            control={<Switch inputProps={{ ref: isVisibleRef }} defaultChecked={isVisible} />}
                            prefixLabel={'是否開啟'}
                            labelPlacement={'start'}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField ref={descriptionRef} defaultValue={description} label="描述" rows={4} multiline fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleConfirm}>
                    確認
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointsActivityBoxDialog;

import React, { useImperativeHandle, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Loading from './Loading';
import Modal from 'react-modal';
// select
import { getLoadingData } from '@Redux/loading/selector';

const ShowLoading = React.forwardRef(({ size, duration, color = 'main', ...props }, ref) => {
    const { count } = useSelector(getLoadingData);
    const [open, setOpen] = React.useState(false);
    let showCount = count !== 0;

    const handleToggle = (value) => {
        setOpen(value);
    };

    const loadingMemo = useMemo(() => {
        return (
            <Modal isOpen={open} overlayClassName="modal-overlay" className="modal-content" ariaHideApp={false} onRequestClose={() => {}}>
                <Loading size={50} duration={700} />
            </Modal>
        );
    }, [open]);

    useImperativeHandle(
        ref,
        () => ({
            handleToggle: () => {
                handleToggle(!showCount);
            },
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        setOpen(showCount);
    }, [showCount]);

    return <React.Fragment>{loadingMemo}</React.Fragment>;
});

export default ShowLoading;

import React, { useImperativeHandle, useState, useRef } from 'react';
import useForkRef from '@Util/hook/useForkRef';
import UploadCyclePurchaseOrderSheetDialog from '@Local/CyclePurchase/Dialog/UploadCyclePurchaseOrderSheetDialog';
import useMessageDialog from '@Util/hook/useMessageDialog';
import useCyclePurchaseApi from '@Apis/useCyclePurchaseApi';

const UPLOAD_CYCLE_PURCHASE_ORDER_SHEET = 'UPLOAD_CYCLE_PURCHASE_ORDER_SHEET';

const CyclePurchaseDialogBox = React.forwardRef((props, ref) => {
    const dialogRef = useRef(null);
    const combinedRef = useForkRef(ref, dialogRef);
    const [openDialog, setOpenDialog] = useState('');
    const [sourceData, setSourceData] = useState({});
    const refreshRef = useRef(null);
    const { deleteCyclePurchaseTasksApi, postCyclePurchaseTasksSettleApi } = useCyclePurchaseApi();
    const setMessageDialog = useMessageDialog();

    const doRefresh = () => {
        if (refreshRef.current && typeof refreshRef.current === 'function') refreshRef.current();
    };

    useImperativeHandle(
        combinedRef,
        () => {
            return {
                openUploadCyclePurchaseOrderSheetDialog: (targetData = {}, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog(UPLOAD_CYCLE_PURCHASE_ORDER_SHEET);
                    refreshRef.current = _refreshFn;
                },
                openDeleteCyclePurchaseTaskDialog: (targetData = {}, _refreshFn = () => {}) => {
                    const { taskID } = targetData;

                    refreshRef.current = _refreshFn;

                    const doDeleteApi = async (taskID, onClose) => {
                        const resp = await deleteCyclePurchaseTasksApi(taskID);
                        if (resp) {
                            doRefresh();
                            typeof onClose === 'function' && onClose();
                        }
                    };

                    setMessageDialog({
                        open: true,
                        title: '確認刪除',
                        msg: '要刪除這一批循環訂單嗎？刪除後無法復原喔。',
                        onAsyncConfirm: (_, onClose) => doDeleteApi(taskID, onClose),
                    });
                },
                openSettleCyclePurchaseTaskDialog: (targetData = {}, _refreshFn = () => {}) => {
                    const { taskID } = targetData;

                    refreshRef.current = _refreshFn;

                    const doSettleCyclePurchaseTaskApi = async (taskID, onClose) => {
                        const resp = await postCyclePurchaseTasksSettleApi(taskID);
                        if (resp) {
                            doRefresh();
                            typeof onClose === 'function' && onClose();
                        }
                    };

                    setMessageDialog({
                        open: true,
                        title: '確認扣款',
                        msg: '確定要將整批循環訂單進行銀行扣款嗎?',
                        onAsyncConfirm: (_, onClose) => doSettleCyclePurchaseTaskApi(taskID, onClose),
                    });
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        [],
    );

    const handleCloseDialog = () => {
        setOpenDialog(null);
        setSourceData(null);
        refreshRef.current = null;
    };

    return (
        <React.Fragment>
            <UploadCyclePurchaseOrderSheetDialog
                open={openDialog === UPLOAD_CYCLE_PURCHASE_ORDER_SHEET}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
        </React.Fragment>
    );
});

export default CyclePurchaseDialogBox;

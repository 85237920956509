import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getTransitionProps, duration, getAutoHeightDuration } from '@Util/createTransitions';
import useForkRef from '@Util/hook/useForkRef';
import { Transition } from 'react-transition-group';

const staticClass = 'collapse';

const CollapseRoot = React.forwardRef((props, ref) => {
    const {
        as: Component = 'div',
        className,
        isHorizontal,
        entered,
        exited,
        collapsedSize,
        in: inProps,
        children,
        ...other
    } = props;
    let classNames = `${staticClass}-root`;
    if (isHorizontal) classNames = clsx(classNames, `${staticClass}-horizontal`);
    if (entered) classNames = clsx(classNames, `${staticClass}-entered`);
    if (exited && collapsedSize === '0px' && !inProps) classNames = clsx(classNames, `${staticClass}-exited`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };

    return <Component {...defaultProperty}>{children}</Component>;
});

const CollapseWrapper = React.forwardRef((props, ref) => {
    const { isHorizontal, children } = props;
    let classNames = `${staticClass}-wrapper`;
    if (isHorizontal) classNames = clsx(classNames, `${staticClass}-horizontal`);

    const defaultProperty = {
        className: classNames,
        ref,
    };

    return <div {...defaultProperty}>{children}</div>;
});

const CollapseWrapperInner = (props) => {
    const { isHorizontal, children } = props;
    let classNames = `${staticClass}-wrapper-inner`;
    if (isHorizontal) classNames = clsx(classNames, `${staticClass}-horizontal`);

    const defaultProperty = {
        className: classNames,
    };

    return <div {...defaultProperty}>{children}</div>;
};

const Collapse = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        collapsedSize: collapsedSizeProp = '0px',
        component,
        easing,
        in: inProp,
        onEnter,
        onEntered,
        onEntering,
        onExit,
        onExited,
        onExiting,
        style = {},
        orientation = 'vertical',
        timeout = duration.standard,
        // eslint-disable-next-line react/prop-types
        TransitionComponent = Transition,
        ...other
    } = props;

    const timer = React.useRef();
    const nodeRef = React.useRef(null); // transitionRef
    const handleRef = useForkRef(ref, nodeRef); // collapseRootRef
    const wrapperRef = React.useRef(null); // collapseWrapper
    const autoTransitionDuration = React.useRef();
    const collapsedSize = typeof collapsedSizeProp === 'number' ? `${collapsedSizeProp}px` : collapsedSizeProp;
    const isHorizontal = orientation === 'horizontal';
    const size = isHorizontal ? 'width' : 'height';
    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const normalizedTransitionCallback = (callback) => (maybeIsAppearing) => {
        if (callback) {
            const node = nodeRef.current;
            // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
            if (maybeIsAppearing === undefined) {
                callback(node);
            } else {
                callback(node, maybeIsAppearing);
            }
        }
    };

    const getWrapperSize = () =>
        wrapperRef.current ? wrapperRef.current[isHorizontal ? 'clientWidth' : 'clientHeight'] : 0;

    const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
        if (wrapperRef.current && isHorizontal) {
            // Set absolute position to get the size of collapsed content
            wrapperRef.current.style.position = 'absolute';
        }
        node.style[size] = collapsedSize;
        onEnter && onEnter(node, isAppearing);
    });

    const handleEntering = normalizedTransitionCallback((node, isAppearing) => {
        const wrapperSize = getWrapperSize();

        if (wrapperRef.current && isHorizontal) {
            // After the size is read reset the position back to default
            wrapperRef.current.style.position = '';
        }

        const { duration: transitionDuration, easing: transitionTimingFunction } = getTransitionProps(
            { style, timeout, easing },
            {
                mode: 'enter',
            }
        );

        if (timeout === 'auto') {
            const duration2 = getAutoHeightDuration(wrapperSize);
            node.style.transitionDuration = `${duration2}ms`;
            autoTransitionDuration.current = duration2;
        } else {
            node.style.transitionDuration =
                typeof transitionDuration === 'string' ? transitionDuration : `${transitionDuration}ms`;
        }

        node.style[size] = `${wrapperSize}px`;
        node.style.transitionTimingFunction = transitionTimingFunction;

        onEntering && onEntering(node, isAppearing);
    });

    const handleEntered = normalizedTransitionCallback((node, isAppearing) => {
        node.style[size] = 'auto';
        onEntered && onEntered(node, isAppearing);
    });

    const handleExit = normalizedTransitionCallback((node) => {
        node.style[size] = `${getWrapperSize()}px`;
        onExit && onExit(node);
    });

    const handleExited = normalizedTransitionCallback(onExited);

    const handleExiting = normalizedTransitionCallback((node) => {
        const wrapperSize = getWrapperSize();
        const { duration: transitionDuration, easing: transitionTimingFunction } = getTransitionProps(
            { style, timeout, easing },
            {
                mode: 'exit',
            }
        );
        if (timeout === 'auto') {
            // TODO: rename getAutoHeightDuration to something more generic (width support)
            // Actually it just calculates animation duration based on size
            const duration2 = getAutoHeightDuration(wrapperSize);
            node.style.transitionDuration = `${duration2}ms`;
            autoTransitionDuration.current = duration2;
        } else {
            node.style.transitionDuration =
                typeof transitionDuration === 'string' ? transitionDuration : `${transitionDuration}ms`;
        }
        node.style[size] = collapsedSize;
        node.style.transitionTimingFunction = transitionTimingFunction;
        onExiting && onExiting(node);
    });

    const addEndListener = (next) => {
        if (timeout === 'auto') {
            timer.current = setTimeout(next, autoTransitionDuration.current || 0);
        }
    };

    return (
        <TransitionComponent
            in={inProp}
            onEnter={handleEnter}
            onEntered={handleEntered}
            onEntering={handleEntering}
            onExit={handleExit}
            onExited={handleExited}
            onExiting={handleExiting}
            addEndListener={addEndListener}
            nodeRef={nodeRef}
            timeout={timeout === 'auto' ? null : timeout}
            {...other}
        >
            {(state, childProps) => {
                return (
                    <CollapseRoot
                        as={component}
                        ref={handleRef}
                        style={{
                            [isHorizontal ? 'minWidth' : 'minHeight']: collapsedSize,
                        }}
                        {...{ [state]: state }}
                        {...childProps}
                        isHorizontal={isHorizontal}
                        collapsedSize={collapsedSizeProp}
                        className={className}
                    >
                        <CollapseWrapper isHorizontal={isHorizontal} ref={wrapperRef}>
                            <CollapseWrapperInner isHorizontal={isHorizontal}>{children}</CollapseWrapperInner>
                        </CollapseWrapper>
                    </CollapseRoot>
                );
            }}
        </TransitionComponent>
    );
});

Collapse.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * The content node to be collapsed.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The width (horizontal) or height (vertical) of the container when collapsed.
     * @default '0px'
     */
    collapsedSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.any,
    /**
     * The transition timing function.
     * You may specify a single easing or a object containing enter and exit values.
     */
    easing: PropTypes.oneOfType([
        PropTypes.shape({
            enter: PropTypes.string,
            exit: PropTypes.string,
        }),
        PropTypes.string,
    ]),
    /**
     * If `true`, the component will transition in.
     */
    in: PropTypes.bool,
    /**
     * @ignore
     */
    onEnter: PropTypes.func,
    /**
     * @ignore
     */
    onEntered: PropTypes.func,
    /**
     * @ignore
     */
    onEntering: PropTypes.func,
    /**
     * @ignore
     */
    onExit: PropTypes.func,
    /**
     * @ignore
     */
    onExited: PropTypes.func,
    /**
     * @ignore
     */
    onExiting: PropTypes.func,
    /**
     * The transition orientation.
     * @default 'vertical'
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * @ignore
     */
    style: PropTypes.object,
    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     *
     * Set to 'auto' to automatically calculate transition time based on height.
     * @default duration.standard
     */
    timeout: PropTypes.oneOfType([
        PropTypes.oneOf(['auto']),
        PropTypes.number,
        PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number,
        }),
    ]),
};

Collapse.supportAuto = true;

export default Collapse;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const DollarSign = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M8.172,9.117,3.953,7.883a1.152,1.152,0,0,1,.324-2.258h2.59a2.384,2.384,0,0,1,1.336.41.609.609,0,0,0,.762-.078l1.359-1.328a.633.633,0,0,0-.07-.957A5.524,5.524,0,0,0,6.875,2.5V.625A.627.627,0,0,0,6.25,0H5a.627.627,0,0,0-.625.625V2.5h-.1A4.28,4.28,0,0,0,.02,7.172a4.472,4.472,0,0,0,3.273,3.773l4,1.172a1.152,1.152,0,0,1-.324,2.258H4.383a2.384,2.384,0,0,1-1.336-.41.609.609,0,0,0-.762.078L.926,15.371a.633.633,0,0,0,.07.957A5.524,5.524,0,0,0,4.375,17.5v1.875A.627.627,0,0,0,5,20H6.25a.627.627,0,0,0,.625-.625V17.492A4.412,4.412,0,0,0,11,14.652,4.289,4.289,0,0,0,8.172,9.117Z"
                transform="translate(6.999 2)"
            />
        </SvgIcon>
    );
});

export default DollarSign;

import React, {useLayoutEffect, useState} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import ShowLoading from './Components/loading/ShowLoading';
import {PrivateRoute, IconButton} from '@Components/';
import {Close as CloseIcon} from '@SvgIcon/';
import {SnackbarProvider} from 'notistack';
import {LOGIN_ROOT_ROUTER} from '@Router/routerPath';
import AppVersion from "./AppVersion";
import LoginPage from '@Local/Login/';
import Home from '@Local/Home/';

import '@Scss/site.scss';


const App = React.forwardRef((props, ref) => {
    const [isRoute, setRoute] = useState(false);
    const {pathname: rootPath = ''} = useLocation();
    const notistackRef = React.createRef();
    
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    useLayoutEffect(
        () => {
            const splitPathname = rootPath.split('/');
            if (splitPathname.length > 1 && splitPathname[1] === 'login') setRoute(true);
        },
        // eslint-disable-next-line
        [rootPath]
    );
    
    return (
        <React.Fragment>
            <SnackbarProvider
                className="snacker-massage"
                ref={notistackRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                action={(key) => (
                    <IconButton onClick={onClickDismiss(key)}>
                        <CloseIcon htmlColor="#fff"/>
                    </IconButton>
                )}
            >
                <AppVersion />
                <Switch>
                    {isRoute && <Route exact path={LOGIN_ROOT_ROUTER} component={LoginPage}/>}
                    <PrivateRoute as={Home}/>
                </Switch>
            </SnackbarProvider>
            <ShowLoading/>
        </React.Fragment>
    );
});

export default App;

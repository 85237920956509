import { PointsProductGiftItemExchangePage, PointsRedeemPage, PointsAuditPage, PointProductGiftItemPage, PointsActivityPage } from '@Local/Points/';
import {
    POINTS_OVERVIEW_ROUTER,
    POINTS_REDEEM_OVERVIEW_ROUTER,
    POINTS_PRODUCT_GIFT_OVERVIEW_ROUTER,
    POINTS_ACTIVITY_OVERVIEW_ROUTER,
    POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER,
    POINTS_AUDIT_OVERVIEW_TYPE_ROUTER,
} from '@Router/routerPath';

const pointsRouter = [
    {
        description: '點數管理',
        frontKey: 'H3X01',
    },
    {
        description: '會員點數總覽',
        frontKey: 'H3X02',
        static: true,
        path: POINTS_OVERVIEW_ROUTER,
        href: POINTS_OVERVIEW_ROUTER,
        as: PointsRedeemPage,
    },
    {
        description: '贈品兌換總覽',
        frontKey: 'H3X03',
        static: true,
        path: POINTS_REDEEM_OVERVIEW_ROUTER,
        href: POINTS_REDEEM_OVERVIEW_ROUTER,
        as: PointsProductGiftItemExchangePage,
    },
    {
        description: '點數活動總覽',
        frontKey: 'H3X04',
        static: true,
        path: POINTS_ACTIVITY_OVERVIEW_ROUTER,
        href: POINTS_ACTIVITY_OVERVIEW_ROUTER,
        as: PointsActivityPage,
    },
    {
        description: '兌換品總覽',
        frontKey: 'H3X05',
        static: true,
        path: POINTS_PRODUCT_GIFT_OVERVIEW_ROUTER,
        href: POINTS_PRODUCT_GIFT_OVERVIEW_ROUTER,
        as: PointProductGiftItemPage,
    },
    {
        description: '點數審核總覽',
        frontKey: 'H3X06',
        exact: true,
        path: POINTS_AUDIT_OVERVIEW_TYPE_ROUTER,
        href: POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER,
        as: PointsAuditPage,
    },
];

export default pointsRouter;

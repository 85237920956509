import React, { createContext, useContext } from 'react';

const PointsContext = createContext(undefined);

export const PointsProvider = ({ children, value }) => {
    return <PointsContext.Provider value={value}>{children}</PointsContext.Provider>;
};

export const usePointsContext = () => {
    const context = useContext(PointsContext);
    if (context === undefined) {
        throw new Error('usePointsContext 必須在 PointsProvider 中使用');
    }
    return context;
};

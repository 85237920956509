import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const NavigateNext = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M16.76,12.66,11.45,18a.94.94,0,0,1-1.32,0h0l-.88-.89a.92.92,0,0,1,0-1.32h0L13,12,9.24,8.24a.92.92,0,0,1,0-1.32h0L10.12,6a.94.94,0,0,1,1.32,0h0l5.32,5.32a.94.94,0,0,1,0,1.32Z" />
        </SvgIcon>
    );
});

export default NavigateNext;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const InfoCircle = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M10,.313A9.688,9.688,0,1,0,19.688,10,9.689,9.689,0,0,0,10,.313Zm0,4.3A1.641,1.641,0,1,1,8.359,6.25,1.641,1.641,0,0,1,10,4.609Zm2.188,9.922a.469.469,0,0,1-.469.469H8.281a.469.469,0,0,1-.469-.469v-.937a.469.469,0,0,1,.469-.469H8.75v-2.5H8.281a.469.469,0,0,1-.469-.469V9.219a.469.469,0,0,1,.469-.469h2.5a.469.469,0,0,1,.469.469v3.906h.469a.469.469,0,0,1,.469.469Z"></path>
        </SvgIcon>
    );
});

export default InfoCircle;

import React, { useRef } from 'react';
import clsx from 'clsx';
import { Card, Box, Stack, Typography } from '@Components/';
import { ListInfo, ScrollerArrowButton, Tablenization } from '@Local/Components/';
import { QuestionCircle as QuestionCircleIcon } from '@SvgIcon/';
import { usePointsContext } from '@Local/Points/Components/PointsContext';
import usePointsRedeemOrderApi from '@Apis/usePointsRedeemOrderApi';

const headerRow = [
    {
        cellKey: 'imageSrc',
        headerLabel: '贈品',
        align: 'left',
        isFixed: true,
        isFixedLeft: true,
        formatCell: (value, rowData, { onClick }) => {
            return (
                <Stack alignItems="center" spacing={1}>
                    <Box className="product-gift-img-label">
                        <img src={value} alt={rowData.itemName} />
                    </Box>
                    <Typography variant="h6" className="font-weight-bold">
                        {rowData.itemName}
                    </Typography>
                    <QuestionCircleIcon
                        className="cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClick(rowData);
                        }}
                    />
                </Stack>
            );
        },
    },
    {
        cellKey: 'points',
        headerLabel: '兌換點數',
        align: 'center',
        width: 8,
        formatCell: (value) => {
            return <Typography variant="h5" component="span">{`${value}點`}</Typography>;
        },
    },
    {
        cellKey: 'activityRedeemQty',
        headerLabel: '兌換數量',
        align: 'center',
        width: 8,
        formatCell: (value) => {
            return (
                <Typography variant="h5" component="span">
                    {value}
                </Typography>
            );
        },
    },
];

const PointsProductGiftItemExchangeTable = React.forwardRef((props, ref) => {
    const { deletePointsRewardOrderCleanCacheApi } = usePointsRedeemOrderApi();
    const { dialogRef, sourceData, refresh } = usePointsContext();
    const scrollerArrowRef = useRef(null);

    const handleProductsInfoOnClick = (rowData) => {
        dialogRef.current.openPointsProductGuideDialog(rowData);
    };

    const handleRowOnClick = (rowData) => {
        dialogRef.current.openProductGiftExchangeRecordDialog(rowData);
    };

    const handleRefreshOnClick = () => {
        doCleanCacheApi();
    };

    const doCleanCacheApi = async () => {
        await deletePointsRewardOrderCleanCacheApi();
        setTimeout(refresh, 500);
    };

    return (
        <Card className="mt-4">
            <Card.CardContent className="p-3">
                <Stack direction="column">
                    <ListInfo 
                        className={clsx('ml-auto', 'mb-2')} 
                        pageSize={sourceData.length} pageIndex={1} 
                        totalCount={sourceData.length}
                        onRefresh={handleRefreshOnClick}
                    />
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization cardProps={{ className: 'theme-gray' }} tableContainerRef={scrollerArrowRef} cellWidth={12} isPagination isFixed>
                            <Tablenization.TableRowHeader headerRow={headerRow} />
                            <Tablenization.TableRowBody
                                headerRow={headerRow}
                                rowsData={sourceData}
                                onClick={handleProductsInfoOnClick}
                                rowOnClick={handleRowOnClick}
                            />
                        </Tablenization>
                        <ScrollerArrowButton parentRef={scrollerArrowRef} />
                    </Box>
                </Stack>
            </Card.CardContent>
        </Card>
    );
});

export default PointsProductGiftItemExchangeTable;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Refresh = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="m4.46,8.53c.39-1.09,1.01-2.12,1.89-2.99,3.13-3.13,8.19-3.13,11.32,0l.86.86h-1.72c-.89,0-1.6.72-1.6,1.6s.72,1.6,1.6,1.6h5.6c.89,0,1.6-.72,1.6-1.6V2.4c0-.89-.72-1.6-1.6-1.6s-1.6.72-1.6,1.6v1.76l-.88-.88C15.55-1.09,8.46-1.09,4.08,3.28c-1.22,1.22-2.1,2.66-2.64,4.19-.3.84.15,1.75.98,2.04.83.3,1.75-.15,2.04-.98h0Zm-3.31,4.34c-.25.08-.49.21-.69.41-.2.2-.34.44-.41.7-.02.06-.03.13-.04.19-.02.09-.02.17-.02.26v5.58c0,.89.72,1.6,1.6,1.6s1.6-.72,1.6-1.6v-1.76l.88.88h0c4.38,4.37,11.47,4.37,15.84,0,1.22-1.22,2.11-2.66,2.65-4.19.3-.84-.15-1.75-.98-2.04s-1.75.15-2.04.98c-.39,1.09-1.01,2.12-1.89,2.99-3.13,3.13-8.19,3.13-11.32,0h0s-.86-.86-.86-.86h1.72c.89,0,1.6-.72,1.6-1.6s-.72-1.6-1.6-1.6H1.62c-.08,0-.16,0-.24.02s-.16.03-.23.05Z" />
        </SvgIcon>
    );
});

export default Refresh;

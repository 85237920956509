import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const FolderPlus = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M18.125,5h-7.5l-2.5-2.5H1.875A1.875,1.875,0,0,0,0,4.375v11.25A1.875,1.875,0,0,0,1.875,17.5h16.25A1.875,1.875,0,0,0,20,15.625V6.875A1.875,1.875,0,0,0,18.125,5Zm-3.75,6.563a.625.625,0,0,1-.625.625H10.938V15a.625.625,0,0,1-.625.625H9.688A.625.625,0,0,1,9.063,15V12.188H6.25a.625.625,0,0,1-.625-.625v-.625a.625.625,0,0,1,.625-.625H9.063V7.5a.625.625,0,0,1,.625-.625h.625a.625.625,0,0,1,.625.625v2.813H13.75a.625.625,0,0,1,.625.625Z"
                transform="translate(2 2.5)"
            ></path>
        </SvgIcon>
    );
});

export default FolderPlus;

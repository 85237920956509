import React, { useMemo, useRef } from 'react';
import { Switch } from 'react-router-dom';
import { Box, Card, Typography, PrivateRoute } from '@Components/';
import { PointsAuditTable, PointsAuditTabs } from '@Local/Points/Audit';
import { PointsProvider } from '@Local/Points/Components/PointsContext';
import { POINTS_AUDIT_OVERVIEW_PASS_TRIAL_ROUTER, POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER, POINTS_AUDIT_OVERVIEW_NON_TRIAL_ROUTER } from '@Router/routerPath';
import { PointsDialogBox } from '@Local/Points/Dialog/';

const PointsAuditPage = React.forwardRef((props, ref) => {
    const auditStatusMapRef = useRef({});
    const refreshRef = useRef({});
    const skipAuditRef = useRef(0);
    const refreshOptionsRef = useRef(null);
    const dialogRef = useRef(null);
    const Tabs = useMemo(
        () => <PointsAuditTabs />,
        // eslint-disable-next-line
        []
    );

    const childValue = {
        refresh: () => {
            if (typeof refreshRef.current === 'function') {
                setTimeout(refreshRef.current(), 500);
            }
        },
        setRefreshRef: (el) => (refreshRef.current = el),
        auditStatusMapFn: () => auditStatusMapRef.current,
        setAuditStatusMapRef: (target) => (auditStatusMapRef.current = target),
        setSkipAuditRef: (el) => (skipAuditRef.current = el),
        getSkipAudit: () => skipAuditRef.current,
        getDialog: () => dialogRef.current,
        refreshOptions: (callback) => typeof refreshOptionsRef.current === 'function' && refreshOptionsRef.current(callback),
        setRefreshOptionsRef: (el) => (refreshOptionsRef.current = el),
    };

    return (
        <PointsProvider value={childValue}>
            <Box className="container main-container-spacing point-audit-container">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        點數審核總覽
                    </Typography>
                </header>
                <Card className="my-3">
                    <Card.CardContent>
                        {Tabs}
                        <Switch>
                            <PrivateRoute static path={POINTS_AUDIT_OVERVIEW_PASS_TRIAL_ROUTER} as={PointsAuditTable} headerLabelType={'PASSTRIAL'} />
                            <PrivateRoute static path={POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER} as={PointsAuditTable} headerLabelType={'ONTRIAL'} />
                            <PrivateRoute static path={POINTS_AUDIT_OVERVIEW_NON_TRIAL_ROUTER} as={PointsAuditTable} headerLabelType={'NONTRIAL'} />
                        </Switch>
                    </Card.CardContent>
                </Card>
                <PointsDialogBox ref={dialogRef} />
            </Box>
        </PointsProvider>
    );
});

export default PointsAuditPage;

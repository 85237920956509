import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react';
import useForkRef from '@Util/hook/useForkRef';
import MembershipDocumentDialog from '@Local/ClubManagement/Dialog/MembershipDocumentDialog';
import MembershipItemDialog from '@Local/ClubManagement/Dialog/MembershipItemDialog';

const MEMBERSHIP_DOCUMENT_DIALOG = 'membership-document-dialog';
const MEMBERSHIP_ITEM_DIALOG = 'membership-item-dialog';

const ClubManagementDialogBox = forwardRef((props, ref) => {
    const dialogRef = useRef(null);
    const combinedRef = useForkRef(ref, dialogRef);
    const [openDialog, setOpenDialog] = useState('');
    const [sourceData, setSourceData] = useState({});
    const refreshRef = useRef(null);

    const doRefresh = () => {
        if (refreshRef.current && typeof refreshRef.current === 'function') refreshRef.current();
    };

    const handleCloseDialog = () => {
        setOpenDialog(null);
        setSourceData(null);
        refreshRef.current = null;
    };

    useImperativeHandle(
        combinedRef,
        () => ({
            openMembershipDocumentDialog: (targetData = {}, _refreshFn = () => {}) => {
                refreshRef.current = _refreshFn;
                setSourceData(targetData);
                setOpenDialog(MEMBERSHIP_DOCUMENT_DIALOG);
            },
            openMembershipItemDialog: (targetData = {}, _refreshFn = () => {}) => {
                refreshRef.current = _refreshFn;
                setSourceData(targetData);
                setOpenDialog(MEMBERSHIP_ITEM_DIALOG);
            },
            closeDialog: () => handleCloseDialog(),
        }),
        // eslint-disable-next-line
        [openDialog, sourceData],
    );
    return (
        <React.Fragment>
            <MembershipDocumentDialog
                open={openDialog === MEMBERSHIP_DOCUMENT_DIALOG}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
            <MembershipItemDialog open={openDialog === MEMBERSHIP_ITEM_DIALOG} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
        </React.Fragment>
    );
});

export default ClubManagementDialogBox;

import { CYCLE_PURCHASE_VERIFICATION_BILLING_OVERVIEW_ROUTER, CYCLE_PURCHASE_BILLING_ROUTER } from '@Router/routerPath';
import { CyclePurchaseVerificationBillingPage, CyclePurchaseBillingDetailPage } from '@Local/CyclePurchase/';

const cyclePurchaseRouter = [
    {
        description: '循環訂單',
        frontKey: 'H5X01',
    },
    {
        description: '檢核扣款總覽',
        frontKey: 'H5X02',
        static: true,
        path: CYCLE_PURCHASE_VERIFICATION_BILLING_OVERVIEW_ROUTER,
        href: CYCLE_PURCHASE_VERIFICATION_BILLING_OVERVIEW_ROUTER,
        as: CyclePurchaseVerificationBillingPage,
    },
    {
        description: '銀行扣款明細',
        frontKey: 'H5X03',
        static: true,
        path: CYCLE_PURCHASE_BILLING_ROUTER,
        as: CyclePurchaseBillingDetailPage,
    },
];

export default cyclePurchaseRouter;

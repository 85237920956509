import React from 'react';
import ClubManagementMembershipTable from '@Local/ClubManagement/ClubManagementMembershipTable';
import MemberPanel from '@Local/ClubManagement/Component/MemberPanel';
import { useClubManagementContext } from '@Local/ClubManagement/Component/ClubManagementContext';

const ClubManagementMembershipContent = (props) => {
    const { sourceData, dialogRef } = useClubManagementContext();
    return (
        <React.Fragment>
            <MemberPanel className={'mb-1'} sourceData={sourceData} />
            <ClubManagementMembershipTable sourceData={sourceData} dialogRef={dialogRef} />
        </React.Fragment>
    );
};

export default ClubManagementMembershipContent;

import React from 'react';
import PropTypes from 'prop-types';
import { debounce, ownerWindow } from '@Util/utils';

const styles = {
    width: 99,
    height: 99,
    position: 'absolute',
    top: -9999,
    overflow: 'scroll',
};

/**
 * @ignore - internal component.
 * The component originates from https://github.com/STORIS/react-scrollbar-size.
 * It has been moved into the core in order to minimize the bundle size.
 */
export default function ScrollbarSize(props) {
    const { className, onChange, ...other } = props;
    const scrollbarHeight = React.useRef();
    const nodeRef = React.useRef(null);

    const setMeasurements = () => {
        scrollbarHeight.current = nodeRef.current.offsetHeight - nodeRef.current.clientHeight;
    };

    React.useEffect(() => {
        const handleResize = debounce(() => {
            const prevHeight = scrollbarHeight.current;
            setMeasurements();

            if (prevHeight !== scrollbarHeight.current) {
                onChange(scrollbarHeight.current);
            }
        });

        const containerWindow = ownerWindow(nodeRef.current);
        containerWindow.addEventListener('resize', handleResize);
        return () => {
            handleResize.clear();
            containerWindow.removeEventListener('resize', handleResize);
        };
    }, [onChange]);

    React.useEffect(() => {
        setMeasurements();
        onChange(scrollbarHeight.current);
    }, [onChange]);

    return <div style={styles} className={className} ref={nodeRef} {...other} />;
}

ScrollbarSize.propTypes = {
    onChange: PropTypes.func.isRequired,
};

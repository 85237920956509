import React, { useEffect, useRef, useState } from 'react';
import { Card, Button, MenuItem, SelectField, Stack, Form } from '@Components/';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { useCyclePurchaseContext } from '@Local/CyclePurchase/Components/CyclePruchaseContext';
import useCyclePurchaseApi from '@Apis/useCyclePurchaseApi';
import { CYCLE_PURCHASE_VERIFICATION_BILLING_OVERVIEW_ROUTER } from '@Router/routerPath';
import { isEmpty, objectToQueryString, paramsToObject, refIsRequiredError } from '@Util/utils';

const CyclePurchaseVerificationBillingSearchBar = (props) => {
    const { className } = props;
    const { dialogRef, setSourceData, bookmark, setBookmarkNum, setRefresh } = useCyclePurchaseContext();
    const history = useHistory();
    const { getCyclePurchaseTasksOverviewApi, getCyclePurchaseTasksOverviewOptionsApi } = useCyclePurchaseApi();
    const [paramsData, setParamsData] = useState({});
    const [options, setOptions] = useState({});
    const statusRef = useRef(null);
    const preloadRef = useRef(false);
    const taskStatusOption = options.taskStatusOption || [];

    const getParams = (bookmark) => {
        const result = {};

        if (bookmark) {
            Object.assign(result, bookmark);
        }

        if (statusRef.current && statusRef.current.getResult) {
            console.log(statusRef.current.getResult(), 'status');
            Object.assign(result, { status: statusRef.current.getResult() });
        }

        return result;
    };

    const updateUrlWithSearchQuery = (params) => {
        let url = CYCLE_PURCHASE_VERIFICATION_BILLING_OVERVIEW_ROUTER;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getOverviewApi = async (params) => {
        let resp = await getCyclePurchaseTasksOverviewApi(params);
        if (resp) {
            setSourceData(resp);
        }
        updateUrlWithSearchQuery(params);
    };

    const getOptionsApi = async () => {
        let resp = await getCyclePurchaseTasksOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
            const { location } = history;
            const search = location.search;
            const params = search ? paramsToObject(search) : getParams(bookmark);
            if (!isEmpty(params)) {
                setParamsData(params);
            }
            preloadRef.current = false;
            getOverviewApi(params);
        }
    };

    const handleSearchOnClick = () => {
        const isError = refIsRequiredError(statusRef);
        if (!isError) {
            const params = getParams(bookmark);
            getOverviewApi(params);
            setBookmarkNum(1);
        }
    };

    const openUploadCyclePurchaseOrderSheetDialog = () => {
        if (dialogRef.current && typeof dialogRef.current.openUploadCyclePurchaseOrderSheetDialog === 'function') {
            dialogRef.current.openUploadCyclePurchaseOrderSheetDialog({}, handleSearchOnClick);
        }
    };

    useEffect(
        () => {
            if (!preloadRef.current) return;
            if (String(bookmark.pageIndex) !== '1') return;
            const params = getParams(bookmark);
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark.pageIndex],
    );

    useEffect(
        () => {
            if (preloadRef.current) return;
            preloadRef.current = true;
            setRefresh(handleSearchOnClick);
            getOptionsApi();
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={clsx('main-search-bar', className)}>
            <Card.CardContent className={'px-4 py-3'}>
                <Form onSubmit={handleSearchOnClick}>
                    <Grid spacing={2} container>
                        <Grid xs={3} item>
                            <SelectField
                                key={paramsData?.status || 99}
                                ref={statusRef}
                                label={'狀態'}
                                defaultValue={paramsData?.status || 99}
                                direction="row"
                                displayEmpty
                                fullWidth
                            >
                                {taskStatusOption
                                    .filter((item) => !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={6} item>
                            <Stack className={'h-100'} alignItems={'center'}>
                                <Button type={'submit'} variant={'contained'}>
                                    搜尋
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid xs={3} item>
                            <Stack className={'h-100'} alignItems={'center'} justifyContent={'end'}>
                                <Button variant={'outlined'} onClick={openUploadCyclePurchaseOrderSheetDialog}>
                                    匯入主檔
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            </Card.CardContent>
        </Card>
    );
};

export default CyclePurchaseVerificationBillingSearchBar;

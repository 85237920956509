import React, {useImperativeHandle, useRef} from 'react';
import {IconButton, MenuItem, Select} from '@Components/';
import {refIsRequiredError} from '@Util/utils';
import clsx from 'clsx';
import {Refresh as RefreshIcon} from "@SvgIcon/";

const fakeSortOptions = [
    {
        text: '建立日期新→舊',
        value: 1,
    },
    {
        text: '建立日期舊→新',
        value: 2,
    },
    {
        text: '金額高→低',
        value: 3,
    },
    {
        text: '金額低→高',
        value: 4,
    },
];

const ListInfo = React.forwardRef((props, ref) => {
    const {
        className,
        pageSize = 10,
        pageIndex = 1,
        totalCount = 0,
        sortDefaultValue,
        sortOptions = fakeSortOptions,
        sortOnChange,
        isSort = false,
        isCurrentPageFirstIndex = false,
        isCurrentPageLastIndex = false,
        onRefresh
    } = props;
    const sortRef = useRef();

    // 取得頁數資訊
    const getPageInfoText = () => {
        const prevPageLastIndex = pageSize * (pageIndex - 1);
        const currentPageFirstIndex = prevPageLastIndex + 1;
        let currentPageLastIndex = pageSize * pageIndex;

        if (totalCount < currentPageLastIndex) {
            currentPageLastIndex = totalCount;
        }
        let text = '';
        if (!isCurrentPageFirstIndex && !isCurrentPageLastIndex) return text;
        if (isCurrentPageFirstIndex) text += currentPageFirstIndex;
        if (isCurrentPageFirstIndex && isCurrentPageLastIndex) text += ' - ';
        if (isCurrentPageLastIndex) text += currentPageLastIndex;
        text += '筆';
        return text;
    };

    const handleSortChange = (e, _, value) => {
        if (typeof sortOnChange === 'function') sortOnChange(value);
    };
    
    const handleRefresh = () => {
        if (typeof onRefresh === 'function') onRefresh();
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            if (sortRef && sortRef.current && sortRef.current.getResult) return sortRef.current.getResult();
        },
        isError: () => refIsRequiredError(sortRef),
    }));
    return (
        <div className={className}>
            {totalCount > 0 && <span className={'mr-1'}>{getPageInfoText()}</span>}
            <span className={clsx({'mr-2': isSort})}>{`(共${totalCount}筆)`}</span>
            {isSort && (
                <Select key={sortDefaultValue} ref={sortRef} defaultValue={sortDefaultValue} onChange={handleSortChange}>
                    {Array.isArray(sortOptions) &&
                        sortOptions.map(({text, value}) => (
                            <MenuItem key={value} value={value}>
                                {text}
                            </MenuItem>
                        ))}
                </Select>
            )}
            {onRefresh && (
                <IconButton onClick={handleRefresh}>
                    <RefreshIcon/>
                </IconButton>
            )}
        </div>
    );
});

export default ListInfo;

import React from 'react';
import clsx from 'clsx';
import { Avatar, Box, Typography, Stack } from '@Components/';

export const MemberInfoBox = React.memo((props) => {
    const { className, style, memberName, subtitle, memberNo } = props;

    return (
        <Box className={className} style={style}>
            <Typography className={'member-info-card-title'} variant={'h5'}>
                {memberName}
            </Typography>
            {subtitle && (
                <Typography className={'member-info-card-subtitle'} variant={'body2'}>
                    {subtitle}
                </Typography>
            )}
            <Typography className={'member-info-card-other'} variant={'body2'}>
                {memberNo}
            </Typography>
        </Box>
    );
});

const MemberInfoCard = (props) => {
    const { className, memberName = '', subtitle = '', memberNo = '', memberImg = '', memberAvatarWidth = '7', isShowAvatar = false, onClick } = props;

    const handleOnClick = () => {
        typeof onClick === 'function' && onClick();
    };

    return (
        <Stack className={clsx('member-info-card', className)} onClick={handleOnClick} justifyContent={'start'} alignItems="center">
            {isShowAvatar && <Avatar src={memberImg} alt={memberName} width={memberAvatarWidth} />}
            <MemberInfoBox
                className={clsx(isShowAvatar && 'member-info-card-content')}
                style={{ maxWidth: isShowAvatar ? `calc(100% - ${memberAvatarWidth + 'rem'})` : '' }}
                memberName={memberName}
                subtitle={subtitle}
                memberNo={memberNo}
            />
        </Stack>
    );
};

export default MemberInfoCard;

import React, { useEffect, useState, useRef } from 'react';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Box, Button, Divider, AutocompleteField } from '@Components/';
import { ReadTextField } from '@Local/Components/';
import { isEmpty, refIsRequiredError } from '@Util/utils';
import usePeopleApi from '@Apis/usePeopleApi';
import usePointsRewardsApi from '@Apis/usePointsRewardsApi';

const PointsMergeDialog = React.forwardRef((props, ref) => {
    const { open: openProps, onClose: onCloseProps, sourceData: memberDataProps, refresh } = props;
    if (!openProps) return null;
    const { getClubPeopleDataApi } = usePeopleApi();
    const { getPointsRewardsOverviewOptionsApi, postPointMergeApi } = usePointsRewardsApi();
    const [sourceData, setSourceData] = useState({});
    const [options, setOptions] = useState({});
    const peopleIDRef = useRef(null);
    const clubIDRef = useRef(null);
    const {
        rewardID: rewardIDProps,
        clubID: clubIDProps,
        clubName: clubNameProps,
        displayName: displayNameProps,
        cellPhone: cellPhoneProps,
        memberNo: memberNoProps,
        totalPoints: totalPointsProps,
    } = memberDataProps;
    const { clubOption = [] } = options;

    const getParams = (cID, pID) => {
        return {
            sourceClubID: clubIDProps,
            sourceRewardID: rewardIDProps,
            targetClubID: cID || 0,
            targetRewardID: pID || 0,
        };
    };

    const handleClubChange = (targetData) => {
        if (!targetData) {
            setSourceData({});
        } else {
            const { value } = targetData;
            setClubPeolpeApi(value);
        }
    };

    const handleConfirmClick = () => {
        if (!refIsRequiredError(clubIDRef, peopleIDRef)) {
            const params = getParams(clubIDRef.current.getResult('value'), peopleIDRef.current.getResult('id'));
            doPointMergeDataApi(params, params.targetRewardID);
        }
    };

    const doPointMergeDataApi = async (params, rID) => {
        const resp = await postPointMergeApi(params, rID);
        if (resp) {
            refresh();
            onCloseProps();
        }
    };

    const setClubPeolpeApi = async (clubID) => {
        const resp = await getClubPeopleDataApi(clubID, 52);
        if (resp) {
            let list = resp;
            if (String(clubID) === String(clubIDProps)) {
                list = list.filter(({ id }) => String(id) !== String(rewardIDProps));
            }
            setSourceData(list);
        }
    };

    const initMergeOptions = async () => {
        const resp = await getPointsRewardsOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    useEffect(
        () => {
            if (openProps && isEmpty(options)) {
                initMergeOptions();
            }
        },
        // eslint-disable-next-line
        [openProps]
    );
    
    return (
        <Dialog PaperProps={{ className: 'wx-90rem' }} open={openProps} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>合併點數</GradientColorHeader>
            <DialogContent>
                <Box className="p-2">
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <ReadTextField label="店舖" children={clubNameProps} />
                        </Grid>
                        <Grid item xs={6}>
                            <ReadTextField label="移轉會員" children={`${displayNameProps},${memberNoProps.slice(-5)},${cellPhoneProps || ''}`} />
                        </Grid>
                        <Grid item xs={2}>
                            <ReadTextField label="移轉點數" children={totalPointsProps} />
                        </Grid>
                    </Grid>
                    <Divider className={'mt-4 mb-2'} />
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <AutocompleteField
                                ref={clubIDRef}
                                key={clubOption}
                                label="店舖"
                                options={clubOption}
                                optionKeys={['text']}
                                primaryKey={'value'}
                                inputProps={{ placeholder: '店舖名稱' }}
                                onChange={handleClubChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        {sourceData.length > 0 && (
                            <Grid item xs={6}>
                                <AutocompleteField
                                    key={sourceData}
                                    ref={peopleIDRef}
                                    label={'合併會員'}
                                    options={sourceData}
                                    optionKeys={['name', 'mobile', 'no']}
                                    primaryKey={'id'}
                                    inputProps={{ placeholder: '請輸入' }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} disabled={isEmpty(sourceData)} onClick={handleConfirmClick}>
                    確認合併
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointsMergeDialog;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { localUser, isEmpty } from '@Util/utils';
// mode is exact or strict

const RedirectComponent = (props) => {
    const { ...other } = props;
    return <Redirect {...other} />;
};

const PrivateRoute = ({ as: Component, ...other }) => {
    const user = localUser.get();
    return (
        <Route
            {...other}
            render={(props) => {
                return !isEmpty(user) ? <Component {...props} {...other} /> : <RedirectComponent to={'/login'} />;
            }}
        />
    );
};
export default PrivateRoute;

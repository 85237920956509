import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, IconButton, ListItemText, Menu, MenuItem, Stack, Typography, FormControlLabel, Switch } from '@Components/';
import { ScrollerArrowButton, Tablenization } from '@Local/Components';
import { MoreVertical as MoreVerticalIcon } from '@SvgIcon/';
import { displayDateText } from '@Util/moment';
import { usePromotionPlanContext } from '@Local/PromotionPlan/Components/PromotionPlanContext';
import usePromotionPlanApi from '@Apis/usePromotionPlanApi';

/**
 * 生成行銷活動資訊
 * @params {string} planInfo - 行銷活動相關的資訊物件
 * @params {Object} planInfo.freeType - 會籍前或會籍後的文字描述
 * @params {number} planInfo.joinFee - 入會費金額
 * @params {string} planInfo.freeTypeText - 會籍前或會籍後的文字描述
 * @params {number} planInfo.freeMonth - 贈送的會籍月份數
 * @params {number} planInfo.freeDay - 贈送的會籍天數
 * @returns {string} - 行銷活動生成的文字
 */
const generatePlanText = ({ freeTypeText, freeMonth, freeDay }) => {
    // 生成贈送文字
    let freeText = '';
    if (freeMonth > 0 && freeDay > 0) {
        freeText = `贈送${freeTypeText}${freeMonth}個月又${freeDay}天`;
    } else if (freeMonth > 0) {
        freeText = `贈送${freeTypeText}${freeMonth}個月`;
    } else if (freeDay > 0) {
        freeText = `贈送${freeTypeText}${freeDay}天`;
    }

    return freeText;
};

const ActionMenuButton = ({ menuData = [], actionButtonProps = {}, actionMenuProps = {}, textAlign = 'left', enabled = true, ...other }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickActionButton = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // 為啟用與沒有操作項就不顯示
    if (!enabled || menuData.length < 1) return null;

    return (
        <React.Fragment>
            <IconButton {...actionButtonProps} onClick={handleClickActionButton}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu className={clsx('more-label-box-menu', `align-${textAlign}`)} anchorEl={anchorEl} open={open} onClose={(e) => handleCloseMenu(null)}>
                {menuData.map(({ text, ...others }) => {
                    return (
                        <MenuItem {...others} key={`more_label_${text}`}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

const PlanEnableSwitch = (props) => {
    const { defaultValue, planID, refresh } = props;
    const { putPromotionPlanEnableApi } = usePromotionPlanApi();
    const apiSendingRef = useRef(false);

    const handlePromotionPlanEnableApi = async (planID, params) => {
        const resp = await putPromotionPlanEnableApi(planID, params);
        if (resp) {
            apiSendingRef.current = false;
            if (typeof refresh === 'function') refresh();
        }
    };

    const handleChangeSwitch = (e, checked) => {
        let params = { enabled: checked };
        if (!apiSendingRef.current) {
            apiSendingRef.current = true;
            handlePromotionPlanEnableApi(planID, params);
        }
    };

    return <FormControlLabel className={'m-0'} control={<Switch key={defaultValue} defaultChecked={defaultValue} onChange={handleChangeSwitch} />} />;
};

const headerRow = [
    {
        cellKey: 'planName',
        headerLabel: '項目名稱',
        formatCell: (planName, { planCode }) => {
            return (
                <Stack alignItems={'start'} direction={'column'}>
                    <Typography component={'span'}>{planName}</Typography>
                    <Typography component={'span'} variant={'body2'}>
                        {planCode}
                    </Typography>
                </Stack>
            );
        },
    },
    {
        cellKey: 'planDescription',
        headerLabel: '項目說明',
    },
    {
        cellKey: 'joinFee',
        headerLabel: '設定內容',
        formatCell: (joinFee, rowData) => {
            const planText = generatePlanText(rowData);
            return (
                <>
                    <Typography className={'mb-0'}>{`入會費$${joinFee}元`}</Typography>
                    {planText && <Typography className={'mb-0'}>{planText}</Typography>}
                </>
            );
        },
    },
    {
        cellKey: 'startDate',
        headerLabel: '生效時間',
        formatCell: (startDate, { endDate }) => {
            return displayDateText(startDate, endDate);
        },
    },
    {
        cellKey: 'isEnabled',
        headerLabel: '是否生效',
        align: 'center',
        formatCell: (isEnabled, { planID }, { refresh }) => {
            return <PlanEnableSwitch defaultValue={isEnabled} planID={planID} refresh={refresh} />;
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        width: 6.8,
        formatCell: (value, rowData, { openPromotionPlanDialog, refresh }) => {
            const menuData = [
                {
                    text: '編輯方案',
                    onClick: (e) => {
                        openPromotionPlanDialog(rowData, refresh);
                    },
                },
            ];
            return <ActionMenuButton menuData={menuData} />;
        },
    },
];

const PromotionPlanTable = () => {
    const { dialogRef, sourceData, setBookmarkNum, refresh } = usePromotionPlanContext();
    const { pageIndex = 1, totalPage, list = [] } = sourceData || {};
    const scrollerArrowRef = useRef(null);

    const handleChangePage = (e, num) => {
        if (typeof setBookmarkNum === 'function') setBookmarkNum(num);
    };

    return (
        <Box className={'scroll-arrow-container overview'}>
            <Tablenization
                cardProps={{ className: 'theme-gray' }}
                page={pageIndex}
                count={totalPage}
                onChange={handleChangePage}
                tableContainerRef={scrollerArrowRef}
                isPagination
            >
                <Tablenization.TableRowHeader headerRow={headerRow} />
                <Tablenization.TableRowBody
                    headerRow={headerRow}
                    rowsData={list}
                    refresh={refresh}
                    bodyRowClass={{
                        key: 'isEnabled',
                        value: false,
                        className: 'overview-item-invalid',
                    }}
                    openPromotionPlanDialog={dialogRef.current && dialogRef.current.openPromotionPlanDialog}
                />
            </Tablenization>
            <ScrollerArrowButton parentRef={scrollerArrowRef} />
        </Box>
    );
};

export default PromotionPlanTable;

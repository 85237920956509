import React, { useRef, useState } from 'react';
import { Box, Typography, Tabs, Tab, Button, Stack } from '@Components/';
import PromotionPlanTable from '@Local/PromotionPlan/PromotionPlanTable';
import PromotionPlanSearchBar from '@Local/PromotionPlan/PromotionPlanSearchBar';
import { PromotionPlanProvider, usePromotionPlanContext } from '@Local/PromotionPlan/Components/PromotionPlanContext';
import { PromotionPlanDialogBox } from '@Local/PromotionPlan/Dialog';
import { initBookmark } from '@Local/PromotionPlan/staticData';

const PromotionPlanTabs = () => {
    const planTypeMap = [
        {
            label: '當月促銷',
            value: 1,
        },
        {
            label: '企業員工優惠',
            value: 2,
        },
        {
            label: '其他優惠專案',
            value: 3,
        },
        {
            label: '合約優惠',
            value: 4,
        },
    ];
    const { planType, setPlanType, dialogRef, refresh } = usePromotionPlanContext();

    const handleChangeTabs = (_, val) => {
        if (typeof setPlanType === 'function') setPlanType(val);
    };

    const handleOpenPromotionPlanDialog = () => {
        console.log(dialogRef);
        if (dialogRef.current && dialogRef.current.openPromotionPlanDialog) {
            dialogRef.current.openPromotionPlanDialog(null, refresh);
        }
    };

    return (
        <Stack justifyContent={'space-between'} alignItems={'center'}>
            <Tabs value={planType} onChange={handleChangeTabs}>
                {planTypeMap.map((item) => (
                    <Tab key={item.value} className={'py-3'} label={item.label} value={item.value} />
                ))}
            </Tabs>
            <Box className="btn-group">
                <Button variant={'outlined'} onClick={handleOpenPromotionPlanDialog}>
                    新增方案
                </Button>
            </Box>
        </Stack>
    );
};
const PromotionPlanPage = (props) => {
    const [sourceData, setSourceData] = useState({});
    const [planType, setPlanType] = useState(1);
    const [bookmark, setBookmark] = useState({
        ...initBookmark(),
    });
    const refreshRef = useRef(null);
    const dialogRef = useRef(null);

    const childContext = {
        dialogRef: dialogRef,
        sourceData,
        setSourceData,
        planType,
        setPlanType,
        bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        refresh: () => typeof refreshRef.current === 'function' && refreshRef.current(),
        setRefresh: (fn) => (refreshRef.current = fn),
    };

    return (
        <PromotionPlanProvider value={childContext}>
            <Box className={'container main-container-spacing promotion-plan-container'}>
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        行銷方案總覽
                    </Typography>
                </Box>
                <PromotionPlanSearchBar />
                <PromotionPlanTabs />
                <PromotionPlanTable />
                <PromotionPlanDialogBox ref={dialogRef} />
            </Box>
        </PromotionPlanProvider>
    );
};

export default PromotionPlanPage;

import React, { createContext, useContext } from 'react';

const PromotionPlanContext = createContext(undefined);

export const PromotionPlanProvider = ({ children, value }) => {
    return <PromotionPlanContext.Provider value={value}>{children}</PromotionPlanContext.Provider>;
};

export const usePromotionPlanContext = () => {
    const context = useContext(PromotionPlanContext);
    if (context === undefined) {
        throw new Error('usePromotionPlanContext 必須在 PromotionPlanProvider 中使用');
    }
    return context;
};

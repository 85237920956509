import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent, Slide, Grid } from '@mui/material';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { ReadTextField } from '@Local/Components/';
import usePointsRedeemItemApi from '@Apis/usePointsRedeemItemApi';
import { TextField, Button } from '@Components/';
import { isEmpty, refIsRequiredError } from '@Util/utils';

const InventoryBox = (props) => {
    const { sourceData, itemID, onClose: onCloseProps } = props;
    const { postProductGiftItemInventoryApi } = usePointsRedeemItemApi();
    const { quantity } = sourceData || {};
    const quantityRef = useRef(null);
    const reasonRef = useRef(null);

    const getParams = () => {
        let params = { quantity: quantityRef.current.getResult(), reason: reasonRef.current.getResult() };
        return params;
    };

    const handleCofirmClick = () => {
        if (!refIsRequiredError(quantityRef, reasonRef)) {
            const params = getParams();
            putInventoryApi(params, itemID);
        }
    };

    const putInventoryApi = async (params, redeemItemID) => {
        const resp = await postProductGiftItemInventoryApi(params, redeemItemID);
        if (resp) onCloseProps();
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={6} item>
                <ReadTextField label="目前庫存" children={quantity} fullWidth />
            </Grid>
            <Grid xs={6} item>
                <TextField ref={quantityRef} label="庫存量" maskType="money" InputProps={{ placeholder: '請輸入要增加或減少' }} fullWidth required />
            </Grid>
            <Grid xs={12} item>
                <TextField ref={reasonRef} label="原因" rows={5} multiline fullWidth required />
            </Grid>
            <Grid xs={12} item>
                <Button variant="contained" onClick={handleCofirmClick}>
                    送出
                </Button>
            </Grid>
        </Grid>
    );
};

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PointsProductGiftItemInventoryDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: sourceDataProps = {} } = props;
    if (!openProps) return null;
    const { getPointsProductGiftItemDataApi } = usePointsRedeemItemApi();
    const [sourceData, setSourceData] = useState({});
    const { itemID } = sourceDataProps;
    const getInventoryApi = async (targetID) => {
        const resp = await getPointsProductGiftItemDataApi(targetID);
        if (resp) {
            const { inventory } = resp;
            setSourceData(inventory);
        }
    };

    useEffect(
        () => {
            getInventoryApi(itemID);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Dialog open={openProps} TransitionComponent={Transition} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>庫存量調整</GradientColorHeader>
            <DialogContent>
                <InventoryBox key={isEmpty(sourceData)} sourceData={sourceData} itemID={itemID} onClose={onCloseProps} />
            </DialogContent>
        </Dialog>
    );
});

export default PointsProductGiftItemInventoryDialog;

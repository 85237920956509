import React from 'react';
import clsx from 'clsx';
import { Paper } from '@Components/';

const staticClass = `card`;

const CardHeaderContent = (props) => {
    const defaultProperty = {
        className: `${staticClass}-header-content`,
        ...props,
    };
    return <div {...defaultProperty} />;
};

const CardHeaderAvatar = (props) => {
    const defaultProperty = {
        className: `${staticClass}-header-avatar`,
        ...props,
    };
    return <div {...defaultProperty} />;
};

const CardHeaderAction = (props) => {
    const defaultProperty = {
        className: `${staticClass}-header-action`,
        ...props,
    };
    return <div {...defaultProperty} />;
};

const Card = React.forwardRef((props, ref) => {
    const { className, raised = false, style, isOverflowHidden = false, ...other } = props;
    let classNames = `${staticClass}-root`;
    if (isOverflowHidden) classNames = clsx(classNames, `${staticClass}-overflow-hidden`);
    const defaultProperty = {
        className: clsx(classNames, className),
        style,
        elevation: raised ? 3 : undefined,
        ref,
        ...other,
    };
    return <Paper {...defaultProperty} />;
});

Card.CardHeader = React.forwardRef((props, ref) => {
    const { className, action, avatar, style, disableTypography = false, subheader: subheaderProp, title: titleProp, ...other } = props;

    let title = titleProp;
    if (typeof title === 'string' && !disableTypography) {
        title = <span className={clsx(`${staticClass}-header-content-title`, avatar ? 'fullWidth' : '')}>{title}</span>;
    }
    let subheader = subheaderProp;
    if (typeof subheader === 'string' && !disableTypography) {
        subheader = <span className={clsx(`${staticClass}-header-content-subTitle`, avatar ? 'fullWidth' : '')}>{subheader}</span>;
    }

    const defaultProperty = {
        className: clsx(`${staticClass}-header`, className),
        style,
        ref,
        ...other,
    };

    return (
        <div {...defaultProperty}>
            {avatar && <CardHeaderAvatar>{avatar}</CardHeaderAvatar>}
            <CardHeaderContent>
                {title}
                {subheader}
            </CardHeaderContent>
            {action && <CardHeaderAction>{action}</CardHeaderAction>}
        </div>
    );
});

Card.CardContent = React.forwardRef((props, ref) => {
    const { className, style, ...other } = props;

    const defaultProperty = {
        className: clsx(`${staticClass}-content`, className),
        ref,
        style,
        ...other,
    };
    return <div {...defaultProperty}></div>;
});

Card.CardActions = React.forwardRef((props, ref) => {
    const { className, disableSpacing, position, ...others } = props;
    let classNames = `${staticClass}-actions`;
    if (!disableSpacing) classNames = clsx(classNames, `${staticClass}-actions-spacing`);
    if (position === 'end') classNames = clsx(classNames, `${staticClass}-position-end`);
    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...others,
    };
    return <div {...defaultProperty} />;
});

export default Card;

import React, {useRef} from 'react';
import {Box, Stack, Typography} from '@Components/';
import {Tablenization} from '@Local/Components';
import MemoControllerBox from '@Local/Components/MemoControllerBox';
import {useCyclePurchaseContext} from '@Local/CyclePurchase/Components/CyclePruchaseContext';
import {formatCurrencyFn} from '@Util/utils';

// const COLOR_MATCH = {
//     1: 'success',
//     2: 'info',
//     3: 'grey',
//     4: 'error',
//     9: 'error',
// };
//
// const fakerData = [
//     {
//         orderNo: 'A000240315C0001',
//         key: '銀行系統異常',
//         memberName: '會員1',
//         memberNo: 'A0000000001',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 5,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 3,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 3,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 3,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 2,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 16,
//         orderTotal: 22080,
//         email: 'email1@example.com',
//         phone: '09876543201',
//         address: '地址1',
//         shippingDay: '第1天',
//         invoice: {
//             carrierType: 1,
//             carrierTypeText: '會員載具',
//             carrierNo: '',
//             issuingType: 2,
//             issuingTypeText: '二聯式發票',
//             taxTitle: '',
//             taxID: '',
//             donationCode: '',
//         },
//         creditCardNo: '1234-5678-9012-01',
//         cardExpiryDate: '2021-12',
//     },
//     {
//         orderNo: 'A000240315C0002',
//         key: '銀行系統異常',
//         memberName: '會員2',
//         memberNo: 'A0000000002',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 3,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 5,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 1,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 5,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 3,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 17,
//         orderTotal: 23460,
//         email: 'email2@example.com',
//         phone: '09876543202',
//         address: '地址2',
//         shippingDay: '第2天',
//         invoice: '發票資訊2',
//         creditCardNo: '1234-5678-9012-02',
//         cardExpiryDate: '2022-12',
//     },
//     {
//         orderNo: 'A000240315C0003',
//         key: '網路連線問題',
//         memberName: '會員3',
//         memberNo: 'A0000000003',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 2,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 2,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 1,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 3,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 5,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 13,
//         orderTotal: 17940,
//         email: 'email3@example.com',
//         phone: '09876543203',
//         address: '地址3',
//         shippingDay: '第3天',
//         invoice: '發票資訊3',
//         creditCardNo: '1234-5678-9012-03',
//         cardExpiryDate: '2023-12',
//     },
//     {
//         orderNo: 'A000240315C0004',
//         key: '信用卡資訊錯誤',
//         memberName: '會員4',
//         memberNo: 'A0000000004',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 3,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 3,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 1,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 4,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 5,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 16,
//         orderTotal: 22080,
//         email: 'email4@example.com',
//         phone: '09876543204',
//         address: '地址4',
//         shippingDay: '第4天',
//         invoice: '發票資訊4',
//         creditCardNo: '1234-5678-9012-04',
//         cardExpiryDate: '2024-12',
//     },
//     {
//         orderNo: 'A000240315C0005',
//         key: '信用卡額度不足',
//         memberName: '會員5',
//         memberNo: 'A0000000005',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 1,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 5,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 2,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 4,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 4,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 16,
//         orderTotal: 22080,
//         email: 'email5@example.com',
//         phone: '09876543205',
//         address: '地址5',
//         shippingDay: '第5天',
//         invoice: '發票資訊5',
//         creditCardNo: '1234-5678-9012-05',
//         cardExpiryDate: '2025-12',
//     },
//     {
//         orderNo: 'A000240315C0006',
//         key: '信用卡資訊錯誤',
//         memberName: '會員6',
//         memberNo: 'A0000000006',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 1,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 2,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 2,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 1,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 3,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 9,
//         orderTotal: 12420,
//         email: 'email6@example.com',
//         phone: '09876543206',
//         address: '地址6',
//         shippingDay: '第6天',
//         invoice: '發票資訊6',
//         creditCardNo: '1234-5678-9012-06',
//         cardExpiryDate: '2026-12',
//     },
//     {
//         orderNo: 'A000240315C0007',
//         key: '網路連線問題',
//         memberName: '會員7',
//         memberNo: 'A0000000007',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 5,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 5,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 5,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 3,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 3,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 21,
//         orderTotal: 28980,
//         email: 'email7@example.com',
//         phone: '09876543207',
//         address: '地址7',
//         shippingDay: '第7天',
//         invoice: '發票資訊7',
//         creditCardNo: '1234-5678-9012-07',
//         cardExpiryDate: '2027-12',
//     },
//     {
//         orderNo: 'A000240315C0008',
//         key: '信用卡資訊錯誤',
//         memberName: '會員8',
//         memberNo: 'A0000000008',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 3,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 5,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 5,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 1,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 4,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 18,
//         orderTotal: 24840,
//         email: 'email8@example.com',
//         phone: '09876543208',
//         address: '地址8',
//         shippingDay: '第8天',
//         invoice: '發票資訊8',
//         creditCardNo: '1234-5678-9012-08',
//         cardExpiryDate: '2028-12',
//     },
//     {
//         orderNo: 'A000240315C0009',
//         key: '信用卡額度不足',
//         memberName: '會員9',
//         memberNo: 'A0000000009',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 2,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 3,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 4,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 1,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 2,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 12,
//         orderTotal: 16560,
//         email: 'email9@example.com',
//         phone: '09876543209',
//         address: '地址9',
//         shippingDay: '第9天',
//         invoice: '發票資訊9',
//         creditCardNo: '1234-5678-9012-09',
//         cardExpiryDate: '2029-12',
//     },
//     {
//         orderNo: 'A000240315C0010',
//         key: '網路連線問題',
//         memberName: '會員10',
//         memberNo: 'A0000000010',
//         statusColor: 4,
//         statusText: '扣款失敗',
//         item: [
//             {
//                 name: '抹茶 ',
//                 quantity: 2,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '鐵觀音 ',
//                 quantity: 2,
//                 category: '乳清蛋白',
//             },
//             {
//                 name: '松露野菇',
//                 quantity: 3,
//                 category: '蛋白暖暖湯',
//             },
//             {
//                 name: '普通',
//                 quantity: 2,
//                 category: '益起動',
//             },
//             {
//                 name: '普通 ',
//                 quantity: 3,
//                 category: '鈣力嗨',
//             },
//         ],
//         itemTotal: 12,
//         orderTotal: 16560,
//         email: 'email10@example.com',
//         phone: '09876543210',
//         address: '地址10',
//         shippingDay: '第10天',
//         invoice: '發票資訊10',
//         creditCardNo: '1234-5678-9012-10',
//         cardExpiryDate: '20210-12',
//     },
// ];

const rowsLabel = [
    {
        cellKey: 'cycleOrderNo',
        headerLabel: '主單編號',
        align: 'center',
    },
    {
        cellKey: 'contactResult',
        headerLabel: '交易結果',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} dialogTitle={'交易結果'}/>;
        },
    },
    {
        cellKey: 'memberName',
        headerLabel: '會員',
        formatCell: (memberName, {memberNo}) => {
            return memberName ? (
                <Stack direction={'column'}>
                    <Box className={'mb-1'}>{memberName}</Box>
                    <Box>{memberNo}</Box>
                </Stack>
            ) : (
                <Stack direction={'column'}>
                    <Box className={'font-color-3'}>無法取得對應會員</Box>
                </Stack>
            );
        },
    },
    {
        cellKey: 'memberCellPhone',
        headerLabel: '手機',
    },
    {
        cellKey: 'transactionPrice',
        headerLabel: '訂單金額',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue, {symbol: '$'});
        },
    },
];

const CyclePurchaseBillingDetailPaymentFailureTable = () => {
    const {sourceData} = useCyclePurchaseContext();
    const list = sourceData.failureRecords || [];
    const labelTitle = useRef(rowsLabel);
    return (
        <Box>
            <Stack className={'mb-2'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography className={'font-weight-bold'} variant={'h5'}>
                    扣款失敗清單
                </Typography>
            </Stack>
            <Tablenization
                cardProps={{className: 'theme-gray overview'}}
                // count={totalPage}
                // page={pageIndex}
                // onChange={handlePageChange}
                // isPagination
            >
                <Tablenization.TableRowHeader headerRow={labelTitle.current}/>
                <Tablenization.TableRowBody
                    headerRow={labelTitle.current}
                    rowsData={list}
                    // dialogRef={dialogRef}
                    // goToOrderPage={goToOrderPage}
                    // goToAccountBookPage={goToAccountBookPage}
                    // goToAllowanceRecord={goToAllowanceRecord}
                    // refresh={refreshFn}
                />
            </Tablenization>
        </Box>
    );
};

export default CyclePurchaseBillingDetailPaymentFailureTable;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Reply = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M.325,7.415,7.2,1.479a.938.938,0,0,1,1.55.71V5.315C15.025,5.387,20,6.645,20,12.591a7.985,7.985,0,0,1-3.255,6.021.7.7,0,0,1-1.1-.728c1.771-5.665-.84-7.168-6.9-7.256v3.434a.938.938,0,0,1-1.55.71L.325,8.835a.938.938,0,0,1,0-1.419Z"
                transform="translate(2 1.75)"
            />
        </SvgIcon>
    );
});

export default Reply;

import React, {useState, useEffect} from 'react';
import {Stack, Box, Chip} from '@Components/';
import {usePointsContext} from "@Local/Points/Components/PointsContext";

const SkipAuditBox = (props, ref) => {
    const { skipAudit } = props;
    const {getSkipAudit, getDialog, refreshOptions : refreshFn} = usePointsContext();
    const [skipAuditState, setSkipAuditStatus] = useState(0);

    const handleSkipAudit = () => {
        const dialog = getDialog();
        dialog.openPointSkipAudit(
            {skipAudit: skipAuditState},
            () => refreshFn(() => setSkipAuditStatus(getSkipAudit()))
        );
    };

    useEffect(
        () => {
            setSkipAuditStatus(getSkipAudit());
        },
        // eslint-disable-next-line
        [skipAudit]
    );

    return (
        <Stack className="mb-2">
            <Box className="align-self-center">{`目前過審點數為：`}</Box>
            <Chip onClick={handleSkipAudit} label={skipAuditState} color="error"/>
        </Stack>
    );
};

export default SkipAuditBox;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Dollar = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#D02952', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12.9,18.4V20c0,0.2-0.2,0.4-0.4,0.4
	c0,0,0,0,0,0h-1.1c-0.2,0-0.5-0.2-0.5-0.4c0,0,0,0,0,0v-1.5c-1,0-1.9-0.2-2.8-0.6c-0.4-0.2-0.6-0.6-0.5-1l0.2-0.7
	c0.1-0.5,0.6-0.8,1.1-0.6c0,0,0.1,0,0.1,0c0.8,0.3,1.7,0.5,2.5,0.5c1.2,0,2-0.5,2-1.3c0-0.8-0.7-1.3-2.2-1.8
	C9,12.3,7.4,11.2,7.4,9.2c0-1.8,1.3-3.2,3.5-3.7V4c0-0.3,0.2-0.5,0.5-0.5h1.1C12.8,3.6,13,3.8,13,4v1.4c0.8,0,1.5,0.1,2.2,0.4
	c0.4,0.1,0.7,0.6,0.6,1.1l-0.2,0.6c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.7-0.2-1.4-0.3-2.1-0.3
	c-1.3,0-1.8,0.6-1.8,1.2c0,0.7,0.7,1.1,2.5,1.8c2.5,0.9,3.5,2,3.5,3.9C16.6,16.4,15.2,17.9,12.9,18.4z"
            />
        </SvgIcon>
    );
});

export default Dollar;

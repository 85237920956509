import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Magnifier = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M17.9,15.538l-4.027-3.425a1.8,1.8,0,0,0-1.221-.53,6.376,6.376,0,1,0-.714.714,1.8,1.8,0,0,0,.53,1.221l3.425,4.027A1.428,1.428,0,1,0,17.9,15.538ZM7.8,11.7a4.25,4.25,0,1,1,4.25-4.25A4.25,4.25,0,0,1,7.8,11.7Z"
                transform="translate(2.572 2.929)"
            ></path>
        </SvgIcon>
    );
});

export default Magnifier;

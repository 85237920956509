import React, {useState, useRef, useEffect} from 'react';
import clsx from 'clsx';
import {Card, Box, Stack, Typography, IconButton, Menu, ListItemText, MenuItem, Checkbox} from '@Components/';
import {ListInfo, Tablenization, ScrollerArrowButton} from '@Local/Components/';
import {MoreVertical as MoreVerticalIcon, Check as CheckIcon, ErrorOutline as ErrorOutlineIcon} from '@SvgIcon/';
import {usePointsContext} from '@Local/Points/Components/PointsContext';
import {parseDate} from '@Util/moment';
import {isEmpty} from '@Util/utils';
import usePointsRewardsApi from '@Apis/usePointsRewardsApi';
import useMessageDialog from '@Util/hook/useMessageDialog';

const headerRow = [
    {
        cellKey: 'displayName',
        headerLabel: '會員',
        width: 10,
        maxWidth: 10,
        formatCell: (value, {memberNo}) => {
            return (
                <Stack alignItems="start" direction="column">
                    <Typography component="span">{value}</Typography>
                    <Typography component="span">{memberNo}</Typography>
                </Stack>
            );
        },
    },
    {
        cellKey: 'cellPhone',
        headerLabel: '手機',
        width: 10,
        maxWidth: 10,
        align: 'center',
        formatCell: (value) => {
            return <Typography component="span">{value}</Typography>;
        },
    },
    {
        cellKey: 'totalPoints',
        headerLabel: '總點數',
        width: 2,
        align: 'center',
        formatCell: (value, rowData, {onMoreClick}) => {
            const {prepareToResetDate} = rowData;

            return (
                <React.Fragment>
                    <Typography
                        component="span"
                        variant="h4"
                        color="main"
                        className="vertical-align-middle"
                        onClick={() => onMoreClick('redeemMemberRecord', rowData)}
                    >
                        {value}
                    </Typography>
                    {prepareToResetDate && <ErrorOutlineIcon color={'error'} fontSize={'small'}/>}
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'currentPoints',
        headerLabel: '目前點數',
        width: 2,
        align: 'center',
        formatCell: (value) => {
            return (
                <Typography component="span" variant="h4">
                    {value}
                </Typography>
            );
        },
    },
    {
        cellKey: 'redeemDate',
        headerLabel: '兌換內容',
        width: 20,
        maxWidth: 20,
        formatCell: (value, rowData, {onMoreClick}) => {
            return (
                <RedeemRecordsBox
                    redeemDate={value}
                    point={rowData.redeemPoints}
                    memo={rowData.redeemDesc}
                    onClick={() => onMoreClick('productExchange', rowData)}
                />
            );
        },
    },
    {
        cellKey: 'redeemStatus',
        headerLabel: '已簽收',
        isIcon: true,
        width: 6,
        maxWidth: 6,
        align: 'center',
        formatCell: (value, {redeemRecordID, rewardID}) => {
            return <ReceivedSignBox value={value} redeemRecordID={redeemRecordID} rewardID={rewardID}/>;
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        isIcon: true,
        width: 4,
        maxWidth: 4,
        align: 'center',
        formatCell: (value, rowData, {onMoreClick}) => {
            return <MoreLabelBox rowData={rowData} onClick={onMoreClick}/>;
        },
    },
];

const RedeemRecordsBox = ({redeemDate, point, memo, onClick}) => {
    if (!redeemDate)
        return (
            <Box onClick={onClick}>
                <Typography component="span" color="gray">
                    尚未兌換
                </Typography>
            </Box>
        );

    if (point === 0)
        return (
            <Box onClick={onClick}>
                <Typography component="span" color="main">
                    本次不兌換
                </Typography>
            </Box>
        );

    return (
        <Box onClick={onClick}>
            <Typography component="span" color="main" variant="h6">{`${point}pt`}</Typography>
            <Typography component="span"> - </Typography>
            <Typography component="span">{parseDate(redeemDate, 'YYYY/MM/DD')}</Typography>
            <Typography component="div" className="text-wrap-wrap">
                {Array.isArray(memo) ? memo.join('') : memo}
            </Typography>
        </Box>
    );
};

const ReceivedSignBox = ({value: valueProps, redeemRecordID: redeemRecordIDProps, rewardID: rewardIDProps}) => {
    const [value, setValue] = useState(0);
    const {postPointsRewardsRedeemStatusApi} = usePointsRewardsApi();
    const setMessageDialog = useMessageDialog();
    const handleOnChange = (_e, checked) => {
        _e.stopPropagation();
        if (checked) {
            setMessageDialog({
                open: true,
                title: '是否要簽收',
                msg: '確認要簽收嗎?',
                onAsyncConfirm: (_, onclose) => doSignApi({status: 1}, rewardIDProps, redeemRecordIDProps, onclose),
            });
        }
    };

    const doSignApi = async (params, rewardID, redeemRecordID, onclose) => {
        const resp = await postPointsRewardsRedeemStatusApi(params, rewardID, redeemRecordID);
        if (resp) {
            setValue(params.status);
            onclose();
        }
    };

    useEffect(
        () => {
            setValue(valueProps);
        },
        // eslint-disable-next-line
        [valueProps]
    );

    return (
        <React.Fragment>
            {String(value) === `1` ? <CheckIcon color="success"/> : String(value) === `2` && <Checkbox onChange={handleOnChange} checked={false}/>}
        </React.Fragment>
    );
};

const MoreLabelBox = React.memo(({rowData, onClick: onClickProps}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const {canDelete} = rowData;

    const handleToggleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    // 關閉menu錨點
    const handleClose = (type) => {
        onClickProps(type, rowData);
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleToggleClick}>
                <MoreVerticalIcon/>
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={() => handleClose('reviseMember')}>
                    <ListItemText>編輯會員</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('productExchange')}>
                    <ListItemText>兌換贈品</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('redeemMemberRecord')}>
                    <ListItemText>累兌點紀錄</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('pointsGrant')}>
                    <ListItemText>會員補點</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('pointMerge')}>
                    <ListItemText>合併點數</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('deleteMember')} disabled={!canDelete}>
                    <ListItemText>刪除會員</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
});

const PointsRedeemTable = React.memo((props) => {
    const scrollerArrowRef = useRef(null);
    const setMessageDialog = useMessageDialog();
    const {deletePointRewardsCacheApi, deletePointsRewardApi} = usePointsRewardsApi();
    const {dialogRef, sourceData, setBookmarkNum, activityId: activityIdRef, clubIDFn, optionsFn, refresh} = usePointsContext();
    const {list = [], pageIndex = 1, pageSize = 50, totalPage, totalCount = 0} = sourceData || {};
    const clubID = clubIDFn();
    const options = optionsFn();

    const handlePageChange = (e, num) => {
        setBookmarkNum(num);
    };

    const handleMoreOnClick = (type, targetData) => {
        if (type === 'productExchange') {
            dialogRef.current.openPointsProductExchangeDialog({...targetData, activityId: activityIdRef.current, clubID}, refresh);
        } else if (type === 'redeemMemberRecord') {
            dialogRef.current.openPointsRedeemMemberRecordDialog(targetData);
        } else if (type === 'pointsGrant') {
            const opt = isEmpty(options?.coachOption ?? []) ? [] : options.coachOption;
            dialogRef.current.openPointsGrantDialog({...targetData, clubID}, refresh, opt);
        } else if (type === 'pointMerge') {
            dialogRef.current.openPointsMergeDialog({...targetData}, refresh);
        } else if (type === 'reviseMember') {
            dialogRef.current.openPointsReviseMemberDialog(
                {
                    displayName: targetData.displayName,
                    cellPhone: targetData.cellPhone,
                    memberNo: targetData.memberNo,
                    rewardID: targetData.rewardID,
                    clubID: targetData.clubID,
                },
                refresh
            );
        } else if (type === 'deleteMember') {
            setMessageDialog({
                open: true,
                title: '刪除會員點數',
                msg: `是否要刪除[${targetData.memberNo}]${targetData.displayName}點數主檔？刪除後無法復原喔。`,
                onAsyncConfirm: (_, onClose) => doDeleteMember(targetData.clubID, targetData.rewardID, onClose),
            });
        }
    };

    const handleClubCacheClean = () => {
        if (clubID > 0) doClubCacheClean(clubID);
    };

    const doClubCacheClean = async (cID) => {
        await deletePointRewardsCacheApi({clubID: cID});
        setTimeout(refresh, 100);
    };

    const doDeleteMember = async (cID, rID, onClose) => {
        const resp = deletePointsRewardApi({clubID: cID}, rID);
        if (resp) {
            onClose();
            setTimeout(refresh, 500);
        }
    }

    return (
        <Card className="mt-4">
            <Card.CardContent className="p-3">
                <Stack direction="column">
                    <ListInfo
                        className={clsx('ml-auto', 'mb-2')}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        totalCount={totalCount}
                        isCurrentPageFirstIndex
                        isCurrentPageLastIndex
                        onRefresh={handleClubCacheClean}
                    />
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization
                            cardProps={{className: 'theme-gray'}}
                            page={pageIndex}
                            count={totalPage}
                            onChange={handlePageChange}
                            tableContainerRef={scrollerArrowRef}
                            isPagination
                        >
                            <Tablenization.TableRowHeader headerRow={headerRow}/>
                            <Tablenization.TableRowBody headerRow={headerRow} rowsData={list} onMoreClick={handleMoreOnClick}/>
                        </Tablenization>
                        <ScrollerArrowButton parentRef={scrollerArrowRef}/>
                    </Box>
                </Stack>
            </Card.CardContent>
        </Card>
    );
});

export default PointsRedeemTable;

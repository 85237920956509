import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Alert, Box, Button, DateField, Form, Grid, MenuItem, SelectField } from '@Components/';
import { UploadFeedbackBox, UploadFileBox } from '@Local/Components';
import { GradientColorHeader } from '@Local/Components/Dialog';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { getToday, parseDate } from '@Util/moment';
import { isEmpty, refIsRequiredError } from '@Util/utils';
import useCyclePurchaseApi from '@Apis/useCyclePurchaseApi';

const UploadCyclePurchaseOrderSheetDialogContent = React.forwardRef((props, ref) => {
    const { feedbackData, isErrorFeedBack, onClose, options } = props;
    const today = getToday();
    const sheetRef = useRef(null);
    const shippingMonthRef = useRef(null);
    const shippingDayRef = useRef(null);
    const shippingDayOption = options.shippingDayOption || [];

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const result = {};

                if (shippingMonthRef.current && shippingMonthRef.current.getResult) {
                    Object.assign(result, { shippingMonth: parseDate(shippingMonthRef.current.getResult(), 'YYYYMM') });
                }

                if (shippingDayRef.current && shippingDayRef.current.getResult) {
                    Object.assign(result, { shippingDay: shippingDayRef.current.getResult() });
                }

                if (sheetRef.current && sheetRef.current.getResult) {
                    Object.assign(result, { file: sheetRef.current.getResult() });
                }

                return result;
            },
            isError: () => refIsRequiredError(shippingMonthRef, shippingDayRef, sheetRef),
        }),
        // eslint-disable-next-line
        [],
    );

    if (isErrorFeedBack) {
        return (
            <UploadFeedbackBox
                className={'import-ach-box'}
                feedbackData={{ failureDownloadPath: '#' }}
                isError={feedbackData?.isError || true}
                onConfirm={onClose}
                onDownload={onClose}
            />
        );
    }

    return (
        <DialogContent>
            <Alert className={'mb-2'} severity={'info'} color={'info'} variant={'outlined'}>
                基於資安考量，上傳請一律設定總表密碼再上傳檔案
            </Alert>
            <Box style={{ maxWidth: '380px', margin: '0 auto 24px' }}>
                <UploadFileBox ref={sheetRef} accept={'.xlsx'} fileExtension={['xlsx']} feedbackData={feedbackData} onConfirm={onClose} onDownload={onClose} />
            </Box>
            <Grid spacing={2} container>
                <Grid xs={6} item>
                    <DateField
                        key={parseDate(today)}
                        ref={shippingMonthRef}
                        label={'月份'}
                        defaultValue={parseDate(today)}
                        views={['year', 'month']}
                        openTo={'month'}
                        format={'YYYY/MM'}
                        mask={'____/__'}
                        placeholder={'請選擇月份'}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid xs={6} item>
                    <SelectField ref={shippingDayRef} label={'出貨週期'} defaultValue={1} displayEmpty required fullWidth>
                        <MenuItem value="" disabled>
                            <em>請選擇</em>
                        </MenuItem>
                        {shippingDayOption
                            .filter((item) => !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                    </SelectField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});
const UploadCyclePurchaseOrderSheetDialog = (props) => {
    const { open, onClose, className, refresh } = props;
    const { postCyclePurchaseTasksUploadExcelApi, getCyclePurchaseTasksOverviewOptionsApi } = useCyclePurchaseApi();
    const [feedbackData, setFeedBackData] = useState(null);
    const [options, setOptions] = useState({});
    const contentRef = useRef(null);
    const isErrorFeedBack = !isEmpty(feedbackData);

    const doCyclePurchaseUploadExcelApi = async (params, formData) => {
        const resp = await postCyclePurchaseTasksUploadExcelApi(params, formData);
        if (resp) {
            if (resp.isError) {
                const { isError, result } = resp;
                setFeedBackData({
                    isError,
                    result,
                });
            } else {
                if (typeof refresh === 'function') refresh();
                if (typeof onClose === 'function') onClose();
            }
        }
        setFeedBackData(null);
    };

    const getOptionsApi = async () => {
        let resp = await getCyclePurchaseTasksOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    const handleSubmit = (e) => {
        const isError = refIsRequiredError(contentRef);
        if (!isError) {
            const params = contentRef.current && contentRef.current.getResult();
            const { file } = params || {};
            const formData = new FormData();
            formData.append('file', file.file, `${file.name}.${file.extension}`);
            doCyclePurchaseUploadExcelApi(params, formData);
        }
    };

    useEffect(
        () => {
            if (open) {
                getOptionsApi();
            }
            return () => {
                setFeedBackData(null);
            };
        },
        // eslint-disable-next-line
        [open],
    );

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            open={open}
            onSubmit={handleSubmit}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>{isErrorFeedBack ? '上傳失敗' : '匯入主檔'}</GradientColorHeader>
            <UploadCyclePurchaseOrderSheetDialogContent
                ref={contentRef}
                feedbackData={feedbackData}
                onClose={onClose}
                options={options}
                isErrorFeedBack={isErrorFeedBack}
            />
            {!isErrorFeedBack && (
                <DialogActions>
                    <Button variant="contained" type="submit">
                        確認送出
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default UploadCyclePurchaseOrderSheetDialog;

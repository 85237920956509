import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Exclamations = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#D02952', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M7.8,14V6c0-0.8,0.7-1.5,1.5-1.5
	c0.8,0,1.5,0.7,1.5,1.5v8c0,0.8-0.7,1.5-1.5,1.5S7.8,14.8,7.8,14C7.8,14,7.8,14,7.8,14z M9.3,19.5c-0.9,0-1.6-0.7-1.6-1.6
	c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6C10.9,18.8,10.2,19.5,9.3,19.5z M13.2,6c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v8
	c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V6z M14.7,19.5c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6
	C16.3,18.8,15.6,19.5,14.7,19.5z"
            />
        </SvgIcon>
    );
});

export default Exclamations;

import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import {
    createTransitions,
    reflow,
    getTransitionProps,
    getAutoHeightDuration,
} from '@Util/createTransitions';
import useForkRef from '@Util/hook/useForkRef';


const getScale = (value) => {
    return `scale(${value}, ${value ** 2})`;
};

const styles = {
    entering: {
        opacity: 1,
        transform: getScale(1),
    },
    entered: {
        opacity: 1,
        transform: 'none',
    },
};

/*
 TODO v6: remove
 Conditionally apply a workaround for the CSS transition bug in Safari 15.4 / WebKit browsers.
 */
// const isWebKit154 =
//     typeof navigator !== 'undefined' &&
//     /^((?!chrome|android).)*(safari|mobile)/i.test(navigator.userAgent) &&
//     /(os |version\/)15(.|_)4/i.test(navigator.userAgent);

const Grow = React.forwardRef((props, ref) => {
    const {
        appear = true,
        children,
        easing,
        in: inProp,
        onEnter,
        onEntered,
        onEntering,
        onExit,
        onExited,
        onExiting,
        style,
        timeout = 'auto',
        // eslint-disable-next-line react/prop-types
        TransitionComponent = Transition,
        ...other
    } = props;

    const timer = React.useRef();
    const autoTimeout = React.useRef();
    const nodeRef = React.useRef(null);
    const foreignRef = useForkRef(children.ref, ref);
    const handleRef = useForkRef(nodeRef, foreignRef);
    const transitions = createTransitions();

    const normalizedTransitionCallback = (callback) => (maybeIsAppearing) => {
        if (callback) {
            const node = nodeRef.current;
            // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
            if (maybeIsAppearing === undefined) {
                callback(node);
            } else {
                callback(node, maybeIsAppearing);
            }
        }
    };

    const handleEntering = normalizedTransitionCallback(onEntering);
    const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
        reflow(node); // So the animation always start from the start.
        const {
            duration: transitionDuration,
            delay,
            easing: transitionTimingFunction,
        } = getTransitionProps(
            { style, timeout, easing },
            {
                mode: 'enter',
            }
        );

        let duration;
        if (timeout === 'auto') {
            duration = getAutoHeightDuration(node.clientHeight);
            autoTimeout.current = duration;
        } else {
            duration = transitionDuration;
        }

        node.style.transition = [
            transitions.create('opacity', {
                duration,
                delay,
            }),
            transitions.create('transform', {
                duration: duration * 0.666,
                delay,
                easing: transitionTimingFunction,
            }),
        ].join(',');

        if (onEnter) onEnter(node, isAppearing);
    });

    const handleEntered = normalizedTransitionCallback(onEntered);
    const handleExiting = normalizedTransitionCallback(onExiting);
    const handleExit = normalizedTransitionCallback((node) => {
        const {
            duration: transitionDuration,
            delay,
            easing: transitionTimingFunction,
        } = getTransitionProps(
            { style, timeout, easing },
            {
                mode: 'exit',
            }
        );

        let duration;
        if (timeout === 'auto') {
            duration = getAutoHeightDuration(node.clientHeight);
            autoTimeout.current = duration;
        } else {
            duration = transitionDuration;
        }

        node.style.transition = [
            transitions.create('opacity', {
                duration,
                delay,
            }),
            transitions.create('transform', {
                duration: duration * 0.666,
                delay: delay || duration * 0.333,
                easing: transitionTimingFunction,
            }),
        ].join(',');

        node.style.opacity = '0';
        node.style.transform = getScale(0.75);

        if (onExit) onExit(node);
    });

    const handleExited = normalizedTransitionCallback(onExited);
    const addEndListener = (next) => {
        if (timeout === 'auto') {
            timer.current = setTimeout(next, autoTimeout.current || 0);
        }
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    return (
        <TransitionComponent
            appear={appear}
            in={inProp}
            nodeRef={nodeRef}
            onEnter={handleEnter}
            onEntered={handleEntered}
            onEntering={handleEntering}
            onExit={handleExit}
            onExited={handleExited}
            onExiting={handleExiting}
            addEndListener={addEndListener}
            timeout={timeout === 'auto' ? null : timeout}
            {...other}
        >
            {(state, childProps) => {
                return React.cloneElement(children, {
                    style: {
                        opacity: 0,
                        transform: getScale(0.75),
                        visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
                        ...styles[state],
                        ...style,
                        ...children.props.style,
                    },
                    ref: handleRef,
                    ...childProps,
                });
            }}
        </TransitionComponent>
    );
});

Grow.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * Perform the enter transition when it first mounts if `in` is also `true`.
     * Set this to `false` to disable this behavior.
     * @default true
     */
    appear: PropTypes.bool,
    /**
     * A single child content element.
     */
    children: PropTypes.any,
    /**
     * The transition timing function.
     * You may specify a single easing or a object containing enter and exit values.
     */
    easing: PropTypes.oneOfType([
        PropTypes.shape({
            enter: PropTypes.string,
            exit: PropTypes.string,
        }),
        PropTypes.string,
    ]),
    /**
     * If `true`, the component will transition in.
     */
    in: PropTypes.bool,
    /**
     * @ignore
     */
    onEnter: PropTypes.func,
    /**
     * @ignore
     */
    onEntered: PropTypes.func,
    /**
     * @ignore
     */
    onEntering: PropTypes.func,
    /**
     * @ignore
     */
    onExit: PropTypes.func,
    /**
     * @ignore
     */
    onExited: PropTypes.func,
    /**
     * @ignore
     */
    onExiting: PropTypes.func,
    /**
     * @ignore
     */
    style: PropTypes.object,
    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     *
     * Set to 'auto' to automatically calculate transition time based on height.
     * @default 'auto'
     */
    timeout: PropTypes.oneOfType([
        PropTypes.oneOf(['auto']),
        PropTypes.number,
        PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number,
        }),
    ]),
};

Grow.supportAuto = true;

export default Grow;

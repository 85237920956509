import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const WheySuspended = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#D02952', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22.9C6,22.9,1.1,18,1.1,12
		C1.1,6,6,1.1,12,1.1C18,1.1,22.9,6,22.9,12S18,22.9,12,22.9z"
            />
            <path
                d="M12,2.2c-5.4,0-9.8,4.4-9.8,9.8s4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8S17.4,2.2,12,2.2z M15.8,18.5H8.2l-1.1-13h5.7
		v0h1.1v0h3L15.8,18.5z"
            />
            <path d="M9.3,17.4h5.4l1.1-10.8H8.2L9.3,17.4z M14.7,9.3L14,16.6h-4L9.3,9.3H14.7z" />
        </SvgIcon>
    );
});

export default WheySuspended;

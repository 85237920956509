export const DateTimeStandard = {
    DisplayFull: 'YYYY-MM-DD HH:mm',
    DisplayDate: 'YYYY-MM-DD',
    DisplayFullBySlash: 'YYYY/MM/DD HH:mm',
    DisplayDateBySlash: 'YYYY/MM/DD',
    DisplayDateOfWeek: 'YYYY/MM/DD(dd)',
    DisplayDateOfMonth: 'YYYY/MM',
    DisplayTime: 'HH:mm',
    DisplayDateWithoutMinus: 'YYYYMMDD',
    DisplayUtcFull: 'YYYY-MM-DDTHH:mmZ',
};

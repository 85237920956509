import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, IconButton, ListItemText, Menu, MenuItem, Chip } from '@Components/';
import { ScrollerArrowButton, Tablenization } from '@Local/Components';
import { MoreVertical as MoreVerticalIcon } from '@SvgIcon/';
import { parseDate } from '@Util/moment';
import { DateTimeStandard } from '../../Components/pickers/enums/DateTimeStandard';
import { formatCurrencyFn, isEmpty } from '@Util/utils';
import { useCyclePurchaseContext } from '@Local/CyclePurchase/Components/CyclePruchaseContext';
import { Link, useHistory } from 'react-router-dom';
import { CYCLE_PURCHASE_BILLING_ROUTER_URL } from '@Router/routerPath';

const matchTaskStatusColors = (taskStatus) => {
    switch (String(taskStatus)) {
        case '1':
        case '3':
            return 'info';
        case '2':
            return 'success';
        case '4':
            return 'success-darken';
        case '7':
        case '8':
        case '9':
            return 'error';
        default:
            return 'gray';
        // return 'warning';
    }
};

const ActionMenuButton = ({ menuData = [], actionButtonProps = {}, actionMenuProps = {}, textAlign = 'left', enabled = true, ...other }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickActionButton = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // 為啟用與沒有操作項就不顯示
    if (!enabled || menuData.length < 1) return null;

    return (
        <React.Fragment>
            <IconButton {...actionButtonProps} onClick={handleClickActionButton}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu className={clsx('more-label-box-menu', `align-${textAlign}`)} anchorEl={anchorEl} open={open} onClose={(e) => handleCloseMenu(null)}>
                {menuData.map(({ text, ...others }) => {
                    return (
                        <MenuItem {...others} key={`more_label_${text}`}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

const headerRow = [
    {
        cellKey: 'fileTaskID',
        headerLabel: '編號',
        formatCell: (value, { taskID, taskStatus }) => {
            const canView = String(taskStatus) === '3' || String(taskStatus) === '4';

            if (!canView) return value;

            return (
                <Link className={'text-decoration-underline text-secondary'} to={CYCLE_PURCHASE_BILLING_ROUTER_URL(taskID)}>
                    {value}
                </Link>
            );
        },
    },
    {
        cellKey: 'validateDate',
        headerLabel: '檢核上傳日期',
        formatCell: (value) => {
            return (
                <React.Fragment>
                    <Box>{parseDate(value, DateTimeStandard.DisplayDate)}</Box>
                    <Box className={'font-color-3'}>{parseDate(value, DateTimeStandard.DisplayTime)}</Box>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'settleDate',
        headerLabel: '送件扣款日期',
        formatCell: (value) => {
            if (!value) return '-';
            return (
                <React.Fragment>
                    <Box>{parseDate(value, DateTimeStandard.DisplayDate)}</Box>
                    <Box className={'font-color-3'}>{parseDate(value, DateTimeStandard.DisplayTime)}</Box>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'taskStatusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (taskStatusText, { taskStatus }) => {
            return <Chip color={matchTaskStatusColors(taskStatus)} label={taskStatusText} />;
        },
    },
    {
        cellKey: 'clubSystemTypeText',
        headerLabel: '店鋪類型',
        align: 'center',
    },
    {
        cellKey: 'shippingMonth',
        headerLabel: '出貨月份',
        align: 'center',
        formatCell: (value) => {
            return <Box>{parseDate(value, DateTimeStandard.DisplayDateOfMonth)}</Box>;
        },
    },
    {
        cellKey: 'shippingDayText',
        headerLabel: '出貨週期',
        align: 'center',
        formatCell: (value) => {
            return `${value}日`;
        },
    },
    {
        cellKey: 'totalAmount',
        headerLabel: '總筆數',
        align: 'center',
    },
    {
        cellKey: 'totalPrice',
        headerLabel: '總金額',
        align: 'center',
    },
    {
        cellKey: 'settleSuccessAmount',
        headerLabel: '扣款成功筆數',
        align: 'center',
        formatCell: (value) => {
            if (!value) return '-';
            return <Box className={'text-success'}>{value}</Box>;
        },
    },
    {
        cellKey: 'settleFailureAmount',
        headerLabel: '扣款失敗筆數',
        align: 'center',
        formatCell: (value) => {
            if (!value) return '-';
            return <Box className={'text-error'}>{value}</Box>;
        },
    },
    {
        cellKey: 'settleSuccessPrice',
        headerLabel: '實際扣款金額',
        align: 'right',
        formatCell: (value) => {
            if (!value) return '-';
            return formatCurrencyFn(value, '$');
        },
    },
    {
        cellKey: 'validateUserDisplayName',
        headerLabel: '檢核人員',
        align: 'center',
        formatCell: (value) => {
            if (!value) return '-';
            return value;
        },
    },
    {
        cellKey: 'settleUserDisplayName',
        headerLabel: '扣款人員',
        align: 'center',
        formatCell: (value) => {
            if (!value) return '-';
            return value;
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        width: 6.8,
        formatCell: (value, { taskStatus, canSettle, canDelete, taskID }, { dialogRef, refresh, gotoDetailPage }) => {
            let menuData = [];
            const { openSettleCyclePurchaseTaskDialog, openDeleteCyclePurchaseTaskDialog } = dialogRef.current || {};
            const canView = String(taskStatus) === '3' || String(taskStatus) === '4';

            if (canView) {
                menuData = [
                    ...menuData,
                    {
                        text: '查看',
                        onClick: (e) => typeof gotoDetailPage === 'function' && gotoDetailPage(taskID),
                    },
                ];
            }

            if (canSettle) {
                menuData = [
                    ...menuData,
                    {
                        text: '進行扣款',
                        onClick: (e) => typeof openSettleCyclePurchaseTaskDialog === 'function' && openSettleCyclePurchaseTaskDialog({ taskID }, refresh),
                    },
                ];
            }

            if (canDelete) {
                menuData = [
                    ...menuData,
                    {
                        text: '刪除',
                        onClick: (e) => typeof openDeleteCyclePurchaseTaskDialog === 'function' && openDeleteCyclePurchaseTaskDialog({ taskID }, refresh),
                    },
                ];
            }

            if (isEmpty(menuData)) return null;

            return <ActionMenuButton menuData={menuData} />;
        },
    },
];

const CyclePurchaseVerificationBillingTable = () => {
    const history = useHistory();
    const { dialogRef, sourceData, setBookmarkNum, refresh } = useCyclePurchaseContext();
    const { pageIndex = 1, totalPage = 1, list = [] } = sourceData || {};
    const scrollerArrowRef = useRef(null);

    const handleChangePage = (e, num) => {
        if (typeof setBookmarkNum === 'function') setBookmarkNum(num);
    };

    const gotoDetailPage = (taskID) => history.push(CYCLE_PURCHASE_BILLING_ROUTER_URL(taskID));

    return (
        <Box className={'scroll-arrow-container overview'}>
            <Tablenization
                cardProps={{ className: 'theme-gray' }}
                page={pageIndex}
                count={totalPage}
                onChange={handleChangePage}
                tableContainerRef={scrollerArrowRef}
                isPagination
            >
                <Tablenization.TableRowHeader headerRow={headerRow} />
                <Tablenization.TableRowBody
                    headerRow={headerRow}
                    rowsData={list}
                    refresh={refresh}
                    bodyRowClass={{
                        key: 'isEnabled',
                        value: false,
                        className: 'overview-item-invalid',
                    }}
                    dialogRef={dialogRef}
                    gotoDetailPage={gotoDetailPage}
                />
            </Tablenization>
            <ScrollerArrowButton parentRef={scrollerArrowRef} />
        </Box>
    );
};

export default CyclePurchaseVerificationBillingTable;

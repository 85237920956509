import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material/';
import { Form, Card, Button, MenuItem, SelectField, Typography, Stack, AutocompleteField } from '@Components/';
import { PointsProductGiftItemExchangeTable } from '@Local/Points/ProductGiftItemExchange';
import { PointsDialogBox } from '@Local/Points/Dialog/';
import { PointsProvider, usePointsContext } from '@Local/Points/Components/PointsContext';
import { initBookmark } from '@Local/Points/staticData';
import usePointsRedeemOrderApi from '@Apis/usePointsRedeemOrderApi';
import { isEmpty } from '@Util/utils';
import { head } from 'lodash/array';

const PointsProductGiftItemExchangeSearchBar = React.memo((props) => {
    const { setSourceData, setRefreshRef, setOptionsRef } = usePointsContext();
    const [options, setOptions] = useState({});
    const { getPointsRewardOrderOverviewOptionsApi, getPointsRewardOrderOverviewApi } = usePointsRedeemOrderApi();
    const activityIdRef = useRef(null);
    const clubRef = useRef(null);

    const handleSearchOnClick = () => {
        if (isEmpty(options)) return;
        let defaultActivityID = (head(activityOption) ?? { value: '' }).value;
        const params = { activityID: activityIdRef.current.getResult() || defaultActivityID, clubID: clubRef.current.getResult('value') };
        doSearchApi(params);
    };

    const doSearchApi = async (params) => {
        const resp = await getPointsRewardOrderOverviewApi(params);
        if (resp) {
            setSourceData(resp);
        }
    };

    const initOptionsApi = async () => {
        const resp = await getPointsRewardOrderOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    useEffect(
        () => {
            handleSearchOnClick();
        },
        // eslint-disable-next-line
        [options]
    );

    useEffect(
        () => {
            if (isEmpty(options)) initOptionsApi();
        },
        // eslint-disable-next-line
        []
    );
    setRefreshRef(handleSearchOnClick);
    setOptionsRef(options);

    const { activityOption = [], clubOption = [] } = options || {};
    return (
        <Form onSubmit={handleSearchOnClick}>
            <Card>
                <Card.CardContent className="p-4 px-2">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <SelectField
                                key={activityOption}
                                ref={activityIdRef}
                                label="兌換時間"
                                direction="row"
                                defaultValue={activityOption[0]?.value ?? ''}
                                displayEmpty
                                fullWidth
                                required
                            >
                                <MenuItem value="" disabled>
                                    <em>請選擇</em>
                                </MenuItem>
                                {activityOption.map(({ text, value, disabled }) => (
                                    <MenuItem key={value} value={value} disabled={disabled}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>
                        <Grid item xs={5}>
                            <AutocompleteField
                                key={clubOption.length}
                                ref={clubRef}
                                label="店鋪"
                                inputProps={{ placeholder: '請輸入店名/店碼' }}
                                options={clubOption}
                                optionKeys={['text']}
                                primaryKey={'id'}
                                direction="row"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button className="ml-2" variant="contained" type="submit">
                                搜尋
                            </Button>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Card>
        </Form>
    );
});

// 贈品兌換總覽
const PointsProductGiftItemExchangePage = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState({});
    const dialogRef = useRef(null);
    const [bookmark, setBookmark] = useState({
        ...initBookmark(),
    });
    const refreshRef = useRef(null);
    const optionsRef = useRef({});

    const childContext = {
        dialogRef: dialogRef,
        setDialogRef: (el) => (dialogRef.current = el),
        sourceData,
        setSourceData,
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        setRefreshRef: (el) => (refreshRef.current = el),
        refresh: () => typeof refreshRef.current === 'function' && refreshRef.current(),
        setOptionsRef: (el) => (optionsRef.current = el),
        // getOptions: () => (isEmpty(optionsRef.current) ? {} : optionsRef.current),
    };

    const handleDownloadExcelOnClick = () => {
        dialogRef.current.openRedeemOrderExcel(isEmpty(optionsRef.current) ? {} : optionsRef.current);
    };

    return (
        <PointsProvider value={childContext}>
            <div className="container main-container-spacing point-product-gift-exchange-container">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        贈品兌換總覽
                    </Typography>
                </header>
                <Stack className="my-2" justifyContent="end">
                    <Button variant="contained" color="secondary" onClick={handleDownloadExcelOnClick}>
                        出貨明細下載
                    </Button>
                </Stack>
                <PointsProductGiftItemExchangeSearchBar />
                <PointsProductGiftItemExchangeTable />
                <PointsDialogBox ref={dialogRef} />
            </div>
        </PointsProvider>
    );
});

export default PointsProductGiftItemExchangePage;

import React, { useMemo, useState, useRef } from 'react';
import { Box, Typography } from '@Components/';
import { PointsProvider } from '@Local/Points/Components/PointsContext';
import { PointsActivitySearchBar, PointsActivityTable } from '@Local/Points/Activity/';
import { PointsDialogBox } from '@Local/Points/Dialog/';
import { initBookmark } from '@Local/Points/staticData';

const PointActivityPage = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState([]);
    const [pageModel, setPageModel] = useState({ ...initBookmark() });
    const refreshRef = useRef(null);
    const dialogRef = useRef(null);

    const SearchBar = useMemo(
        () => <PointsActivitySearchBar />,
        // eslint-disable-next-line
        []
    );
    const TableList = useMemo(
        () => <PointsActivityTable />,
        // eslint-disable-next-line
        []
    );

    const childValue = {
        sourceData,
        setSourceData,
        pageModel,
        setPageModel,
        dialogRef,
        setNextPage: () => setPageModel((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 })),
        setRefreshRef: (el) => (refreshRef.current = el),
        refresh: () => typeof refreshRef.current === 'function' && refreshRef.current(),
    };

    return (
        <PointsProvider value={childValue}>
            <Box className="container main-container-spacing point-activity-container">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        點數活動總覽
                    </Typography>
                </header>
                {SearchBar}
                {TableList}
            </Box>
            <PointsDialogBox ref={dialogRef} />
        </PointsProvider>
    );
});

export default PointActivityPage;

import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import 'moment/locale/zh-tw';
import CalendarHeader from './CalendarHeader';
import CalendarDays from './CalendarDays';
import { Card } from '@Components/';
import { getFirstOfMonth, getLastOfMonth } from '@Util/moment';

let Calendar = React.forwardRef((props, ref) => {
    const {
        className,
        year = 2023,
        month = 0,
        selected = [],
        between = [],
        minDate = moment(getFirstOfMonth(moment(), -12)),
        maxDate = moment(getLastOfMonth(moment(), 12)),
        // onSelect = () => {},
        onDayClick = () => {},
        onDayMouseEnter = () => {},
        onDayMouseLeave = () => {},
        handleRoleDayClassName: handleRoleDayClassNameFn,
        isChecked = false,
        hasHover = false,
        multiChoices = false,
        cardProps = {},
    } = props;

    const calendarDaysRef = useRef(null);

    const isDisable = (_day) => {
        return (
            (!_.isNull(minDate) && minDate.isValid() && minDate > _day && !minDate.isSame(_day, 'day')) ||
            (!_.isNull(maxDate) && maxDate.isValid() && maxDate < _day && !maxDate.isSame(_day, 'day'))
        );
    };

    const isSelected = (_day, selectDay) => selectDay.isValid() && _day.isSame(selectDay, 'day');

    const isBetween = (_day) => {
        return _.isArray(between) && between.length === 2 && between[0] <= _day && between[1] >= _day;
    };

    const handleRoleDayClassName = (_day) => {
        const isRange = _.isArray(selected);
        let other = {};
        if (typeof handleRoleDayClassNameFn === 'function') other = handleRoleDayClassNameFn(_day);
        if (hasHover && month === _day.month() && !isDisable(_day)) {
            other['calendar-hover'] = true;
        }
        return {
            'calendar-today': _day.isSame(moment(), 'day'),
            'calendar-others': month !== _day.month(),
            'calendar-selected': !isRange && isSelected(_day, selected),
            'calendar-selected-start': isRange && selected[0] && isSelected(_day, selected[0]),
            'calendar-selected-end': isRange && selected[1] && isSelected(_day, selected[1]),
            'calendar-disabled': isDisable(_day),
            'calendar-between': isBetween(_day),
            ...other,
        };
    };

    const handleDayClick = (_day) => {
        if (isDisable(_day) || month !== _day.month()) return false;
        onDayClick && onDayClick(_day);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => calendarDaysRef.current.getResult(),
        }),
        // eslint-disable-next-line
        []
    );

    return (
        <div className={clsx('calendar', className)}>
            <Card {...cardProps}>
                <CalendarHeader year={year} month={month} />
                <CalendarDays
                    ref={calendarDaysRef}
                    year={year}
                    month={month}
                    onRoleDayClassNames={handleRoleDayClassName}
                    onDayClick={handleDayClick}
                    onDayMouseEnter={onDayMouseEnter}
                    onDayMouseLeave={onDayMouseLeave}
                    isChecked={isChecked}
                    multiChoices={multiChoices}
                />
            </Card>
        </div>
    );
});

export default Calendar;

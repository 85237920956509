import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const CashRegister = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M19.965,14.8l-1.043-6.25A1.25,1.25,0,0,0,17.688,7.5H8.125V5h3.75a.627.627,0,0,0,.625-.625V.625A.627.627,0,0,0,11.875,0h-10A.627.627,0,0,0,1.25.625v3.75A.627.627,0,0,0,1.875,5h3.75V7.5H2.309A1.253,1.253,0,0,0,1.074,8.543L.031,14.793A2.423,2.423,0,0,0,0,15.2V18.75A1.249,1.249,0,0,0,1.246,20h17.5A1.249,1.249,0,0,0,20,18.75V15.207A2.074,2.074,0,0,0,19.965,14.8ZM10.938,9.688a.627.627,0,0,1,.625-.625h.625a.627.627,0,0,1,.625.625v.625a.627.627,0,0,1-.625.625h-.625a.627.627,0,0,1-.625-.625Zm-1.25,2.5h.625a.627.627,0,0,1,.625.625v.625a.627.627,0,0,1-.625.625H9.688a.627.627,0,0,1-.625-.625v-.625A.627.627,0,0,1,9.688,12.188ZM8.438,9.063a.627.627,0,0,1,.625.625v.625a.627.627,0,0,1-.625.625H7.813a.627.627,0,0,1-.625-.625V9.688a.627.627,0,0,1,.625-.625ZM3.125,3.125V1.875h7.5v1.25Zm1.563,7.813H4.063a.627.627,0,0,1-.625-.625V9.688a.627.627,0,0,1,.625-.625h.625a.627.627,0,0,1,.625.625v.625A.627.627,0,0,1,4.688,10.938Zm.625,2.5v-.625a.627.627,0,0,1,.625-.625h.625a.627.627,0,0,1,.625.625v.625a.627.627,0,0,1-.625.625H5.938A.627.627,0,0,1,5.313,13.438Zm8.438,4.375a.313.313,0,0,1-.312.313H6.563a.313.313,0,0,1-.312-.312v-.625a.313.313,0,0,1,.313-.312h6.875a.313.313,0,0,1,.313.313Zm.938-4.375a.627.627,0,0,1-.625.625h-.625a.627.627,0,0,1-.625-.625v-.625a.627.627,0,0,1,.625-.625h.625a.627.627,0,0,1,.625.625Zm1.875-3.125a.627.627,0,0,1-.625.625h-.625a.627.627,0,0,1-.625-.625V9.688a.627.627,0,0,1,.625-.625h.625a.627.627,0,0,1,.625.625Z"
                transform="translate(2.004 2)"
            />
        </SvgIcon>
    );
});

export default CashRegister;

import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import { useSnackbar } from 'notistack';

const PUT_AUTH_SECURITY_API = `/api/me/security/`;

const useAuthUserApi = () => {
    const Axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();

    const putAuthUserPasswordApi = useCallback(
        async (params) => {
            let resp = await Axios.put(PUT_AUTH_SECURITY_API, params);
            if (resp) enqueueSnackbar('變更密碼成功，請重新登入', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        putAuthUserPasswordApi,
    };
};

export default useAuthUserApi;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const LoseTouch = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#51A69E', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M18.1,14.3c-0.5-0.6-1.4-0.6-2-0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.5,0.5-1,1-1.5,1.5c-0.1,0.1-0.3,0.2-0.4,0.1
	c0,0,0,0,0,0c-0.3-0.2-0.7-0.3-1-0.5c-1.4-0.9-2.6-2-3.7-3.4c-0.5-0.6-1-1.4-1.3-2.1c-0.1-0.1,0-0.3,0.1-0.4c0.5-0.5,1-1,1.5-1.5
	c0.6-0.5,0.7-1.4,0.2-2C9.8,6,9.8,5.9,9.7,5.8C9.3,5.4,8.9,5.1,8.5,4.7C8.1,4.3,7.8,3.9,7.4,3.5C6.8,2.9,6,2.8,5.4,3.3
	c0,0-0.1,0.1-0.1,0.1c-0.5,0.5-1,1-1.5,1.5C3.3,5.4,3,6,3,6.6c0,1,0.1,2,0.5,3c0.7,1.9,1.8,3.7,3.2,5.3c1.8,2.1,4,3.9,6.5,5.1
	c1.1,0.6,2.4,0.9,3.6,1c0.9,0.1,1.7-0.2,2.3-0.9c0.4-0.5,0.9-0.9,1.3-1.4c0.6-0.5,0.7-1.4,0.2-2c0-0.1-0.1-0.1-0.2-0.2
	C19.7,15.8,18.9,15.1,18.1,14.3L18.1,14.3z"
            />
            <path
                d="M17.1,8.9v0.4h-1.6V8.8c0-0.8,0.4-1.5,1-1.9c0.7-0.5,1-1,0.9-1.4c0-0.5-0.4-0.9-0.9-0.9c-0.7,0-1.2,0.5-1.4,1.4
	l-1.8-0.4c0.3-1.8,1.4-2.7,3.4-2.6c1.6,0.1,2.5,0.8,2.6,2.2c0,1-0.5,1.9-1.4,2.3C17.4,8,17,8.5,17.1,8.9z M17.3,11.8h-1.9V10h1.9
	L17.3,11.8z"
            />
        </SvgIcon>
    );
});

export default LoseTouch;

import React from 'react';
import useFormControl from '../formControl/useFormControl';
import formControlState from '../formControl/formControlState';
import clsx from 'clsx';

const staticClass = `formlabel`;
// const colorMap = ['error', 'add', 'primary', 'secondary', 'success', 'warning'];

const AsteriskComponent = (props) => {
    return (
        <React.Fragment>
            <span className="asterisk">&thinsp;&thinsp;{'*'}</span>
            <span>&thinsp;&thinsp;-&thinsp;&thinsp;</span>
            <span className="asterisk-text">必填</span>
        </React.Fragment>
    );
};

const FormLabel = React.forwardRef((props, ref) => {
    const {
        children,
        component: Component = 'label',
        className,
        // color,
        required,
        focused,
        disabled,
        error,
        filled,
        lightColor = false,
        inline = false,
        ...other
    } = props;
    let classNames = staticClass;

    const formControl = useFormControl();
    const fcs = formControlState({
        props,
        formControl,
        states: ['color', 'required', 'focused', 'disabled', 'error', 'filled','readOnly'],
    });

    if (fcs.disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (fcs.error) classNames = clsx(classNames, `${staticClass}-error`);
    if (fcs.focused) classNames = clsx(classNames, `${staticClass}-focused`);
    if (lightColor) classNames = clsx(classNames, `${staticClass}-color-light`);
    if (inline) classNames = clsx(classNames, `${staticClass}-inline`);
    // if (colorMap.indexOf(fcs.color) !== -1) classNames = clsx(classNames, `${staticClass}-color-${fcs.color}`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
        ...other,
    };
    
    return (
        <Component {...defaultProperty}>
            <span>{children}</span>
            {fcs.required && !fcs.readOnly && <AsteriskComponent />}
        </Component>
    );
});

export default FormLabel;

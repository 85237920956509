import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Appointment = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M10,1.25C4.477,1.25,0,4.887,0,9.375A7.212,7.212,0,0,0,2.227,14.48,9.938,9.938,0,0,1,.086,18.223a.311.311,0,0,0-.059.34.306.306,0,0,0,.285.188A8.933,8.933,0,0,0,5.8,16.742,11.913,11.913,0,0,0,10,17.5c5.523,0,10-3.637,10-8.125S15.523,1.25,10,1.25ZM5,10.625a1.25,1.25,0,1,1,1.25-1.25A1.249,1.249,0,0,1,5,10.625Zm5,0a1.25,1.25,0,1,1,1.25-1.25A1.249,1.249,0,0,1,10,10.625Zm5,0a1.25,1.25,0,1,1,1.25-1.25A1.249,1.249,0,0,1,15,10.625Z"
                transform="translate(2.333 2.333)"
            />
        </SvgIcon>
    );
});

export default Appointment;

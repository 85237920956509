import React from 'react';
import clsx from 'clsx';
import { DialogTitle } from '@mui/material';
import { IconButton } from '@Components/';
import { Close as CloseIcon } from '@SvgIcon/';

const GradientColorHeader = (props) => {
    const { title: propsTitle = '請設定標題', onClose: onCloseProps, children } = props;

    const handleOnClose = (e) => {
        onCloseProps && onCloseProps(e);
    };
    return (
        <DialogTitle className="dialog-title-header-primary text-center">
            <div className={clsx('h5 text-gray-50 font-weight-bold', onCloseProps && 'dialog-title-margin')}>{children || propsTitle}</div>
            {onCloseProps && (
                <div className="dialog-title-header-close-icon">
                    <IconButton size="large" onClick={handleOnClose}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                </div>
            )}
        </DialogTitle>
    );
};

export default GradientColorHeader;

import React, { useRef, useState } from 'react';
import { Box, Typography } from '@Components/';
import CyclePurchaseVerificationBillingSearchBar from '@Local/CyclePurchase/CyclePurchaseVerificationBillingSearchBar';
import CyclePurchaseVerificationBillingTable from '@Local/CyclePurchase/CyclePurchaseVerificationBillingTable';
import { CyclePurchaseProvider } from '@Local/CyclePurchase/Components/CyclePruchaseContext';
import CyclePurchaseDialogBox from '@Local/CyclePurchase/Dialog/CyclePurchaseDialogBox';

const initBookmark = () => ({
    pageIndex: 1,
    pageSize: 50,
});

const CyclePurchaseVerificationBillingPage = (props) => {
    const [sourceData, setSourceData] = useState({});
    const [bookmark, setBookmark] = useState(initBookmark());
    const refresh = useRef(null);
    const dialogRef = useRef(null);

    const childContext = {
        dialogRef: dialogRef,
        sourceData,
        setSourceData,
        bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        refresh: () => typeof refresh.current === 'function' && refresh.current(),
        setRefresh: (fn) => (refresh.current = fn),
    };

    return (
        <CyclePurchaseProvider value={childContext}>
            <Box className={'container main-container-spacing'}>
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        循環訂單檢核與扣款總覽
                    </Typography>
                </Box>
                <CyclePurchaseVerificationBillingSearchBar className={'mb-1'} />
                <CyclePurchaseVerificationBillingTable />
            </Box>
            <CyclePurchaseDialogBox ref={dialogRef} />
        </CyclePurchaseProvider>
    );
};

export default CyclePurchaseVerificationBillingPage;

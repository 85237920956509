/* 登入 */
export const GET_LOGIN_API = `/api/login/`;
export const GET_LOGOUT_API = `/api/logout/`;

/* 版本號 */
export const GET_SYSTEM_BUILD_TIME_API = `/api/system/build-time/`;

/* 身份驗證 */
export const GET_ME_CHECK_API = `/api/me/check/`;
export const GET_ME_API = `/api/me/`;

/* 總部用 店舖清單 */
export const GET_CLUBS_CLUBID_USERS_API = (clubId) => `/api/clubs/${clubId}/users/`;
export const POST_CLUBS_CLUBID_USERS_COMMON_API = (clubId) => `/api/clubs/${clubId}/users/common/`;
export const POST_CLUBS_CLUBID_USERS_OWNER_API = (clubId) => `/api/clubs/${clubId}/users/owner/`;
export const POST_CLUBS_CLUBID_USERS_USERID_RELATION_API = (clubId, userId) => `/api/clubs/${clubId}/users/${userId}/relation/`;
export const DELETE_CLUBS_CLUBID_USERS_USERID_RELATION_API = (clubId, userId) => `/api/clubs/${clubId}/users/${userId}/relation/`;
export const POST_CLUBS_CLUBID_USERS_USERID_ROLES_API = (clubId, userId) => `/api/clubs/${clubId}/users/${userId}/roles/`;

/* 會員清單 */
export const GET_CLUBS_CLUBID_PEOPLE_API = (clubID) => `/api/clubs/${clubID}/people/`;

/* 點數換贈品相關 */
export const GET_POINTS_REWARDS_OVERVIEW_OPTIONS_API = `/api/points/rewards/overview/options/`;
export const GET_POINTS_REWARDS_OVERVIEW_API = `/api/points/rewards/overview/`;
export const POST_PONITS_REWARDS_API = `/api/points/rewards/`;
export const PUT_PONITS_REWARDS_REWARDID_API = (rewardID) => `/api/points/rewards/${rewardID}`;
export const DELETE_PONITS_REWARDS_REWARDID_API = (rewardID) => `/api/points/rewards/${rewardID}`;
export const GET_POINTS_REWARDS_REWARDID_RECORDS_API = (rewardID) => `/api/points/rewards/${rewardID}/records/`;
export const GET_POINTS_REWARDS_EXPORT_API = `/api/points/rewards/Export/`;
export const GET_POINTS_REWARDS_EXPORT_ALL_API = `/api/points/rewards/ExportAll/`;
export const GET_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API = (rewardID, activityID) =>
    `/api/points/rewards/${rewardID}/activity/${activityID}/redeem-cart/`;
export const POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API = GET_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_REDEEM_CART_API;
export const POST_POINTS_REWARDS_REWARDID_ACTIVITY_ACTIVITYID_UNREDEEMED_API = (rewardID, activityID) =>
    `/api/points/rewards/${rewardID}/activity/${activityID}/un-redeem/`;
export const POST_POINTS_REWARDS_REWARDID_ACCUMULATE_API = (rewardID) => `/api/points/rewards/${rewardID}/accumulate/`;
export const POST_POINTS_REWARDS_REWARDID_REDEEM_STATUS_REDEEMRECORDID_API = (rewardID, redeemRecordID) =>
    `/api/points/rewards/${rewardID}/redeem-status/${redeemRecordID}/`;
export const POST_POINTS_REWARDS_REWARDID_REDEEM_COMBINE_API = (rewardID) => `/api/points/rewards/${rewardID}/redeem-combine/`;
export const DELETE_POINTS_REWARDS_OVERVIEW_CACHE_API = `/api/points/rewards/overview-cache`;
export const DELETE_POINTS_REWARDS_REWARDID_RECORDS_CACHE_API = (rewardID) => `/api/points/rewards/${rewardID}/records-cache`;
export const GET_POINTS_REWARDS_APPEND_OPTIONS_API = `/api/points/rewards/append-options`;

// 點數審核
export const GET_POINT_REWARD_AUDIT_OVERVIEW_OPTIONS_API = `/api/points/reward-audit/overview/options/`;
export const GET_POINT_REWARD_AUDIT_OVERVIEW_API = `/api/points/reward-audit/overview/`;
export const POST_POINT_REWARD_AUDIT_API = `/api/points/reward-audit/`;
export const POST_POINTS_REWARD_AUDIT_SKIP_AUDIT_API = `/api/points/reward-audit/skip-audit/`;
export const DELETE_POINTS_REWARD_AUDIT_OVERVIEW_CACHE_API = `/api/points/reward-audit/overview-cache/`;

/* 贈品清單相關 */
export const GET_POINTS_REDEEM_ORDERS_OVERVIEW_OPTIONS_API = `/api/points/redeem-orders/overview/options/`;
export const GET_POINTS_REDEEM_ORDERS_OVERVIEW_API = `/api/points/redeem-orders/overview/`;
export const GET_POINTS_REDEEM_ORDERS_REDEEMITEMID_RECORDS_API = (redeemItemID) => `/api/points/redeem-orders/${redeemItemID}/records/`;
export const POST_POINTS_REDEEM_ORDERS_EXPORT_API = `/api/points/redeem-orders/export/`;
export const DELETE_POINTS_REDEEM_ORDERS_OVERVIEW_CACHE_API = `/api/points/redeem-orders/overview-cache/`;

/* 贈品 */
export const GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_API = (redeemItemID) => `/api/points/redeem-items/${redeemItemID}/`;
export const PUT_POINTS_REDEEM_ITEMS_REDEEMITEMID_API = GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_API;
export const GET_POINTS_REDEEM_ITEMS_OVERVIEW_API = `/api/points/redeem-items/overview/`;
export const POST_POINTS_REDEEM_ITEMS_API = `/api/points/redeem-items/`;
export const POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_THUMBNAIL_IMAGE_API = (redeemItemID) => `/api/points/redeem-items/${redeemItemID}/thumbnail-image/`;
export const POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_DESCRIPTION_IMAGE_API = (redeemItemID) => `/api/points/redeem-items/${redeemItemID}/description-image/`;
export const GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API = (redeemItemID) => `/api/points/redeem-items/${redeemItemID}/inventory/`;
export const POST_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API = GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API;
export const PUT_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API = GET_POINTS_REDEEM_ITEMS_REDEEMITEMID_INVENTORY_API;

/* 點數活動 */
export const GET_POINTS_ACTIVITIES_OVERVIEW_API = `/api/points/activities/overview`;
export const GET_POINTS_ACTIVITIES_ACTIVITYID_API = (activityID) => `/api/points/activities/${activityID}`;
export const PUT_POINTS_ACTIVITIES_ACTIVITYID_API = GET_POINTS_ACTIVITIES_ACTIVITYID_API;
export const POST_POINTS_ACTIVITIES_API = `/api/points/activities`;
export const PUT_POINTS_ACTIVITIES_ACTIVITYID_RESET_ITEMS_API = (activityID) => `/api/points/activities/${activityID}/reset-items`;

/* 左側選項清單 */
export const GET_ME_MENUS_API = '/api/me/menus/';

/* 行銷方案 */
export const GET_PROMOTION_PLAN_OPTIONS_API = `/api/document/plans/options/`;
export const GET_PROMOTION_PLAN_OVERVIEW_API = `/api/document/plans/overview/`;
export const POST_PROMOTION_PLAN_API = `/api/document/plans/`;
export const PUT_PROMOTION_PLAN_API = (planID) => `/api/document/plans/${planID}/`;
export const DELETE_PROMOTION_PLAN_API = (planID) => `/api/document/plans/${planID}/`;
export const PUT_PROMOTION_PLAN_ENABLE_API = (planID) => `/api/document/plans/${planID}/enable/`;

/* 循環訂單 */
export const GET_CYCLE_PURCHASE_TASKS_OVERVIEW_OPTIONS_API = `/api/cycle-purchase-tasks/overview/options/`;
export const GET_CYCLE_PURCHASE_TASKS_OVERVIEW_API = `/api/cycle-purchase-tasks/overview/`;
export const GET_CYCLE_PURCHASE_TASKS_API = (taskID) => `/api/cycle-purchase-tasks/${taskID}/`;
export const DELETE_CYCLE_PURCHASE_TASKS_API = (taskID) => `/api/cycle-purchase-tasks/${taskID}/`;
export const POST_CYCLE_PURCHASE_TASKS_UPLOAD_EXCEL_API = (shippingMonth, shippingDay) =>
    `/api/cycle-purchase-tasks/${shippingMonth}/type/${shippingDay}/upload/`;
export const POST_CYCLE_PURCHASE_TASKS_SETTLE_API = (taskID) => `/api/cycle-purchase-tasks/${taskID}/settle/`;

import React, { createContext, useContext } from 'react';

const ClubManagementContext = createContext(undefined);

export const ClubManagementProvider = ({ children, value }) => {
    return <ClubManagementContext.Provider value={value}>{children}</ClubManagementContext.Provider>;
};

export const useClubManagementContext = () => {
    const context = useContext(ClubManagementContext);
    if (context === undefined) {
        throw new Error('useClubManagementContext 必須在 ClubManagementProvider 中使用');
    }
    return context;
};

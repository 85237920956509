import React, { useRef } from 'react';
import clsx from 'clsx';
import {Box, Stack, Typography} from '@Components/';
import { ListInfo, ScrollerArrowButton, Tablenization } from '@Local/Components/';
import { isValidDate, parseDate } from '@Util/moment';
import {ErrorOutline as ErrorOutlineIcon} from "@SvgIcon/";
import usePointsRewardsApi from "@Apis/usePointsRewardsApi";

const headerRow = [
    {
        cellKey: 'actionTypeText',
        headerLabel: '類型',
        align: 'left',
        width: 10,
        maxWidth: 10,
        formatCell: (value, {actionType}) => {
            return (actionType === 6
                    ? <ErrorOutlineIcon color={'error'}/>
                    : <Typography component="span">{value}</Typography>
            );
        },
    },
    {
        cellKey: 'recordDate',
        headerLabel: '建立時間',
        align: 'center',
        width: 20,
        maxWidth: 20,
        formatCell: (value, {actionType}) => {
            return <Typography component="span">
                {isValidDate(value) && actionType !== 6
                    ? parseDate(value, 'YYYY/MM/DD HH:MM')
                    : '-'}
            </Typography>;
        },
    },
    {
        cellKey: 'points',
        headerLabel: '點數',
        align: 'center',
        width: 10,
        maxWidth: 10,
        formatCell: (value, {actionType}) => {
            return (
                actionType !== 6
                    ? <Typography className={clsx(value < 0 ? 'minus' : 'add', 'points-redeem-box', 'mx-auto')} component="div">
                        {value || 0}
                    </Typography>
                    : '-'
            );
        },
    },
    {
        cellKey: 'statusText',
        headerLabel: '狀態',
        width: 15,
        maxWidth: 15,
        align: 'center',
        formatCell: (value) => {
            return <Typography component="span">{value || ''}</Typography>;
        },
    },
    {
        cellKey: 'desc',
        headerLabel: '內容',
        align: 'left',
        formatCell: (value, {actionType, recordDate}) => {
            return (
                <React.Fragment>
                    {actionType === 6 && <Typography>到期時間：{parseDate(recordDate, 'YYYY/MM/DD')}</Typography>}
                    <Typography component="span">{value || ''}</Typography>
                </React.Fragment>);
        },
    },
    {
        cellKey: 'memo',
        headerLabel: '備註',
        align: 'left',
        formatCell: (value) => {
            return (<Typography component="span">{value}</Typography>);
        },
    },
];

const PointsRedeemRecordTable = React.forwardRef((props, ref) => {
    const { sourceData = {}, onPageChange } = props;
    const {deletePointRewardsRewardIDCacheApi} = usePointsRewardsApi();
    const scrollerArrowRef = useRef(null);
    const { rewardID, pageSize = 10, pageIndex = 1, totalCount = 0, totalPage, list = [] } = sourceData || {};
    
    const handlePageChange = (e, num) => {
        onPageChange && onPageChange(num);
    };
    
    const handleCacheClean = async () => {
        await deletePointRewardsRewardIDCacheApi(rewardID);
        setTimeout(() => {
            onPageChange && onPageChange(pageIndex);
        }, 100);
    };

    return (
        <Box className="mt-4">
            <Stack direction="column">
                <ListInfo
                    className={clsx('ml-auto', 'mb-2')}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    totalCount={totalCount}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                    onRefresh={handleCacheClean}
                />
                <Box className={'scroll-arrow-container'}>
                    <Tablenization
                        isPagination
                        cardProps={{ className: 'theme-gray' }}
                        page={pageIndex}
                        count={totalPage}
                        onChange={handlePageChange}
                        tableContainerRef={scrollerArrowRef}
                    >
                        <Tablenization.TableRowHeader headerRow={headerRow} />
                        <Tablenization.TableRowBody headerRow={headerRow} rowsData={list} />
                    </Tablenization>
                    <ScrollerArrowButton parentRef={scrollerArrowRef} />
                </Box>
            </Stack>
        </Box>
    );
});

export default PointsRedeemRecordTable;

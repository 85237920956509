import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Minus = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M16.25,8.125h-15A1.25,1.25,0,0,0,0,9.375v1.25a1.25,1.25,0,0,0,1.25,1.25h15a1.25,1.25,0,0,0,1.25-1.25V9.375A1.25,1.25,0,0,0,16.25,8.125Z"
                transform="translate(3 1.875)"
            ></path>
        </SvgIcon>
    );
});

export default Minus;

import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import { useSnackbar } from 'notistack';
import {
    GET_POINT_REWARD_AUDIT_OVERVIEW_OPTIONS_API,
    GET_POINT_REWARD_AUDIT_OVERVIEW_API,
    POST_POINT_REWARD_AUDIT_API,
    DELETE_POINTS_REWARD_AUDIT_OVERVIEW_CACHE_API,
    POST_POINTS_REWARD_AUDIT_SKIP_AUDIT_API,
} from '@Apis/apiPath';

// 點數審核
const usePointsRewardAudit = () => {
    const Axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();

    // 點數審核紀錄options
    const getPointRewardAuditOptionsApi = useCallback(
        async () => {
            const resp = await Axios.get(GET_POINT_REWARD_AUDIT_OVERVIEW_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 點數審核總覽
    const getPointRewardAuditOverviewApi = useCallback(
        async (params) => {
            const resp = await Axios.get(GET_POINT_REWARD_AUDIT_OVERVIEW_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 點數審核通過與否
    const postPointRewardAuditIsPassApi = useCallback(
        async (params, message) => {
            const resp = await Axios.post(POST_POINT_REWARD_AUDIT_API, params);
            resp && enqueueSnackbar(`${message} 成功`, { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 清除cache點數
    const postEditPointAuditSkipAuditApi = useCallback(
        async (params) => {
            const resp = await Axios.post(POST_POINTS_REWARD_AUDIT_SKIP_AUDIT_API, params);
            resp && enqueueSnackbar(`修改過審點數成功`, { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 清除cache點數
    const deletePointAuditCacheApi = useCallback(
        async () => {
            const resp = await Axios.delete(DELETE_POINTS_REWARD_AUDIT_OVERVIEW_CACHE_API);
            resp && enqueueSnackbar(`已刷新總覽清單`, { variant: 'success' });
            
        },
        // eslint-disable-next-line
        []
    );

    return {
        getPointRewardAuditOptionsApi,
        getPointRewardAuditOverviewApi,
        postPointRewardAuditIsPassApi,
        deletePointAuditCacheApi,
        postEditPointAuditSkipAuditApi,
    };
};

export default usePointsRewardAudit;

import React, { useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Slide, Grid } from '@mui/material';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { SelectField, MenuItem, TextField, DateField, Button } from '@Components/';
import { isEmpty, refIsRequiredError } from '@Util/utils';
import usePointsRedeemOrderApi from '@Apis/usePointsRedeemOrderApi';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PointsRedeemOrderExcelDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, options: optionsProps } = props;
    if (isEmpty(optionsProps) || !openProps) return null;
    const { postRedeemOrdersExportApi } = usePointsRedeemOrderApi();
    const { activityOption = [] } = optionsProps || {};
    const activityIdRef = useRef(null);
    const homeDeliveryPhoneNumbersRef = useRef(null);
    const arrivalDateRef = useRef(null);

    const getParams = () => ({
        activityId: activityIdRef.current.getResult(),
        homeDeliveryPhoneNumbers: homeDeliveryPhoneNumbersRef.current.getResult(),
        arrivalDate: arrivalDateRef.current.getResult(),
    });

    const handleExcelOnClick = () => {
        if (!refIsRequiredError(activityIdRef, homeDeliveryPhoneNumbersRef, arrivalDateRef)) {
            doDownloadExcel(getParams());
        }
    };

    const doDownloadExcel = async (params) => {
        await postRedeemOrdersExportApi(params);
    };

    return (
        <Dialog open={openProps} TransitionComponent={Transition} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>出貨明細下載</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={12} item>
                        <SelectField
                            key={activityOption}
                            ref={activityIdRef}
                            label="兌換時間"
                            defaultValue={activityOption[0]?.value ?? ''}
                            displayEmpty
                            fullWidth
                            required
                        >
                            <MenuItem value="" disabled>
                                <em>請選擇</em>
                            </MenuItem>
                            {activityOption.map(({ text, value, disabled }) => (
                                <MenuItem key={value} value={value} disabled={disabled}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            ref={homeDeliveryPhoneNumbersRef}
                            label="寄送的會員電話"
                            InputProps={{ placeholder: '複數電話號碼需以;隔開' }}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <DateField ref={arrivalDateRef} label="配送日期" fullWidth required />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleExcelOnClick}>
                    下載
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointsRedeemOrderExcelDialog;

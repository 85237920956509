import React, { useRef } from 'react';
import clsx from 'clsx';
import { Card, Box, Stack, Typography } from '@Components/';
import { ListInfo, ScrollerArrowButton, Tablenization } from '@Local/Components/';
import { parseDate } from '@Util/moment';

const headerRow = [
    {
        cellKey: 'creatorName',
        headerLabel: '建立者',
        align: 'center',
    },
    {
        cellKey: 'recordDate',
        headerLabel: '建立時間',
        align: 'center',
        formatCell: (value) => {
            return <React.Fragment>{parseDate(value)}</React.Fragment>;
        },
    },
    {
        cellKey: 'quantity',
        headerLabel: '數量',
        align: 'center',
        formatCell: (value) => {
            const isPositive = value > 0;
            return (
                <Box className={clsx(!(value === 0) && 'points-product-gift-record-quantity', isPositive ? 'blue' : 'red')}>{`${
                    isPositive ? '+' : ''
                }${value || '-'}`}</Box>
            );
        },
    },
    {
        cellKey: 'reason',
        headerLabel: '原因',
        align: 'center',
        width: 12,
        maxWidth: 12,
        formatCell: (value) => {
            return (
                <Typography variant="h6" component="span" className={'white-space-normal'}>
                    {value}
                </Typography>
            );
        },
    },
];

const PointsProductGiftRecordTable = React.forwardRef((props, ref) => {
    const { sourceData = {}, pageIndex = 1, pageSize = 1, totalCount = 1, onPageChange } = props;
    const scrollerArrowRef = useRef(null);

    const handlePageChange = (_e, num) => {
        onPageChange(num);
    };

    return (
        <Card className="mt-4">
            <Card.CardContent className="p-3">
                <Stack direction="column">
                    <ListInfo className={clsx('ml-auto', 'mb-2')} pageSize={pageSize} pageIndex={pageIndex} totalCount={totalCount} />
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization cardProps={{ className: 'theme-gray' }} tableContainerRef={scrollerArrowRef} cellWidth={12} isPagination isFixed>
                            <Tablenization.TableRowHeader headerRow={headerRow} />
                            <Tablenization.TableRowBody headerRow={headerRow} rowsData={sourceData} onChange={handlePageChange} />
                        </Tablenization>
                        <ScrollerArrowButton parentRef={scrollerArrowRef} />
                    </Box>
                </Stack>
            </Card.CardContent>
        </Card>
    );
});

export default PointsProductGiftRecordTable;

import React, { useRef } from 'react';
import clsx from 'clsx';
import { Box, Typography, Stack } from '@Components/';
import { ListInfo, ScrollerArrowButton, Tablenization } from '@Local/Components/';

const passTrialHeaderRow = [
    {
        cellKey: 'clubName',
        headerLabel: '店舖',
        align: 'center',
    },
    {
        cellKey: 'displayName',
        headerLabel: '會員',
        align: 'center',
        formatCell: (value, { cellPhone }) => {
            return (
                <React.Fragment>
                    <Typography>{value}</Typography>
                    <Typography variant="body2">{cellPhone}</Typography>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'accumulatedPoints',
        headerLabel: '點數',
        align: 'center',
    },
    {
        cellKey: 'accumulatedDesc',
        headerLabel: '累點說明',
        width: 40,
        maxWidth: 40,
    },
    {
        cellKey: 'memo',
        headerLabel: '備註',
        align: 'center',
        width: 40,
        maxWidth: 40,
    },
];

const PointsAuditCountsTable = (props) => {
    const { sourceData = [] } = props;
    const scrollerArrowRef = useRef(null);

    return (
        <Box className="mb-4">
            <Stack direction="column">
                <ListInfo className={clsx('ml-auto', 'mb-2')} pageSize={sourceData.length} pageIndex={1} totalCount={sourceData.length} />
                <Box className={'scroll-arrow-container'}>
                    <Tablenization cardProps={{ className: 'theme-gray' }} tableContainerRef={scrollerArrowRef} cellWidth={16} isPagination isFixed>
                        <Tablenization.TableRowHeader headerRow={passTrialHeaderRow} />
                        <Tablenization.TableRowBody headerRow={passTrialHeaderRow} rowsData={sourceData} />
                    </Tablenization>
                    <ScrollerArrowButton parentRef={scrollerArrowRef} />
                </Box>
            </Stack>
        </Box>
    );
};

export default PointsAuditCountsTable;

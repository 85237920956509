/* 開發用 */
export const DEVELOP_ICON_ROUTER = `/develop/icon/`;
export const DEVELOP_TEST_ROUTER = `/develop/test/`;

/*
    新增頁面 ADD
    /XXX/XXX/add
    編輯頁面 EDIT
    /XXX/XXX/edit/(:id)
    唯獨模式 READ ONLY
    /XXX/XXX/read-only/(:id)
*/

/* 登陸與ROOT */
export const ENTRANCE_ROOT_ROUTER = `site`;
export const LOGIN_ROOT_ROUTER = `/login/`;
export const HQ_ROOT_ROUTER = `/site`;

/* 點數管理 */
// 點數總覽
export const POINTS_OVERVIEW_ROUTER = `/site/points/overview/`;
// 贈品兌換總覽
export const POINTS_REDEEM_OVERVIEW_ROUTER = `/site/points-redeem/overview/`;
// 點數活動總覽
export const POINTS_ACTIVITY_OVERVIEW_ROUTER = `/site/points/activity/overview/`;
// 點數審核管理
export const POINTS_AUDIT_OVERVIEW_ROUTER = `/site/points/audit/overview/`;
export const POINTS_AUDIT_OVERVIEW_TYPE_ROUTER = `/site/points/audit/overview/:trailType(pass-trial|on-trial|non-trial)/`;
export const POINTS_AUDIT_OVERVIEW_PASS_TRIAL_ROUTER = `/site/points/audit/overview/pass-trial/`;
export const POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER = `/site/points/audit/overview/on-trial/`;
export const POINTS_AUDIT_OVERVIEW_NON_TRIAL_ROUTER = `/site/points/audit/overview/non-trial/`;
// 贈品總覽
export const POINTS_PRODUCT_GIFT_OVERVIEW_ROUTER = `/site/points/product-gift/overview/`;

// 文書管理
export const PROMOTION_PLAN_OVERVIEW_ROUTER = `/site/promotion-plan/overview/`;

// 循環訂單管理
export const CYCLE_PURCHASE_VERIFICATION_BILLING_OVERVIEW_ROUTER = '/site/cycle-purchase-verification-billing/overview/';
export const CYCLE_PURCHASE_BILLING_ROUTER = '/site/cycle-purchase-verification-billing/:maskID/';
export const CYCLE_PURCHASE_BILLING_ROUTER_URL = (maskID) => `/site/cycle-purchase-verification-billing/${maskID}/`;

// 店鋪管理
export const CLUB_MANAGEMENT_MEMBER_MEMBERSHIP_ROUTER = '/site/club-management/membership/';
export const CLUB_MANAGEMENT_MEMBERSHIP_ROUTER = '/site/club-management/membership/:membershipID/';
export const CLUB_MANAGEMENT_MEMBERSHIP_ROUTER_URL = (membershipID) => `/site/club-management/membership/${membershipID}/`;

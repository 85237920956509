import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import {
    GET_PROMOTION_PLAN_OPTIONS_API,
    GET_PROMOTION_PLAN_OVERVIEW_API,
    POST_PROMOTION_PLAN_API,
    PUT_PROMOTION_PLAN_API,
    DELETE_PROMOTION_PLAN_API,
    PUT_PROMOTION_PLAN_ENABLE_API,
} from '@Apis/apiPath';

const usePromotionPlanApi = () => {
    const Axios = useAxios();

    const getPromotionPlanOptionsApi = useCallback(
        async () => {
            const resp = await Axios.get(GET_PROMOTION_PLAN_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPromotionPlanOverviewApi = useCallback(
        async (params) => {
            const resp = await Axios.get(GET_PROMOTION_PLAN_OVERVIEW_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postPromotionPlanApi = useCallback(
        async (params) => {
            const resp = await Axios.post(POST_PROMOTION_PLAN_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const putPromotionPlanApi = useCallback(
        async (planID, params) => {
            const resp = await Axios.put(PUT_PROMOTION_PLAN_API(planID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const deletePromotionPlanApi = useCallback(
        async (planID) => {
            const resp = await Axios.delete(DELETE_PROMOTION_PLAN_API(planID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const putPromotionPlanEnableApi = useCallback(
        async (planID, params) => {
            const resp = await Axios.put(PUT_PROMOTION_PLAN_ENABLE_API(planID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getPromotionPlanOptionsApi,
        getPromotionPlanOverviewApi,
        postPromotionPlanApi,
        putPromotionPlanApi,
        deletePromotionPlanApi,
        putPromotionPlanEnableApi
    };
};

export default usePromotionPlanApi;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Clock = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M10,.313A9.688,9.688,0,1,0,19.688,10,9.686,9.686,0,0,0,10,.313Zm2.23,13.676-3.445-2.5a.472.472,0,0,1-.191-.379V4.531a.47.47,0,0,1,.469-.469h1.875a.47.47,0,0,1,.469.469V9.91l2.48,1.8a.468.468,0,0,1,.1.656l-1.1,1.516A.472.472,0,0,1,12.23,13.988Z"
                transform="translate(1.688 1.688)"
            />
        </SvgIcon>
    );
});

export default Clock;

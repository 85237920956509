import React, { useLayoutEffect } from 'react';
import clsx from 'clsx';
import ListContext from './ListContext';
import useForkRef from '@Util/hook/useForkRef';
import ButtonBase from '../button/ButtonBase';

const staticClass = 'list-item-button';

const ListItemButton = React.forwardRef((props, ref) => {
    const {
        className,
        alignItems = 'center',
        autoFocus = false,
        children,
        dense = false,
        disableGutters = false,
        divider = false,
        disabled = false,
        selected = false,
        ...other
    } = props;
    const listItemRef = React.useRef(null);
    const context = React.useContext(ListContext);
    let classNames = staticClass;

    const childContext = {
        dense: dense || context.dense || false,
        alignItems,
        disableGutters,
    };

    useLayoutEffect(() => {
        if (autoFocus) {
            if (listItemRef.current) {
                listItemRef.current.focus();
            } else if (process.env.NODE_ENV !== 'production') {
                console.error('MUI: Unable to set focus to a ListItemButton whose component has not been rendered.');
            }
        }
    }, [autoFocus]);

    const handleRef = useForkRef(listItemRef, ref);

    if (alignItems === 'flex-start') classNames = clsx(classNames, `${staticClass}-${alignItems}`);
    if (!disableGutters) classNames = clsx(classNames, `${staticClass}-gutters`);
    if (dense) classNames = clsx(classNames, `${staticClass}-dense`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (divider) classNames = clsx(classNames, `${staticClass}-divider`);
    if (selected) classNames = clsx(classNames, `${staticClass}-selected`);

    const defaultProperty = {
        className: clsx(classNames, className),
        component: `div`,
        disabled,
        ref: handleRef,
        ...other,
    };
    return (
        <ListContext.Provider value={childContext}>
            <ButtonBase {...defaultProperty}>{children}</ButtonBase>
        </ListContext.Provider>
    );
});

export default ListItemButton;

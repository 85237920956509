import React from 'react';
import { Box } from '@Components/';

const TestPage = () => {
    return (
        <Box className={'main-container-spacing'}>
            <h1>Test Page</h1>
        </Box>
    );
};

export default TestPage;

import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import img_cloud from '@Images/cloud.png';
import img_empty from '@Images/file-empty.png';
import img_nomatch from '@Images/file-nomatch.png';
import img_excel from '@Images/file-excel.png';
import { IconButton, FormHelperText, Typography, Box, Button } from '@Components/';
import { Close as CloseIcon } from '@SvgIcon/';
import { isEmpty, linkDownload } from '@Util/utils';

const staticClass = 'upload-box';

let imgMap = {
    xlsx: img_excel,
};

const ViewFileBox = (props) => {
    const { file: _file, link: _link } = props;
    const imgRef = useRef(null);
    let fileObject = {};
    if (_link) Object.assign(fileObject, convertUrlToObject(_link));
    else Object.assign(fileObject, _file);

    useEffect(
        () => {
            const extension = _file.extension;

            switch (extension) {
                case 'xlsx':
                    imgRef.current.src = imgMap.xlsx;
                    break;
                default:
                    imgRef.current.src = img_nomatch;
            }
        },
        // eslint-disable-next-line
        [_file],
    );

    return (
        <React.Fragment>
            <Box component="picture" className={`${staticClass}-file-wrap`}>
                <Box component="img" ref={imgRef} className={`${staticClass}-img`} alt={fileObject.name} />
                {fileObject && <Typography className={`${staticClass}-filename text-center`}>{`${fileObject.name}.${fileObject.extension}`}</Typography>}
            </Box>
        </React.Fragment>
    );
};

const ViewImageBox = (props) => {
    const { file: _file, link: _link } = props;
    const imgRef = useRef(null);
    let fileObject = {};
    if (_link) Object.assign(fileObject, convertUrlToObject(_link));
    else Object.assign(fileObject, _file);

    const createImageFromFile = (img, file) => {
        return new Promise((resolve, reject) => {
            if (/^https*/g.test(file)) {
                img.src = file;
            } else {
                img.src = URL.createObjectURL(file);
            }
            img.onload = () => {
                URL.revokeObjectURL(img.src);
                resolve(img);
            };
            img.onerror = () => reject('Failure to load image.');
        });
    };

    useEffect(
        () => {
            if (_file) {
                createImageFromFile(imgRef.current, _file.file);
            } else {
                imgRef.current.src = img_nomatch;
            }
        },
        // eslint-disable-next-line
        [_file],
    );

    return (
        <Box component="picture" className={`${staticClass}-image-wrap`}>
            <Box component="img" ref={imgRef} className={`${staticClass}-img`} alt={fileObject.name} />
        </Box>
    );
};

const NoFileBox = (props) => {
    const { isShow, readOnly, className } = props;
    return (
        <Box className={clsx({ hide: !isShow })}>
            {!readOnly ? (
                <React.Fragment>
                    <Box component="img" className={clsx('demo-img', `${staticClass}-img`)} src={img_cloud} alt={'上傳檔案'} />
                    <Typography className={'font-color-3 px-2'}>{'請將要上傳的檔案拖曳至此或點擊此區域'}</Typography>
                </React.Fragment>
            ) : (
                <Box component="picture" className={`${staticClass}-file-wrap`}>
                    <Box component="img" className={clsx(className, `${staticClass}-img`)} src={img_empty} alt="無檔案" />
                    <Typography className={'font-color-3 p-2'}>{'無上傳檔案'}</Typography>
                </Box>
            )}
        </Box>
    );
};

const convertUrlToObject = (_url) => {
    let source;
    if (_url) {
        const [extension, fileInfo] = _url.split('.').reverse();
        const [name] = fileInfo.split('/').reverse();
        source = {};
        source.extension = extension;
        source.name = name;
    }
    return source ? source : null;
};

const useUploadFileResult = (props) => {
    const { defaultValue, linkProps, fileExt, onFileChange, onClearFile, readOnly } = props;
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const [fileState, setFile] = useState(null);
    const [linkState, setLink] = useState(linkProps); // 下載檔案

    const fileRef = useRef(null);
    const divRef = useRef(null);
    const showFileImg = fileState || linkState;

    const handleClearFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFile(null);
        setLink(null);
        typeof onClearFile === 'function' && onClearFile();
    };

    const handleClick = () => {
        if (linkState) linkDownload(linkState);
        else if (readOnly) return;
        else fileRef.current && fileRef.current.click();
    };

    const handleFileChange = (_file) => {
        if (readOnly) return;
        if (!_file) {
            console.error('No File unpload');
            return;
        }
        const [fileExtension, fileName] = _file.name.split('.').reverse();
        if (fileExt && !fileExt.includes(fileExtension)) {
            _snackbar(`錯誤的格式，請使用以下格式：${fileExt.join('、')}`, {
                variant: 'error',
            });
        } else {
            setFile({
                extension: fileExtension,
                name: fileName,
                file: _file,
            });

            onFileChange && onFileChange(_file);
        }
    };

    const handleOnDragEnter = (e) => {
        divRef.current.classList.add(`${staticClass}-drop-enter`);
        e.preventDefault();
        e.stopPropagation();
        if (readOnly) return;
    };

    const handleDragleave = (e) => {
        divRef.current.classList.remove(`${staticClass}-drop-enter`);
        e.preventDefault();
        e.stopPropagation();
        if (readOnly) return;
    };

    const handleDragover = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (readOnly) return;
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (readOnly) return;
        const dt = e.dataTransfer;
        const files = dt.files;
        handleFileChange(files[0]);
        divRef.current.classList.remove(`${staticClass}-drop-enter`);
    };

    useEffect(
        () => {
            if (!isEmpty(defaultValue)) {
                setFile({
                    extension: null,
                    name: null,
                    file: defaultValue,
                });
            }
        },
        // eslint-disable-next-line
        [defaultValue],
    );

    return {
        fileState,
        linkState,
        fileRef,
        divRef,
        showFileImg,
        handleClearFile,
        handleClick,
        handleFileChange,
        handleOnDragEnter,
        handleDragleave,
        handleDragover,
        handleDrop,
    };
};

export const UploadFeedbackBox = (props) => {
    const { className, isError = false, feedbackData, onDownload, onConfirm } = props;
    const { totalAmount = 0, successAmount = 0, failureAmount = 0, failureDownloadPath: link } = feedbackData || {};

    return (
        <Box className={clsx('upload-feedback-box text-center', className)}>
            <Box className={`upload-feedback-box-wrap`}>
                <Box component={'img'} className={`upload-feedback-box-img`} src={img_cloud} alt="" />
                <Typography>總筆數： {totalAmount}</Typography>
                <Typography className={clsx('desc is-success')}>正確數： {successAmount}</Typography>
                <Typography className={clsx('desc mb-3 is-error')}>錯誤數： {failureAmount}</Typography>
            </Box>
            {isError ? (
                link && (
                    <React.Fragment>
                        <Typography className={'font-color-3'} variant={'body2'}>
                            下載並檢視錯誤欄位，修正後請重新上傳
                        </Typography>
                        <Button variant={'contained'} href={link} onClick={onDownload} download>
                            下載檔案
                        </Button>
                    </React.Fragment>
                )
            ) : (
                <Button variant={'contained'} onClick={onConfirm}>
                    完成
                </Button>
            )}
        </Box>
    );
};

export const UploadImageBox = React.forwardRef((props, ref) => {
    const {
        className,
        accept = '*/*',
        fileExtension: fileExt,
        isError: propsError = false,
        helperText: helperTextProps = '此選項為必填，不得為空值',
        required = false,
        readOnly = false,
        heightAuto = false,
        note,
    } = props;
    const {
        fileState,
        linkState,
        fileRef,
        divRef,
        showFileImg,
        handleClearFile,
        handleClick,
        handleFileChange: handleFileChangeFn,
        handleOnDragEnter,
        handleDragleave,
        handleDragover,
        handleDrop,
    } = useUploadFileResult(props);
    const [isError, setError] = useState(propsError);

    const handleFileChange = (_file) => {
        handleFileChangeFn(_file);
        setError(false);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => fileState,
            isError: () => {
                let isError = required;
                if (fileState) isError = false;
                setError(isError);
                return isError;
            },
        }),
        // eslint-disable-next-line
        [fileState],
    );

    return (
        <Box className={clsx(staticClass, className, { 'pseudo-img': heightAuto && !showFileImg })} ref={divRef}>
            <Box
                component="input"
                className="file-input"
                type="file"
                ref={fileRef}
                onChange={(e) => handleFileChange(e.target.files[0])}
                accept={accept}
                key={uuid()}
            />
            <Box
                className={clsx(heightAuto && !showFileImg && `${staticClass}-wrap`)}
                onClick={handleClick}
                onDragEnter={handleOnDragEnter}
                onDragLeave={handleDragleave}
                onDragOver={handleDragover}
                onDrop={handleDrop}
            >
                {showFileImg && !readOnly && (
                    <IconButton color="error" className={`${staticClass}-close-icon`} onClick={handleClearFile}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                )}
                <Box className={clsx(heightAuto && !showFileImg && `${staticClass}-content`, 'text-center')} onDragEnter={handleOnDragEnter}>
                    <NoFileBox isShow={!showFileImg} readOnly={readOnly} />
                    {showFileImg ? <ViewImageBox file={fileState} link={linkState} /> : ''}
                    {!fileState && fileExt && <Box className={`${staticClass}-desc`}>支援檔案格式：{fileExt.join('、')}</Box>}
                    {!fileState && note && <Box className={`${staticClass}-desc`}>{note}</Box>}
                </Box>
            </Box>
            {isError && <FormHelperText isError={isError}>{helperTextProps}</FormHelperText>}
        </Box>
    );
});

export const UploadFileBox = React.forwardRef((props, ref) => {
    const {
        className,
        accept = '*/*',
        fileExtension: fileExt,
        isError: propsError = false,
        helperText: helperTextProps = '此選項為必填，不得為空值',
        required = false,
        readOnly = false,
        note,
    } = props;

    const {
        fileState,
        linkState,
        fileRef,
        divRef,
        showFileImg,
        handleClearFile,
        handleClick,
        handleFileChange: handleFileChangeFn,
        handleOnDragEnter,
        handleDragleave,
        handleDragover,
        handleDrop,
    } = useUploadFileResult(props);
    const [isError, setError] = useState(propsError);

    const handleFileChange = (_file) => {
        handleFileChangeFn(_file);
        setError(false);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => fileState,
            isError: () => {
                let isError = required;
                if (fileState) isError = false;
                setError(isError);
                return isError;
            },
        }),
        // eslint-disable-next-line
        [fileState],
    );

    return (
        <Box className={clsx(staticClass, className, 'pseudo-img')} ref={divRef}>
            <Box
                component="input"
                className="file-input"
                type="file"
                ref={fileRef}
                onChange={(e) => handleFileChange(e.target.files[0])}
                accept={accept}
                key={uuid()}
            />
            <Box
                className={`${staticClass}-wrap`}
                onClick={handleClick}
                onDragEnter={handleOnDragEnter}
                onDragLeave={handleDragleave}
                onDragOver={handleDragover}
                onDrop={handleDrop}
            >
                {showFileImg && !readOnly && (
                    <IconButton color="error" className={`${staticClass}-close-icon`} onClick={handleClearFile}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                )}
                <Box className={clsx(`${staticClass}-content`, 'text-center')} onDragEnter={handleOnDragEnter}>
                    <NoFileBox isShow={!showFileImg} readOnly={readOnly} />
                    {showFileImg ? <ViewFileBox file={fileState} link={linkState} /> : ''}
                    {fileExt && <Box className={`${staticClass}-desc`}>支援檔案格式：{fileExt.join('、')}</Box>}
                    {note && <Box className={`${staticClass}-desc`}>{note}</Box>}
                </Box>
            </Box>
            {isError && <FormHelperText isError={isError}>{helperTextProps}</FormHelperText>}
        </Box>
    );
});

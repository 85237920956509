import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import {
    GET_POINTS_REDEEM_ORDERS_OVERVIEW_OPTIONS_API,
    GET_POINTS_REDEEM_ORDERS_OVERVIEW_API,
    GET_POINTS_REDEEM_ORDERS_REDEEMITEMID_RECORDS_API,
    DELETE_POINTS_REDEEM_ORDERS_OVERVIEW_CACHE_API,
    POST_POINTS_REDEEM_ORDERS_EXPORT_API,
} from '@Apis/apiPath';

const usePointsRedeemOrderApi = () => {
    const Axios = useAxios();

    const getPointsRewardOrderOverviewOptionsApi = useCallback(
        async () => {
            const resp = await Axios.get(GET_POINTS_REDEEM_ORDERS_OVERVIEW_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardOrderOverviewApi = useCallback(
        async (params) => {
            const resp = await Axios.get(GET_POINTS_REDEEM_ORDERS_OVERVIEW_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getPointsRewardOrderRecordsApi = useCallback(
        async (params, redeemItemId) => {
            const resp = await Axios.get(GET_POINTS_REDEEM_ORDERS_REDEEMITEMID_RECORDS_API(redeemItemId), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postRedeemOrdersExportApi = useCallback(
        async (params) => {
            await Axios.post(POST_POINTS_REDEEM_ORDERS_EXPORT_API, params, {
                headerOption: { responseType: 'blob' },
            });
        },
        // eslint-disable-next-line
        []
    );

    const deletePointsRewardOrderCleanCacheApi = useCallback(
        async (params) => {
            await Axios.delete(DELETE_POINTS_REDEEM_ORDERS_OVERVIEW_CACHE_API, params);
        },
        // eslint-disable-next-line
        []
    );

    return {
        getPointsRewardOrderOverviewOptionsApi,
        getPointsRewardOrderOverviewApi,
        getPointsRewardOrderRecordsApi,
        postRedeemOrdersExportApi,
        deletePointsRewardOrderCleanCacheApi,
    };
};

export default usePointsRedeemOrderApi;

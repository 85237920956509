import { POINTS_AUDIT_OVERVIEW_PASS_TRIAL_ROUTER, POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER, POINTS_AUDIT_OVERVIEW_NON_TRIAL_ROUTER } from '@Router/routerPath';

export const initBookmark = () => ({
    pageIndex: 1,
    pageSize: 50,
});

export const auditMap = {
    1: POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER,
    2: POINTS_AUDIT_OVERVIEW_PASS_TRIAL_ROUTER,
    3: POINTS_AUDIT_OVERVIEW_NON_TRIAL_ROUTER,
};

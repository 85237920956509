import React, { useRef } from 'react';
import clsx from 'clsx';
import { Card, Box, Stack, Typography, Switch, Button } from '@Components/';
import { ListInfo, ScrollerArrowButton, Tablenization } from '@Local/Components/';
import { QuestionCircle as QuestionCircleIcon } from '@SvgIcon/';
import { usePointsContext } from '@Local/Points/Components/PointsContext';
import usePointsRedeemItemApi from '@Apis/usePointsRedeemItemApi';
import { PointsProductGiftTableMoreLabel } from '@Local/Points/ProductGift/';

const headerRow = [
    {
        cellKey: 'imageSrc',
        headerLabel: '贈品',
        align: 'left',
        isFixed: true,
        isFixedLeft: true,
        formatCell: (value, rowData, { onClick }) => {
            return (
                <Stack alignItems="center" spacing={1}>
                    <Box className="product-gift-img-label">
                        <img src={value} alt={rowData.itemName} />
                    </Box>
                    <Typography variant="h6" className="font-weight-bold">
                        {rowData.itemName}
                    </Typography>
                    <QuestionCircleIcon
                        className="cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onClick(rowData);
                        }}
                    />
                </Stack>
            );
        },
    },
    {
        cellKey: 'points',
        headerLabel: '兌換點數',
        align: 'center',
        width: 8,
        formatCell: (value) => {
            return <Typography variant="h5" component="span">{`${value}點`}</Typography>;
        },
    },
    {
        cellKey: 'originalPrice',
        headerLabel: '成本價',
        align: 'center',
        width: 8,
        formatCell: (value) => {
            return (
                <Typography component="span">
                    {value}
                </Typography>
            );
        },
    },
    {
        cellKey: 'costPrice',
        headerLabel: '市價',
        align: 'center',
        width: 8,
        formatCell: (value) => {
            return (
                <Typography component="span">
                    {value}
                </Typography>
            );
        },
    },
    {
        cellKey: 'currentStock',
        headerLabel: '庫存量',
        align: 'center',
        width: 8,
        formatCell: (value) => {
            return (
                <Typography variant="h5" component="span">
                    {value}
                </Typography>
            );
        },
    },
    {
        cellKey: 'isEnabled',
        headerLabel: '上架',
        align: 'center',
        width: 3,
        formatCell: (value, rowData, { onSwitchChange }) => {
            return (
                <Switch
                    onChange={(_e, checked) => {
                        onSwitchChange(rowData, { isEnabled: checked });
                    }}
                    defaultChecked={value}
                />
            );
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        align: 'center',
        width: 3,
        formatCell: (_value, rowData, { onMoreLabelClick }) => {
            return <PointsProductGiftTableMoreLabel rowData={rowData} onClick={onMoreLabelClick} />;
        },
    },
];

const PointsProductGiftTable = (props) => {
    const { dialogRef, sourceData, pageModel, setNextPage, refresh } = usePointsContext();
    const { putPointsProductGiftItemDataApi } = usePointsRedeemItemApi();
    const scrollerArrowRef = useRef(null);
    
    const handleAddProductGiftOnClick = () => {
        dialogRef.current.openProductGiftItemDialog({}, refresh);
    };

    const handleRowOnClick = (rowData) => {
        dialogRef.current.openProductGiftRecordDialog(rowData, refresh);
    };

    const handleProdcutsInfoOnClick = (rowData) => {
        dialogRef.current.openPointsProductGuideDialog(rowData);
    };

    const handleMoreLabelOnClick = (type, rowData) => {
        if (type === 'editProductGift') {
            dialogRef.current.openProductGiftItemDialog(rowData, refresh);
        } else if (type === 'editProductGiftStock') {
            dialogRef.current.openProductGiftItemInventory(rowData, refresh);
        }
    };

    const handleSwitchChange = (sourceData, changeData) => {
        const { itemID } = sourceData;
        let params = JSON.parse(JSON.stringify(sourceData));
        Object.assign(params, { ...changeData });
        doPutSwitchChangeApi(params, itemID);
    };

    const handlePageOnChange = (_e, num) => {
        setNextPage(num);
    };

    const doPutSwitchChangeApi = async (params, itemID) => {
        const resp = await putPointsProductGiftItemDataApi(params, itemID);
        resp && refresh();
    };

    return (
        <Card className="mt-4">
            <Card.CardContent className="p-3">
                <Stack spacing={2}>
                    <Button variant="contained" color="info" onClick={handleAddProductGiftOnClick}>
                        新增贈品
                    </Button>
                </Stack>
                <Stack direction="column">
                    <ListInfo
                        className={clsx('ml-auto', 'mb-2')}
                        pageSize={pageModel.pageSize}
                        pageIndex={pageModel.pageIndex}
                        totalCount={pageModel.totalCount}
                        isCurrentPageFirstIndex
                        isCurrentPageLastIndex
                    />
                    <Box className={'scroll-arrow-container'}>
                        <Tablenization
                            cardProps={{ className: 'theme-gray' }}
                            tableContainerRef={scrollerArrowRef}
                            page={pageModel.pageIndex}
                            count={pageModel.totalPage}
                            onChange={handlePageOnChange}
                            cellWidth={12}
                            isPagination
                            isFixed
                        >
                            <Tablenization.TableRowHeader headerRow={headerRow} />
                            <Tablenization.TableRowBody
                                headerRow={headerRow}
                                rowsData={sourceData}
                                onClick={handleProdcutsInfoOnClick}
                                onMoreLabelClick={handleMoreLabelOnClick}
                                rowOnClick={handleRowOnClick}
                                onSwitchChange={handleSwitchChange}
                            />
                        </Tablenization>
                        <ScrollerArrowButton parentRef={scrollerArrowRef} />
                    </Box>
                </Stack>
            </Card.CardContent>
        </Card>
    );
};

export default PointsProductGiftTable;

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import clsx from 'clsx';
import { Button, DateField, Form, TextField, CheckboxField, AutocompleteField } from '@Components/';
import { refIsRequiredError } from '@Util/utils';
import { GradientColorHeader } from '@Local/Components/Dialog';
import { DurationField, ReadTextField } from '@Local/Components';
import { displayDateText, initialDate } from '@Util/moment';

// 創建類：新入會、續約、承接、移入新約、移入續約、復會、轉新約。
const MembershipItemByCreate = forwardRef((props, ref) => {
    const { data } = props;
    const { applyDate, startDate, memo } = data || {};
    const applyDateRef = useRef(null);
    const startDateRef = useRef(null);
    const effectiveDateRef = useRef(null);
    const memoRef = useRef(null);
    const confirmRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (applyDateRef.current && applyDateRef.current.getResult) {
                    Object.assign(result, { applyDate: applyDateRef.current.getResult() });
                }
                if (startDateRef.current && startDateRef.current.getResult) {
                    Object.assign(result, { startDate: startDateRef.current.getResult() });
                }
                if (effectiveDateRef.current && effectiveDateRef.current.getResult) {
                    Object.assign(result, { effectiveDate: effectiveDateRef.current.getResult() });
                }
                if (memoRef.current && memoRef.current.getResult) {
                    Object.assign(result, { memo: memoRef.current.getResult() });
                }
                return result;
            },
            isErrors: () => refIsRequiredError([]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <DateField key={initialDate(applyDate)} ref={applyDateRef} label={'申請日期'} defaultValue={initialDate(applyDate)} fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <DateField key={initialDate(startDate)} ref={startDateRef} label={'會籍起始日'} defaultValue={initialDate(startDate)} fullWidth required />
            </Grid>
            <Grid item xs={12}>
                <TextField key={memo} ref={memoRef} label={'備註(教練看的到)'} defaultValue={memo} fullWidth multiline rows={4} />
            </Grid>
            <Grid item xs={12}>
                <CheckboxField ref={confirmRef} required>
                    <CheckboxField.Checkbox value={true} label={'只會更新當前會籍，如有後續會籍請確認並更新。'} required />
                </CheckboxField>
            </Grid>
        </React.Fragment>
    );
});

// 終止類：退會、移出、轉讓、轉約。
const MembershipItemByQuit = forwardRef((props, ref) => {
    const { data } = props;
    const { applyDate, startDate, memo, effectiveDate } = data || {};
    const applyDateRef = useRef(null);
    const startDateRef = useRef(null);
    const effectiveDateRef = useRef(null);
    const memoRef = useRef(null);
    const confirmRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (applyDateRef.current && applyDateRef.current.getResult) {
                    Object.assign(result, { applyDate: applyDateRef.current.getResult() });
                }
                if (startDateRef.current && startDateRef.current.getResult) {
                    Object.assign(result, { startDate: startDateRef.current.getResult() });
                }
                if (effectiveDateRef.current && effectiveDateRef.current.getResult) {
                    Object.assign(result, { effectiveDate: effectiveDateRef.current.getResult() });
                }
                if (memoRef.current && memoRef.current.getResult) {
                    Object.assign(result, { memo: memoRef.current.getResult() });
                }
                return result;
            },
            isErrors: () => refIsRequiredError([]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <DateField key={initialDate(applyDate)} ref={applyDateRef} label={'申請日期'} defaultValue={initialDate(applyDate)} fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <DateField key={initialDate(startDate)} ref={startDateRef} label={'項目申請日'} defaultValue={initialDate(startDate)} fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <DateField
                    key={initialDate(effectiveDate)}
                    ref={effectiveDateRef}
                    label={'生效日期'}
                    defaultValue={initialDate(effectiveDate)}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField key={memo} ref={memoRef} label={'備註(教練看的到)'} defaultValue={memo} fullWidth multiline rows={4} />
            </Grid>
            <Grid item xs={12}>
                <CheckboxField ref={confirmRef} required>
                    <CheckboxField.Checkbox value={true} label={'只會更新當前會籍，如有後續會籍請確認並更新。'} required />
                </CheckboxField>
            </Grid>
        </React.Fragment>
    );
});

// 展延類：手動展延
const MembershipItemByExtended = forwardRef((props, ref) => {
    const { data } = props;
    const { applyDate, memo } = data || {};
    const applyDateRef = useRef(null);
    const durationRef = useRef(null);
    const memoRef = useRef(null);
    const confirmRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (applyDateRef.current && applyDateRef.current.getResult) {
                    Object.assign(result, { applyDate: applyDateRef.current.getResult() });
                }
                if (durationRef.current && durationRef.current.getResult) {
                    Object.assign(result, { duration: durationRef.current.getResult() });
                }
                if (memoRef.current && memoRef.current.getResult) {
                    Object.assign(result, { memo: memoRef.current.getResult() });
                }
                return result;
            },
            isErrors: () => refIsRequiredError([]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <DateField key={initialDate(applyDate)} ref={applyDateRef} label={'申請日期'} defaultValue={initialDate(applyDate)} fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <DurationField
                    ref={durationRef}
                    label={'時間'}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                    }}
                    isShowDay
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField key={memo} ref={memoRef} label={'備註(教練看的到)'} defaultValue={memo} fullWidth multiline rows={4} />
            </Grid>
            <Grid item xs={12}>
                <CheckboxField ref={confirmRef} required>
                    <CheckboxField.Checkbox value={true} label={'只會更新當前會籍，如有後續會籍請確認並更新。'} required />
                </CheckboxField>
            </Grid>
        </React.Fragment>
    );
});

// 贈送類：贈送會籍前、贈送會籍後、手動贈送
const MembershipItemByGift = forwardRef((props, ref) => {
    const { data } = props;
    const { applyDate, memo } = data || {};
    const applyDateRef = useRef(null);
    const durationRef = useRef(null);
    const memoRef = useRef(null);
    const confirmRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (applyDateRef.current && applyDateRef.current.getResult) {
                    Object.assign(result, { applyDate: applyDateRef.current.getResult() });
                }
                if (durationRef.current && durationRef.current.getResult) {
                    Object.assign(result, { duration: durationRef.current.getResult() });
                }
                if (memoRef.current && memoRef.current.getResult) {
                    Object.assign(result, { memo: memoRef.current.getResult() });
                }
                return result;
            },
            isErrors: () => refIsRequiredError([]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <DateField key={initialDate(applyDate)} ref={applyDateRef} label={'申請日期'} defaultValue={initialDate(applyDate)} fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <DurationField
                    ref={durationRef}
                    label={'贈送時間'}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                    }}
                    isShowDay
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField key={memo} ref={memoRef} label={'備註(教練看的到)'} defaultValue={memo} fullWidth multiline rows={4} />
            </Grid>
            <Grid item xs={12}>
                <CheckboxField ref={confirmRef} required>
                    <CheckboxField.Checkbox value={true} label={'只會更新當前會籍，如有後續會籍請確認並更新。'} required />
                </CheckboxField>
            </Grid>
        </React.Fragment>
    );
});

// 暫停類：暫停、店休、防疫假、持續扣款
const MembershipItemBySuspend = forwardRef((props, ref) => {
    const { data } = props;
    const { applyDate, startDate, suspendStartDate, suspendEndDate, memo, membershipStartDate, membershipEndDate } = data || {};
    const applyDateRef = useRef(null);
    const startDateRef = useRef(null);
    const durationRef = useRef(null);
    const suspensionMonthRef = useRef(null);
    const recoveryMonthRef = useRef(null);
    const useDateRef = useRef(null);
    const memoRef = useRef(null);
    const confirmRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (applyDateRef.current && applyDateRef.current.getResult) {
                    Object.assign(result, { applyDate: applyDateRef.current.getResult() });
                }
                if (startDateRef.current && startDateRef.current.getResult) {
                    Object.assign(result, { startDate: startDateRef.current.getResult() });
                }
                if (durationRef.current && durationRef.current.getResult) {
                    Object.assign(result, { duration: durationRef.current.getResult() });
                }
                if (suspensionMonthRef.current && suspensionMonthRef.current.getResult) {
                    Object.assign(result, { suspensionMonth: suspensionMonthRef.current.getResult() });
                }
                if (recoveryMonthRef.current && recoveryMonthRef.current.getResult) {
                    Object.assign(result, { recoveryMonth: recoveryMonthRef.current.getResult() });
                }
                if (useDateRef.current && useDateRef.current.getResult) {
                    Object.assign(result, { useDate: useDateRef.current.getResult() });
                }
                if (memoRef.current && memoRef.current.getResult) {
                    Object.assign(result, { memo: memoRef.current.getResult() });
                }
                return result;
            },
            isErrors: () => refIsRequiredError([]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <DateField key={initialDate(applyDate)} ref={applyDateRef} label={'申請日期'} defaultValue={initialDate(applyDate)} fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <DateField key={initialDate(startDate)} ref={startDateRef} label={'開始時間'} defaultValue={initialDate(startDate)} fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <DurationField
                    ref={durationRef}
                    label={'持續時間'}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                    }}
                    isShowDay
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <ReadTextField label={'預計暫停期間'} value={displayDateText(suspendStartDate, suspendEndDate)} fullWidth />
            </Grid>
            <Grid item xs={6}>
                <DateField ref={suspensionMonthRef} label={'暫停扣款月份'} fullWidth required />
            </Grid>
            <Grid item xs={6}>
                <DateField ref={recoveryMonthRef} label={'恢復扣款月份'} fullWidth required />
            </Grid>
            <Grid item xs={12}>
                <ReadTextField label={'更新後會籍期間'} value={displayDateText(membershipStartDate, membershipEndDate)} fullWidth />
            </Grid>
            <Grid item xs={6}>
                <DurationField
                    ref={useDateRef}
                    label={'已使用時間'}
                    dayProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                    }}
                    monthProps={{
                        type: 'number',
                        inputProps: {
                            min: 0,
                        },
                    }}
                    isShowDay
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField key={memo} ref={memoRef} label={'備註(教練看的到)'} defaultValue={memo} fullWidth multiline rows={4} />
            </Grid>
            <Grid item xs={12}>
                <CheckboxField ref={confirmRef} required>
                    <CheckboxField.Checkbox value={true} label={'只會更新當前會籍，如有後續會籍請確認並更新。'} required />
                </CheckboxField>
            </Grid>
        </React.Fragment>
    );
});

const fakeMembershipTypeOptions = [
    {
        value: 1,
        text: '新入會',
    },
    {
        value: 3,
        text: '移入',
    },
    {
        value: 4,
        text: '移出',
    },
    {
        value: 5,
        text: '續約',
    },
    {
        value: 6,
        text: '轉約(舊約)',
    },
    {
        value: 7,
        text: '復會',
    },
    {
        value: 8,
        text: '轉讓',
    },
    {
        value: 9,
        text: '承接',
    },
    {
        value: 10,
        text: '轉新約',
    },
    {
        value: 11,
        text: '暫停',
    },
    {
        value: 12,
        text: '防疫假',
    },
    {
        value: 13,
        text: '持續扣款',
    },
    {
        value: 14,
        text: '店休',
    },
    {
        value: 15,
        text: '暫停(免扣款暫停)',
    },
    {
        value: 21,
        text: '贈送(會籍後)',
    },
    {
        value: 22,
        text: '贈送(會籍前)',
    },
    {
        value: 23,
        text: '手動贈送',
    },
    {
        value: 24,
        text: '手動展延',
    },
    {
        value: 31,
        text: '退會',
    },
    {
        value: 32,
        text: '國外移籍',
    },
    {
        value: 41,
        text: '延後會籍',
    },
    {
        value: 51,
        text: '新入會(移入)',
    },
    {
        value: 52,
        text: '續約(移入)',
    },
];

const MembershipItemDialogContent = forwardRef((props, ref) => {
    const { sourceData, options } = props;
    const { membershipItemType: membershipItemTypeProps } = sourceData || {};
    const { membershipTypeOptions = fakeMembershipTypeOptions } = options || [];
    const [membershipItemType, setMembershipItemType] = useState(membershipItemTypeProps);
    const membershipTypeRef = useRef(null);
    const contentRef = useRef(null);

    const handleChangeMembershipType = (data) => {
        const { value = 0 } = data || {};
        setMembershipItemType(value);
    };

    const getMembershipItemComponent = (membershipItemType) => {
        switch (membershipItemType) {
            case 1:
            case 3:
            case 5:
            case 6:
            case 7:
            case 9:
            case 10:
            case 51:
            case 52:
                return <MembershipItemByCreate ref={contentRef} data={sourceData} />;
            case 21:
            case 22:
            case 23:
                return <MembershipItemByGift ref={contentRef} data={sourceData} />;
            case 24:
            case 41:
                return <MembershipItemByExtended ref={contentRef} data={sourceData} />;
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
                return <MembershipItemBySuspend ref={contentRef} data={sourceData} />;
            case 4:
            case 8:
            case 31:
            case 32:
                return <MembershipItemByQuit ref={contentRef} data={sourceData} />;
            default:
                return null;
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};

                if (membershipItemType) {
                    Object.assign(result, { membershipItemType });
                }

                if (contentRef.current && contentRef.current.getResult) {
                    Object.assign(result, contentRef.current.getResult());
                }

                return result;
            },
            isErrors: () => refIsRequiredError([membershipTypeRef, contentRef]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <AutocompleteField
                        key={membershipItemType}
                        ref={membershipTypeRef}
                        defaultValue={membershipItemType}
                        label={'選擇會籍項目'}
                        inputProps={{ placeholder: '請輸入會籍項目' }}
                        options={membershipTypeOptions}
                        optionKeys={['text']}
                        onChange={handleChangeMembershipType}
                        primaryKey={'value'}
                        required
                        fullWidth
                    />
                </Grid>
                {getMembershipItemComponent(membershipItemType)}
            </Grid>
        </DialogContent>
    );
});

const MembershipItemDialog = (props) => {
    const {
        open = false,
        sourceData,
        // refresh,
        onClose,
        className,
    } = props;
    // const { membershipItemType } = sourceData || {};
    const contentRef = useRef(null);

    const handleSubmit = (e) => {
        const isError = contentRef.current.isErrors();
        if (!isError) {
            const result = contentRef.current.getResult();
            console.log(result, 'result');
        }
    };

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            open={open}
            onSubmit={handleSubmit}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>修改會籍合約</GradientColorHeader>
            <MembershipItemDialogContent ref={contentRef} sourceData={sourceData} />
            <DialogActions>
                <Button variant="contained" type="submit">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MembershipItemDialog;

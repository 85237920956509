import Axios from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import CodePack, { ExceptionsPack } from './statusCode';
import _ from 'lodash';
import { serialize } from '@Util/utils';
import { setLoadingData } from '@Redux/loading/action';

const httpMethodtemplet =
    (methodType) =>
    (action = {}) =>
    async (url, parameters, { headerOption = {}, ...other } = {}) => {
        if (methodType === 'delete') parameters = Object.assign({}, { data: parameters });
        if (methodType === 'get' && parameters) {
            const queryString = serialize(parameters);
            if (queryString) url += `?${queryString}`;
            parameters = {};
        }
        if (methodType === 'pdf') {
            methodType = 'get';
            parameters = {};
            headerOption['responseType'] = `arraybuffer`;
            other.fileName = other.fileName || `未設定`;
        }
        const requestInfo = {
            method: methodType,
            url,
            parameters,
            requestHeaderOption: headerOption,
        };

        return await axiosApi(requestInfo, { ...other, ...action });
    };

const axiosGet = httpMethodtemplet('get');

const axiosPost = httpMethodtemplet('post');

const axiosPut = httpMethodtemplet('put');

const axiosDelete = httpMethodtemplet('delete');

const axiosPDFDownload = httpMethodtemplet('pdf');

const axiosApi = async (
    { method, url, parameters, requestHeaderOption },
    { callbackfn = null, isLoadingVisible = true, _showLoading, _snackbar, _history, fileName: _fileName }
) => {
    let sourceData;
    let respondBody;
    let isError = false;
    isLoadingVisible && _showLoading(true);
    try {
        if (method === 'get') {
            respondBody = await Axios[method](url, requestHeaderOption);
        } else {
            respondBody = await Axios[method](url, parameters, requestHeaderOption);
        }
        const { data: resource } = respondBody;
        if (resource.success) {
            if (resource.statusCode === CodePack.STATUS_SUCCESS_CODE) {
                sourceData = resource.result;
            }
        } else if (`application/vnd.ms-excel` === respondBody['headers']['content-type']) {
            // excel下載
            let excelFile;
            let filename;
            const filenameRegex = /filename\*[^;=\n]*=.*''((['"]).*?|[^;\n]*)/;
            const matches = filenameRegex.exec(respondBody['headers']['content-disposition']);
            if (matches != null && matches[1]) {
                filename = decodeURI(matches[1].replace(/['"]/g, ''));
            }
            if (method === 'post') {
                excelFile = new Blob([resource], { type: 'application/vnd.ms-excel;charset=utf-8' });
            } else {
                excelFile = resource;
            }
            const fileURL = URL.createObjectURL(excelFile);
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = fileURL;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            await link.click();
            document.body.removeChild(link);
            _snackbar('下載excel成功', { variant: 'info' });
            sourceData = true;
        } else if (`application/pdf` === respondBody['headers']['content-type']) {
            // pdf下載
            const blobPack = new Blob([resource], {
                type: 'application/octet-stream',
                'Content-Disposition': 'attachment',
            });
            const fileURL = URL.createObjectURL(blobPack);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `${_fileName}.pdf`;
            await link.click();
            _snackbar('下載PDF成功', { variant: 'info' });
            sourceData = true;
        } else {
            let { statusCode, message } = resource;
            statusCode = String(statusCode);
            if (statusCode === CodePack.STATUS_LOGOUT_CODE && _history.location.pathname.split('/')[1] !== 'login') {
                _snackbar('已登出，請重新登入', { variant: 'info' });
                localStorage.clear();
                _history.go('/login');
            } else if (statusCode === CodePack.STATUS_PERMISSION_DENIED_CODE) {
                sourceData = null;
                _snackbar(message, { variant: 'error' });
                _history.push('/club/404');
            } else if (ExceptionsPack.indexOf(statusCode) !== -1) {
                _snackbar(message, { variant: 'error' });
                sourceData = {};
            } else {
                _snackbar(message, { variant: 'error' });
            }
        }
    } catch (error) {
        const { response } = error;
        respondBody = error;
        isError = true;
        if (response) {
            // 此段為測試，Allen todo
            _snackbar(`系統回應發生錯誤(${response.status})`, {
                variant: 'error',
            });
        } else {
            console.error(error);
        }
    }
    isLoadingVisible && _showLoading(false);
    return isError ? sourceData : _.isFunction(callbackfn) ? callbackfn(sourceData, respondBody) : sourceData;
};

const useAxios = () => {
    const _history = useHistory();
    const { enqueueSnackbar: _snackbar } = useSnackbar();
    const _dispatch = useDispatch();
    const _showLoading = useCallback(
        (isShow) => {
            _dispatch(setLoadingData(isShow));
        },
        [_dispatch]
    );

    const action = { _showLoading, _snackbar, _history };
    const getAction = useCallback(axiosGet(action), []);
    const postAction = useCallback(axiosPost(action), []);
    const putAction = useCallback(axiosPut(action), []);
    const deleteAction = useCallback(axiosDelete(action), []);
    const PDFAction = useCallback(axiosPDFDownload(action), []);
    return {
        get: getAction,
        post: postAction,
        put: putAction,
        delete: deleteAction,
        getPDF: PDFAction,
    };
};

export default useAxios;

import React from 'react';
import clsx from 'clsx';
import { FirstPage as FirstPageIcon, LastPage as LastPageIcon, NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@SvgIcon/';
import ButtonBase from '../button/ButtonBase';

const sizeArray = ['small', 'large'];
const colorArray = ['primary', 'secondary'];
const variantArray = ['text', 'outlined'];

const PaginationItemEllipsis = React.forwardRef((props, ref) => {
    const { size, disabled, children } = props;
    const staticClass = 'pagination-item-ellipsis';
    let classNames = `${staticClass}-root`;
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (sizeArray.indexOf(size) !== -1) classNames = clsx(classNames, `${staticClass}-disabled`);
    classNames = clsx(classNames, 'ban-select');
    const defaultProperty = {
        ref,
        className: classNames,
    };
    return <div {...defaultProperty}>{children}</div>;
});

const PaginationItemPage = React.forwardRef((props, ref) => {
    const { size, disabled, color, shape, variant, children, selected, ...other } = props;
    const staticClass = 'pagination-item-page';
    let classNames = `${staticClass}-root`;
    if (selected) classNames = clsx(classNames, `${staticClass}-selected`);
    if (sizeArray.indexOf(size) !== -1) classNames = clsx(classNames, `${staticClass}-size-${size}`);
    if (variantArray.indexOf(variant) !== -1) classNames = clsx(classNames, `${staticClass}-variant-${variant}`);
    if (colorArray.indexOf(color) !== -1) classNames = clsx(classNames, `${staticClass}-color-${color}`);
    if (disabled) classNames = clsx(classNames, `${staticClass}-disabled`);
    if (shape === 'rounded') classNames = clsx(classNames, `${staticClass}-shape-${shape}`);

    const defaultProperty = {
        ...other,
        ref,
        className: classNames,
        disabled,
    };

    return <ButtonBase {...defaultProperty}>{children}</ButtonBase>;
});

const PaginationItemPageIcon = ({ as: Component = 'div' }) => {
    return <Component className="pagination-item-icon" />;
};

const PaginationItem = React.forwardRef((props, ref) => {
    const {
        page,
        disabled = false,
        selected = false,
        isRtl = false,
        type = 'page',
        shape = 'circular',
        color = 'standard',
        size = 'medium',
        variant = 'text',
        ...other
    } = props;

    const ellipsisProperty = {
        size,
        disabled,
        ref,
    };

    const pageProperty = {
        ...other,
        color,
        selected,
        size,
        disabled,
        shape,
        variant,
        ref,
    };

    const normalizedIcons = isRtl
        ? {
              previous: NavigateNextIcon,
              next: NavigateBeforeIcon,
              last: FirstPageIcon,
              first: LastPageIcon,
          }
        : {
              previous: NavigateBeforeIcon,
              next: NavigateNextIcon,
              first: FirstPageIcon,
              last: LastPageIcon,
          };

    const Icon = normalizedIcons[type];

    return type === 'start-ellipsis' || type === 'end-ellipsis' ? (
        <PaginationItemEllipsis {...ellipsisProperty}>…</PaginationItemEllipsis>
    ) : (
        <PaginationItemPage {...pageProperty}>
            {type === 'page' && page}
            {Icon ? <PaginationItemPageIcon as={Icon} /> : null}
        </PaginationItemPage>
    );
});

export default PaginationItem;

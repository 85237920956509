import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormControl, FormLabel, Stack, TextField, FormHelperText } from '@Components/';

const DurationField = React.forwardRef((props, ref) => {
    const {
        label,
        className,
        dayProps = {},
        monthProps = {},
        lightColor,
        required,
        isShowDay = false,
        isError: propsError = false,
        helperText = '此選項為必填，不得為空值',
        fullWidth,
        readOnly = false,
        disabled = false,
    } = props;
    const monthRef = useRef(null);
    const dayRef = useRef(null);
    const [isError, setError] = useState(propsError);

    useImperativeHandle(ref, () => ({
        isError: () => {
            const hasError = required && (!monthRef.current?.value || (isShowDay && !dayRef.current?.value));
            setError(hasError);
            return hasError;
        },
        getResult: () => ({
            month: monthRef.current?.getResult?.(),
            day: dayRef.current?.getResult?.(),
        }),
    }));

    const handleChange = (setter) => (e) => setter(e.target.value);

    useEffect(() => {
        setError(propsError);
    }, [propsError]);

    return (
        <FormControl fullWidth={fullWidth} className={clsx('durationField', className)} error={isError}>
            {label && <FormLabel lightColor={lightColor}>{label}</FormLabel>}
            <Stack direction="row" spacing="2" className="">
                <Stack direction="row" spacing="2" alignItems="center" className="durationField-wrap">
                    <TextField {...monthProps} ref={monthRef} readOnly={readOnly} disabled={disabled} onChange={handleChange(monthProps.onChange)} />
                    <span>月</span>
                </Stack>

                {isShowDay && (
                    <Stack direction="row" spacing="2" alignItems="center" className="durationField-wrap">
                        <TextField {...dayProps} ref={dayRef} readOnly={readOnly} disabled={disabled} onChange={handleChange(dayProps.onChange)} />
                        <span>天</span>
                    </Stack>
                )}
            </Stack>
            {isError && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
});

export default DurationField;

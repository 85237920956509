import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const NoAnswer = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#51A69E', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M20.9,10.1L19,6.7l-1.8-3.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.2-0.8,0-1,0.3L14,6.7l-1.9,3.4
	C12,10.2,12,10.3,12,10.4c0,0.5,0.3,0.8,0.7,0.8h7.6c0.1,0,0.2,0,0.3-0.1C21,10.9,21.1,10.5,20.9,10.1z M16,5.8
	c0-0.3,0.2-0.5,0.5-0.5S17,5.6,17,5.8v2.8c0,0.3-0.2,0.5-0.5,0.5S16,8.9,16,8.6V5.8z M16.5,10.5c-0.3,0-0.6-0.2-0.6-0.6
	c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6C17.1,10.2,16.8,10.5,16.5,10.5z"
            />
            <path
                d="M18.1,14.3c-0.5-0.6-1.4-0.6-2-0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.5,0.5-1,1-1.5,1.5c-0.1,0.1-0.3,0.2-0.4,0.1l0,0
	c-0.3-0.2-0.7-0.3-1-0.5c-1.4-0.9-2.6-2-3.7-3.4c-0.5-0.6-1-1.4-1.3-2.1C8,9.8,8.1,9.6,8.2,9.5c0.5-0.5,1-1,1.5-1.5
	c0.6-0.5,0.7-1.4,0.2-2C9.8,6,9.8,5.9,9.7,5.8C9.3,5.4,8.9,5.1,8.5,4.7C8.1,4.3,7.8,3.9,7.4,3.5C6.8,2.9,6,2.8,5.4,3.3L5.3,3.4
	c-0.5,0.5-1,1-1.5,1.5S3,6,3,6.6c0,1,0.1,2,0.5,3c0.7,1.9,1.8,3.7,3.2,5.3c1.8,2.1,4,3.9,6.5,5.1c1.1,0.6,2.4,0.9,3.6,1
	c0.9,0.1,1.7-0.2,2.3-0.9c0.4-0.5,0.9-0.9,1.3-1.4c0.6-0.5,0.7-1.4,0.2-2c0-0.1-0.1-0.1-0.2-0.2C19.7,15.8,18.9,15.1,18.1,14.3
	L18.1,14.3z"
            />
        </SvgIcon>
    );
});

export default NoAnswer;

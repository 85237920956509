import {DEVELOP_ICON_ROUTER, DEVELOP_TEST_ROUTER} from '@Router/routerPath';
import { IconsPage } from '@Local/Development/';
import {TestPage} from '@Local/Development';

export const developerRouter = {
    description: '開發用',
    menuName: '開發專用區',
    frontKey: 'D999X01',
    children: [
        {
            description: '所有SVG圖檔',
            menuName: '所有Icon',
            strict: true,
            path: DEVELOP_ICON_ROUTER,
            href: DEVELOP_ICON_ROUTER,
            as: IconsPage,
            frontKey: 'D999X02',
        },
        {
            description: 'test',
            menuName: '各種測試',
            strict: true,
            path: DEVELOP_TEST_ROUTER,
            href: DEVELOP_TEST_ROUTER,
            as: TestPage,
            frontKey: 'D999X03',
        },
    ],
};

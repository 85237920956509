import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const DialogFilled = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M12,3.2c-5.5,0-10,3.6-10,8.1c0,1.9,0.8,3.8,2.2,5.1c-0.4,1.4-1.2,2.7-2.1,3.7C2,20.3,2,20.4,2,20.6c0,0.1,0.2,0.2,0.3,0.2
	c2,0,3.9-0.7,5.5-2c1.3,0.5,2.8,0.8,4.2,0.8c5.5,0,10-3.6,10-8.1S17.5,3.2,12,3.2z"
            />
        </SvgIcon>
    );
});

export default DialogFilled;

import React from 'react';
import { Box } from '@Components/';
import useMessageDialog from '@Util/hook/useMessageDialog';
import clsx from 'clsx';

const MemoControllerBox = (props) => {
    const { className, textEllipsisColumns = 2, width = '180px', dialogTitle = '備註', data, isTableSpace = true } = props;
    const whiteSpaceNormal = isTableSpace ? 'white-space-normal' : '';
    const setMessageDialog = useMessageDialog();
    const handleClickMemo = () => {
        if (data && data.length > 0) {
            setMessageDialog({
                open: true,
                title: dialogTitle,
                msg: data,
            });
        }
    };

    return (
        <Box
            className={clsx(`memo-controller-box text-ellipsis-columns-${textEllipsisColumns}`, className, {
                'cursor-pointer': data && data.length > 0,
            })}
            style={{ width }}
            onClick={handleClickMemo}
        >
            <Box className={clsx(whiteSpaceNormal)}>{data}</Box>
        </Box>
    );
};

export default MemoControllerBox;

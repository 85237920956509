import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import clsx from 'clsx';
import { Button, DateField, Form, SelectField, DateIntervalField, TextField, CheckboxField } from '@Components/';
import { refIsRequiredError } from '@Util/utils';
import { GradientColorHeader } from '@Local/Components/Dialog';
import { ReadTextField } from '@Local/Components';
import { displayDateText, initialDate, parseDate } from '@Util/moment';

const MembershipDocumentDialogContent = forwardRef((props, ref) => {
    const { data, options } = props;
    const {
        membershipType,
        membershipMemberType,
        membershipTerm,
        applyDate,
        reviewStartDate,
        reviewEndDate,
        startDate,
        endDate,
        monthlyFee,
        firstMonthFee,
        memo,
        originalContractStartDate,
        originalContractEndDate,
        membershipStartDate,
        membershipEndDate,
    } = data || {};
    const { membershipTypeOptions = [], membershipMemberTypeOptions = [], membershipTermOptions = [] } = options || [];
    const membershipTypeRef = useRef(null);
    const membershipMemberTypeRef = useRef(null);
    const membershipTermRef = useRef(null);
    const applyDateRef = useRef(null);
    const reviewDateRef = useRef(null);
    const dateRef = useRef(null);
    const monthlyFeeRef = useRef(null);
    const firstMonthFeeRef = useRef(null);
    const memoRef = useRef(null);
    const confirmRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {};
                if (membershipTypeRef.current && membershipTypeRef.current.getResult) {
                    Object.assign(result, { membershipType: membershipTypeRef.current.getResult() });
                }
                if (membershipMemberTypeRef.current && membershipMemberTypeRef.current.getResult) {
                    Object.assign(result, { membershipMemberType: membershipMemberTypeRef.current.getResult() });
                }
                if (membershipTermRef.current && membershipTermRef.current.getResult) {
                    Object.assign(result, { membershipTerm: membershipTermRef.current.getResult() });
                }
                if (applyDateRef.current && applyDateRef.current.getResult) {
                    Object.assign(result, { applyDate: parseDate(applyDateRef.current.getResult()) });
                }
                if (reviewDateRef.current && reviewDateRef.current.getResult) {
                    const { startDate, endDate } = reviewDateRef.current.getResult();
                    Object.assign(result, { reviewStartDate: parseDate(startDate), reviewEndDate: parseDate(endDate) });
                }
                if (dateRef.current && dateRef.current.getResult) {
                    const { startDate, endDate } = dateRef.current.getResult();
                    Object.assign(result, { startDate: parseDate(startDate), endDate: parseDate(endDate) });
                }
                if (monthlyFeeRef.current && monthlyFeeRef.current.getResult) {
                    Object.assign(result, { monthlyFee: monthlyFeeRef.current.getResult() });
                }
                if (firstMonthFeeRef.current && firstMonthFeeRef.current.getResult) {
                    Object.assign(result, { firstMonthFee: firstMonthFeeRef.current.getResult() });
                }
                if (memoRef.current && memoRef.current.getResult) {
                    Object.assign(result, { memo: memoRef.current.getResult() });
                }
                return result;
            },
            isErrors: () =>
                refIsRequiredError([
                    membershipTypeRef,
                    membershipMemberTypeRef,
                    membershipTermRef,
                    applyDateRef,
                    reviewDateRef,
                    dateRef,
                    monthlyFeeRef,
                    firstMonthFeeRef,
                    memoRef,
                    confirmRef,
                ]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectField
                        key={membershipType}
                        ref={membershipTypeRef}
                        label={'會籍類型'}
                        defaultValue={membershipType}
                        options={membershipTypeOptions}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectField
                        key={membershipMemberType}
                        ref={membershipMemberTypeRef}
                        label={'會員類型'}
                        defaultValue={membershipMemberType}
                        options={membershipMemberTypeOptions}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectField
                        key={membershipTerm}
                        ref={membershipTermRef}
                        label={'合約長度'}
                        defaultValue={membershipTerm}
                        options={membershipTermOptions}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateField key={initialDate(applyDate)} ref={applyDateRef} label={'申請日期'} defaultValue={initialDate(applyDate)} fullWidth required />
                </Grid>
                <Grid item xs={12}>
                    <DateIntervalField
                        label={'審閱期'}
                        ref={reviewDateRef}
                        startDateProps={{
                            key: initialDate(reviewStartDate),
                            defaultValue: initialDate(reviewStartDate),
                        }}
                        endDateProps={{
                            key: initialDate(reviewEndDate),
                            defaultValue: initialDate(reviewEndDate),
                        }}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <DateIntervalField
                        label={'原生期間'}
                        ref={dateRef}
                        startDateProps={{
                            key: initialDate(startDate),
                            defaultValue: initialDate(endDate),
                        }}
                        endDateProps={{
                            key: initialDate(endDate),
                            defaultValue: initialDate(endDate),
                        }}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReadTextField label={'更新後合約期間'} value={displayDateText(originalContractStartDate, originalContractEndDate)} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <ReadTextField label={'更新後會籍期間'} value={displayDateText(membershipStartDate, membershipEndDate)} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField key={monthlyFee} ref={monthlyFeeRef} label={'月費'} defaultValue={monthlyFee} fullWidth required />
                </Grid>
                <Grid item xs={6}>
                    <TextField key={firstMonthFee} ref={firstMonthFeeRef} label={'破月費金額'} defaultValue={firstMonthFee} fullWidth required />
                </Grid>
                <Grid item xs={12}>
                    <TextField key={memo} ref={memoRef} label={'備註(教練看的到)'} defaultValue={memo} fullWidth multiline rows={4} />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField ref={confirmRef} required>
                        <CheckboxField.Checkbox value={true} label={'只會更新當前會籍，如有後續會籍請確認並更新。'} required />
                    </CheckboxField>
                </Grid>
            </Grid>
        </DialogContent>
    );
});

const MembershipDocumentDialog = (props) => {
    const {
        open = false,
        // sourceData,
        // refresh,
        onClose,
        className,
    } = props;
    const contentRef = useRef(null);

    const handleSubmit = (e) => {
        const isError = contentRef.current.isErrors();
        if (!isError) {
            const result = contentRef.current.getResult();
            console.log(result, 'result');
        }
    };

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-65rem', component: Form }}
            open={open}
            onSubmit={handleSubmit}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>修改會籍合約</GradientColorHeader>
            <MembershipDocumentDialogContent ref={contentRef} />
            <DialogActions>
                <Button variant="contained" type="submit">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MembershipDocumentDialog;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setMessageDialog as setMessageDialogAction } from '@Redux/messageDialog/action';

const useMessageDialog = () => {
    const dispatch = useDispatch();
    const setMessageDialog = useCallback(
        (data) => {
            dispatch(setMessageDialogAction(data));
        },
        // eslint-disable-next-line
        []
    );

    return setMessageDialog;
};

export default useMessageDialog;

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, Tab } from '@Components/';
import { auditMap } from '@Local/Points/staticData';
import usePointRewardAudit from '@Apis/usePointsRewardAudit';
import { isEmpty } from '@Util/utils';
import { POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER } from '@Router/routerPath';
import { usePointsContext } from '@Local/Points/Components/PointsContext';

const PointsAuditTabs = (props) => {
    const { setAuditStatusMapRef, setSkipAuditRef, setRefreshOptionsRef } = usePointsContext();
    const history = useHistory();
    const { trailType } = useParams();
    const [statusOption, setStatusOption] = useState([]);
    const [tabValue, setTabValue] = useState(POINTS_AUDIT_OVERVIEW_ON_TRIAL_ROUTER);
    const { getPointRewardAuditOptionsApi } = usePointRewardAudit();
    const preloadRef = useRef(null);

    const handleTabChange = (_e, value) => {
        setTabValue(value);
    };

    const initOptions = async (callback) => {
        const resp = await getPointRewardAuditOptionsApi();
        if (resp) {
            const { statusOption: opt, skipAudit } = resp;
            setSkipAuditRef(skipAudit);
            setStatusOption([...opt]);
            setAuditStatusMapRef({
                pass: resp.auditedStatus,
                deny: resp.notAcceptedStatus,
            });
            preloadRef.current = true;
            
            if (typeof callback === 'function') callback(resp);
        }
    };

    const TabMemo = useMemo(
        () => {
            return (
                <React.Fragment>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        {statusOption.map(({ text, value }) => (
                            <Tab key={value} className={'p-4'} label={text} value={auditMap[value]} />
                        ))}
                    </Tabs>
                </React.Fragment>
            );
        },
        // eslint-disable-next-line
        [tabValue, statusOption]
    );

    useEffect(
        () => {
            history.push(tabValue);
        },
        // eslint-disable-next-line
        [tabValue]
    );

    useEffect(
        () => {
            if (isEmpty(statusOption)) {
                initOptions();
            }
        },
        // eslint-disable-next-line
        [trailType]
    );

    setRefreshOptionsRef(initOptions);

    return (
        <React.Fragment>
            <header className={'page-header'}>{TabMemo}</header>
        </React.Fragment>
    );
};

export default PointsAuditTabs;

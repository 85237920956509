import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent, Slide } from '@mui/material';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { Card } from '@Components/';
import { isEmpty } from '@Util/utils';
import { PointProductGiftItemExchangeRecordTable } from '@Local/Points/ProductGiftItemExchange';
import usePointsRedeemOrderApi from '@Apis/usePointsRedeemOrderApi';
import { initBookmark } from '@Local/Points/staticData';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PointsProductGiftExchangeRecordDialog = React.forwardRef((props, ref) => {
    const { open: openProps = false, onClose: onCloseProps, sourceData: productsData = {} } = props;
    if (isEmpty(productsData) || !openProps) return null;
    const { getPointsRewardOrderRecordsApi } = usePointsRedeemOrderApi();
    const preload = useRef(false);
    const [sourceData, setSourceData] = useState([]);
    const [pageModel, setPageModel] = useState({
        ...initBookmark(),
    });

    const handlePageChange = (num) => {
        setPageModel((prev) => ({ ...prev, pageIndex: num }));
    };

    const getProductGiftDataApi = async (itemID) => {
        const resp = await getPointsRewardOrderRecordsApi(pageModel, itemID);
        if (resp) {
            const { list, ...others } = resp;
            setSourceData(list);
            setPageModel({ ...others });
            preload.current = true;
        }
    };

    useEffect(
        () => {
            if (preload.current) {
                const { itemID } = productsData;
                getProductGiftDataApi(itemID);
            }
        },
        // eslint-disable-next-line
        [pageModel.pageIndex]
    );

    useEffect(
        () => {
            const { itemID } = productsData;
            if (openProps) {
                getProductGiftDataApi(itemID);
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Dialog open={openProps} TransitionComponent={Transition} fullScreen>
            <GradientColorHeader onClose={onCloseProps}>兌換清單</GradientColorHeader>
            <DialogContent>
                <Card>
                    <Card.CardContent className={'p-4'}>
                        <PointProductGiftItemExchangeRecordTable rowsData={sourceData} onPageChange={handlePageChange} {...pageModel} />
                    </Card.CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
});

export default PointsProductGiftExchangeRecordDialog;

import React, { useImperativeHandle, useState, useRef } from 'react';
import {
    PointsRedeemRecordDialog,
    PointsGrantDialog,
    PointsProductGuideDialog,
    PointsProductGiftExchangeRecordDialog,
    PointsProductExchangeDialog,
    PointsMergeDialog,
    PointsProductGiftRecordDialog,
    PointsProductGiftItemDialog,
    PointsActivityBoxDialog,
    PointsProductGiftItemInventoryDialog,
    PointsRedeemOrderExcelDialog,
    PointsAuditSkipAuditDialog,
    PointRedeemAddMemberDialog,
    PointReviseMemberDialog,
} from '@Local/Points/Dialog/';
import useForkRef from '@Util/hook/useForkRef';
import { isEmpty } from '@Util/utils';
import PointsAuditCountsDialog from "@Local/Points/Dialog/PointsAuditCountsDialog";

const PointsDialogBox = React.forwardRef((props, ref) => {
    const dialogRef = useRef(null);
    const combinedRef = useForkRef(ref, dialogRef);
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState({});
    const [options, setOptions] = useState({}); // 可寫可不寫看自己需求
    const refreshRef = useRef(null);

    const doRefresh = () => {
        refreshRef.current && typeof refreshRef.current === 'function' && refreshRef.current();
    };

    useImperativeHandle(
        combinedRef,
        () => {
            return {
                openPointsRedeemMemberRecordDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsRedeemMemberRecord');
                    refreshRef.current = _refreshFn;
                },
                openPointsReviseMemberDialog: (targetData, _refreshFn = () => {}, options) => {
                    setSourceData(targetData);
                    !isEmpty(options) && setOptions(options);
                    setOpenDialog('pointsReviseMember');
                    refreshRef.current = _refreshFn;
                },
                openPointsGrantDialog: (targetData, _refreshFn = () => {}, options) => {
                    setSourceData(targetData);
                    !isEmpty(options) && setOptions(options);
                    setOpenDialog('pointsGrant');
                    refreshRef.current = _refreshFn;
                },
                openPointsProductGuideDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsProductGuide');
                    refreshRef.current = _refreshFn;
                },
                openProductGiftExchangeRecordDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('productGiftExchangeRecord');
                    refreshRef.current = _refreshFn;
                },
                openPointsProductExchangeDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsProductExchange');
                    refreshRef.current = _refreshFn;
                },
                openPointsMergeDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsMergeDialog');
                    refreshRef.current = _refreshFn;
                },
                openProductGiftRecordDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('productGiftRecord');
                    refreshRef.current = _refreshFn;
                },
                openProductGiftItemDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('productGiftItem');
                    refreshRef.current = _refreshFn;
                },
                openProductActivityDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsActivityBox');
                    refreshRef.current = _refreshFn;
                },
                openProductGiftItemInventory: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('productGiftItemInventory');
                    refreshRef.current = _refreshFn;
                },
                openRedeemOrderExcel: (targetOptions, _refreshFn = () => {}) => {
                    setOptions(targetOptions);
                    setOpenDialog('redeemOrderExcel');
                    refreshRef.current = _refreshFn;
                },
                openPointSkipAudit: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('skipAudit');
                    refreshRef.current = _refreshFn;
                },
                openPointAuditDialog: (targetData, _refreshFn = () => {}) => {
                    setSourceData(targetData);
                    setOpenDialog('pointsAuditDialog');
                    refreshRef.current = _refreshFn;
                },
                openRedeemAddMember: (targetData, _refreshFn = () => {}, options) => {
                    setSourceData(targetData);
                    !isEmpty(options) && setOptions(options);
                    setOpenDialog('redeemAddMember');
                    refreshRef.current = _refreshFn;
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        []
    );

    const handleCloseDialog = () => {
        setOpenDialog(null);
        setSourceData(null);
        setOptions(null);
        refreshRef.current = null;
    };

    return (
        <React.Fragment>
            <PointsRedeemRecordDialog
                open={openDialog === 'pointsRedeemMemberRecord'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
            <PointsGrantDialog open={openDialog === 'pointsGrant'} options={options} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsProductGuideDialog open={openDialog === 'pointsProductGuide'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsProductGiftExchangeRecordDialog
                open={openDialog === 'productGiftExchangeRecord'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
            <PointsProductExchangeDialog
                open={openDialog === 'pointsProductExchange'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
            <PointsMergeDialog open={openDialog === 'pointsMergeDialog'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsProductGiftRecordDialog open={openDialog === 'productGiftRecord'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsProductGiftItemDialog open={openDialog === 'productGiftItem'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsActivityBoxDialog open={openDialog === 'pointsActivityBox'} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            <PointsProductGiftItemInventoryDialog
                open={openDialog === 'productGiftItemInventory'}
                onClose={handleCloseDialog}
                sourceData={sourceData}
                refresh={doRefresh}
            />
            <PointsRedeemOrderExcelDialog open={openDialog === 'redeemOrderExcel'} onClose={handleCloseDialog} options={options} refresh={doRefresh} />
            <PointsAuditSkipAuditDialog
                open={openDialog === 'skipAudit'}
                sourceData={sourceData}
                onClose={handleCloseDialog}
                options={options}
                refresh={doRefresh}
            />
            <PointsAuditCountsDialog
                open={openDialog === 'pointsAuditDialog'}
                sourceData={sourceData}
                onClose={handleCloseDialog}
                refresh={doRefresh}
            />
            <PointRedeemAddMemberDialog
                open={openDialog === 'redeemAddMember'}
                sourceData={sourceData}
                onClose={handleCloseDialog}
                options={options}
                refresh={doRefresh}
            />
            <PointReviseMemberDialog
                open={openDialog === 'pointsReviseMember'}
                sourceData={sourceData}
                onClose={handleCloseDialog}
                options={options}
                refresh={doRefresh}
            />
        </React.Fragment>
    );
});

export default PointsDialogBox;

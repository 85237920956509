import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const staticClass = `typography`;
const alignArray = ['center', 'inherit', 'justify', 'left', 'right'];
const displayMap = {
    block: 'block',
    inlineBlock: 'inline-block',
};

const defaultVariantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'h6',
    body1: 'p',
    body2: 'p',
};
const colorMap = {
    main: 'text-main',
    primary: 'text-primary',
    secondary: 'text-secondary',
    error: 'text-error',
    white: 'text-white',
    gray: 'text-gray',
};

const Typography = React.forwardRef((props, ref) => {
    const {
        className,
        style,
        align = 'inherit',
        gutterBottom = false,
        noWrap = false,
        paragraph = false,
        variant = `body1`,
        component,
        display = '',
        mb = false,
        color = '',
        variantMapping = defaultVariantMapping,
        ...other
    } = props;
    const Component = component || (paragraph ? 'p' : variantMapping[variant] || defaultVariantMapping[variant]) || 'span';
    let classNames = `${staticClass}-root`;

    if (defaultVariantMapping[variant]) classNames = clsx(classNames, `${staticClass}-${variant}`);
    if (gutterBottom) classNames = clsx(classNames, `${staticClass}-gutter-bottom`);
    if (noWrap) classNames = clsx(classNames, `${staticClass}-no-wrap`);
    if (paragraph) classNames = clsx(classNames, `${staticClass}-paragraph`);
    if (!!displayMap[display]) classNames = clsx(classNames, `${staticClass}-display-${displayMap[display]}`);
    if (alignArray[align]) classNames = clsx(classNames, `${staticClass}-text-align-${align}`);
    if (mb) classNames = clsx(classNames, `${staticClass}-no-mb`);
    if (colorMap[color]) classNames = clsx(classNames, `${staticClass}-${colorMap[color]}`);

    const defaultProperty = {
        className: clsx(classNames, className),
        style,
        ref,
        ...other,
    };

    return <Component {...defaultProperty} />;
});

Typography.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * Set the text-align on the component.
     * @default 'inherit'
     */
    align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * If `true`, the text will have a bottom margin.
     * @default false
     */
    gutterBottom: PropTypes.bool,
    /**
     * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
     *
     * Note that text overflow can only happen with block or inline-block level elements
     * (the element needs to have a width in order to overflow).
     * @default false
     */
    noWrap: PropTypes.bool,
    /**
     * If `true`, the element will be a paragraph element.
     * @default false
     */
    paragraph: PropTypes.bool,
    /**
     * Applies the theme typography styles.
     * @default 'body1'
     */
    variant: PropTypes.oneOfType([
        PropTypes.oneOf(['body1', 'body2', 'button', 'caption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'inherit', 'overline', 'subtitle1', 'subtitle2']),
        PropTypes.string,
    ]),
    /**
     * The component maps the variant prop to a range of different HTML element types.
     * For instance, subtitle1 to `<h6>`.
     * If you wish to change that mapping, you can provide your own.
     * Alternatively, you can use the `component` prop.
     * @default {
     *   h1: 'h1',
     *   h2: 'h2',
     *   h3: 'h3',
     *   h4: 'h4',
     *   h5: 'h5',
     *   h6: 'h6',
     *   subtitle1: 'h6',
     *   subtitle2: 'h6',
     *   body1: 'p',
     *   body2: 'p',
     *   inherit: 'p',
     * }
     */
    variantMapping: PropTypes.object,
};

export default Typography;

import {PROMOTION_PLAN_OVERVIEW_ROUTER} from "@Router/routerPath";
import {PromotionPlanPage} from "@Local/PromotionPlan";

const documentRouter = [
    {
        description: '文書管理',
        frontKey: 'H4X01',
    },
    {
        description: '行銷方案',
        frontKey: 'H4X02',
        static: true,
        path: PROMOTION_PLAN_OVERVIEW_ROUTER,
        href: PROMOTION_PLAN_OVERVIEW_ROUTER,
        as: PromotionPlanPage,
    },
]

export default documentRouter;

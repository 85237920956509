import React, { useRef, useState } from 'react';
import { Box, IconButton, ListItemText, Menu, MenuItem } from '@Components/';
import { ScrollerArrowButton, Tablenization } from '@Local/Components';
import { MoreVertical as MoreVerticalIcon } from '@SvgIcon/';
import clsx from 'clsx';
import { formatCurrencyFn, isEmpty } from '@Util/utils';
import { Link, useHistory } from 'react-router-dom';
import { CLUB_MANAGEMENT_MEMBERSHIP_ROUTER_URL } from '@Router/routerPath';
import { displayDateText } from '@Util/moment';

const ActionMenuButton = ({ menuData = [], actionButtonProps = {}, actionMenuProps = {}, textAlign = 'left', enabled = true, ...other }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickActionButton = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // 為啟用與沒有操作項就不顯示
    if (!enabled || menuData.length < 1) return null;

    return (
        <React.Fragment>
            <IconButton {...actionButtonProps} onClick={handleClickActionButton}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu className={clsx('more-label-box-menu', `align-${textAlign}`)} anchorEl={anchorEl} open={open} onClose={(e) => handleCloseMenu(null)}>
                {menuData.map(({ text, ...others }) => {
                    return (
                        <MenuItem {...others} key={`more_label_${text}`}>
                            <ListItemText>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

const headerRow = [
    {
        cellKey: 'membershipID',
        headerLabel: '會籍ID',
        formatCell: (value, { isReview = true }) => {
            if (!isReview) return value;

            return (
                <Link className={'text-decoration-underline text-secondary'} to={CLUB_MANAGEMENT_MEMBERSHIP_ROUTER_URL(value)}>
                    {value}
                </Link>
            );
        },
    },
    {
        cellKey: 'useStatusText',
        headerLabel: '履約狀態',
    },
    {
        cellKey: 'membershipTypeText',
        headerLabel: '會籍類型',
    },
    {
        cellKey: 'reviewStartDate',
        headerLabel: '審閱期',
        align: 'center',
        formatCell: (reviewStartDate, { reviewEndDate }) => {
            return displayDateText(reviewStartDate, reviewEndDate);
        },
    },
    {
        cellKey: 'contractStartDate',
        headerLabel: '合約期間',
        align: 'center',
        formatCell: (contractStartDate, { contractEndDate }) => {
            return displayDateText(contractStartDate, contractEndDate);
        },
    },
    {
        cellKey: 'startDate',
        headerLabel: '會籍期間',
        align: 'center',
        formatCell: (startDate, { endDate }) => {
            return displayDateText(startDate, endDate);
        },
    },
    {
        cellKey: 'membershipTermText',
        headerLabel: '合約長度',
        align: 'center',
    },
    {
        cellKey: 'memberTypeText',
        headerLabel: '會員類型',
        align: 'center',
    },
    {
        cellKey: 'monthlyFee',
        headerLabel: '月費',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'more',
        headerLabel: '',
        width: 6.8,
        formatCell: (_, { isReview = true, isModify = true, membershipID }, { goToMemberMembershipDetailPage, dialogRef }) => {
            let menuData = [];

            if (isReview) {
                menuData = [
                    ...menuData,
                    {
                        text: '查看會籍',
                        onClick: (e) => {
                            goToMemberMembershipDetailPage(membershipID);
                        },
                    },
                ];
            }

            if (isModify) {
                menuData = [
                    ...menuData,
                    {
                        text: '修改會籍合約',
                        onClick: (e) => {
                            if (dialogRef && dialogRef.current) {
                                dialogRef.current.openMembershipDocumentDialog({ membershipID });
                            }
                        },
                    },
                ];
            }

            if (isEmpty(menuData)) return null;

            return <ActionMenuButton menuData={menuData} />;
        },
    },
];

const fakeData = [
    {
        membershipID: 2341,
        useStatusText: '進行中',
        membershipTypeText: '續約',
        reviewStartDate: '2022/06/28',
        reviewEndDate: '2022/06/30',
        contractStartDate: '2022/07/01',
        contractEndDate: '2024/07/31',
        startDate: '2022/07/01',
        endDate: '2024/08/02',
        membershipTermText: '二年約',
        memberTypeText: '一般會員',
        monthlyFee: 1380,
        isReview: true,
        isModify: true,
    },
    {
        membershipID: 13,
        useStatusText: '已完成',
        membershipTypeText: '新入會',
        reviewStartDate: '2019/03/15',
        reviewEndDate: '2019/03/17',
        contractStartDate: '2019/03/18',
        contractEndDate: '2021/06/30',
        startDate: '2019/03/18',
        endDate: '2021/06/30',
        membershipTermText: '二年約',
        memberTypeText: '一般會員',
        monthlyFee: 1380,
        isReview: true,
        isModify: true,
    },
];

const ClubManagementMembershipTable = (props) => {
    const { sourceData, dialogRef } = props;
    const { list = fakeData } = sourceData || {};
    const history = useHistory();
    const scrollerArrowRef = useRef(null);

    const goToMemberMembershipDetailPage = (memberID) => {
        history.push(CLUB_MANAGEMENT_MEMBERSHIP_ROUTER_URL(memberID));
    };

    return (
        <Box className={'scroll-arrow-container overview'}>
            <Tablenization cardProps={{ className: 'theme-gray' }} tableContainerRef={scrollerArrowRef}>
                <Tablenization.TableRowHeader headerRow={headerRow} />
                <Tablenization.TableRowBody
                    headerRow={headerRow}
                    rowsData={list}
                    goToMemberMembershipDetailPage={goToMemberMembershipDetailPage}
                    dialogRef={dialogRef}
                />
            </Tablenization>
            <ScrollerArrowButton parentRef={scrollerArrowRef} />
        </Box>
    );
};

export default ClubManagementMembershipTable;

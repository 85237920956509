import React, {useImperativeHandle, useState} from 'react';
import clsx from 'clsx';
import NavContent from '@Local/Navigation/NavContent';
import NavBottom from '@Local/Navigation/NavBottom';
import {Paper, Box} from '@Components/';
import TopBarStart from '@Local/TopBar/TopBarStart';

const NavigationPage = React.forwardRef((props, ref) => {
    const [open, setOpen] = useState(true);

    const handleToggleMenu = () => {
        setOpen((prev) => !prev);
    };
    const handleCloseMenu = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 1180) {
            setOpen(false);
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                triggerToggleMenu: () => handleToggleMenu(),
                triggerCloseMenu: () => handleCloseMenu(),
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Box className={clsx('nav', {open})}>
            <Paper elevation="4" className={clsx(`nav-container`)}>
                <TopBarStart className={'mb-2'} onToggleMenu={handleToggleMenu}/>
                <NavContent onCloseMenu={handleCloseMenu}/>
                <NavBottom/>
            </Paper>
        </Box>
    );
});

export default NavigationPage;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const MagnifyingGlassPlus = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M19.5,9.8c0,2.2-0.7,4.1-1.9,5.8l5.9,5.9c0.6,0.6,0.6,1.5,0,2.1c-0.6,0.6-1.5,0.6-2.1,0l-5.9-5.9c-1.6,1.2-3.6,1.9-5.8,1.9
	C4.4,19.5,0,15.2,0,9.8S4.4,0,9.7,0S19.5,4.4,19.5,9.8z M8.6,13.9c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1v-3h3
	c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-3v-3c0-0.6-0.5-1.1-1.1-1.1C9.1,4.5,8.6,5,8.6,5.7v3h-3C5,8.7,4.5,9.2,4.5,9.8
	c0,0.6,0.5,1.1,1.1,1.1h3V13.9z"
            />
        </SvgIcon>
    );
});

export default MagnifyingGlassPlus;

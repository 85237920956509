import React, { useEffect } from 'react';
import { IMaskInput, IMask } from 'react-imask';
import { isInvaild } from '@Util/utils';

const maskTypeEnum = {
    // 身分證
    IDCARD: {
        mask: 'ID0000 00000',
        blocks: {
            ID: {
                mask: /^[a-zA-Z]{0,1}$/g,
            },
        },
        prepare: function (str) {
            return str.toUpperCase();
        },
        placeholder: '例:A1234 00000',
    },
    // 信用卡卡號
    CCN: {
        mask: '0000-****-****-0000',
        definitions: {
            '#': /[\d]/,
            '*': /[\d*]/,
        },
        placeholder: '共16位數字',
    },
    // 信用卡有效日期
    CVV2: {
        mask: 'MM/YY',
        blocks: {
            YY: {
                mask: '00',
            },
            MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
            },
        },
        placeholder: '例:09/23 (月/年)',
    },
    // 手機
    MOB: {
        mask: '0000-000-000',
        definitions: {
            '#': /[\d]/,
        },
        placeholder: '例:09123456789',
    },
    // 自然人號碼
    CDC: {
        mask: 'TT00 0000 0000 0000',
        blocks: {
            TT: {
                mask: /^[a-zA-Z]{0,2}$/g,
            },
        },
        prepare: function (str) {
            return str.toUpperCase();
        },
        placeholder: '例:TP00 0000 0000 0000',
    },
    // 手機載具
    MB: {
        mask: /^\/[0-9A-Za-z.+-]{0,7}$/g,
        prepare: function (str) {
            return str.toUpperCase();
        },
        placeholder: '例:/TEST03R',
    },
    // 公司統一編號
    TID: {
        mask: /^[0-9]{0,8}$/g,
        placeholder: '共8位數字',
    },
    // 愛心碼
    DC: {
        mask: /^[0-9]{0,7}$/g,
        placeholder: '共3-7位數字',
    },
    //電話號碼 , # 用於分機
    TEL: {
        mask: /^[0-9,#+-]{0,20}$/g,
        placeholder: '請輸入電話號碼',
        autoComplete: 'off',
        type: 'tel',
    },
    MONEY: {
        mask: Number, // enable number mask
        scale: 0, // digits after point, 0 for integers
        thousandsSeparator: ',', // any single char
    },
};

const TextInputMask = React.forwardRef((props, ref) => {
    const { onChange: onChangeProps, value: valueProps, defaultValue: defaultValueProps, maskType = '', placeholder, ...other } = props;
    const initValue =
        valueProps !== undefined ? (isInvaild(valueProps) ? '' : String(valueProps)) : isInvaild(defaultValueProps) ? '' : String(defaultValueProps);
    const [maskValue, setmaskValue] = React.useState({
        value: initValue,
        viewValue: initValue,
    });
    const timeStampRef = React.useRef(1000);
    const inputRef = React.useRef(null);
    const maskProperty = maskTypeEnum[maskType] || {};
    const defaultProps = {};
    if (placeholder) {
        defaultProps.placeholder = placeholder;
    }

    React.useImperativeHandle(
        ref,
        () => {
            return {
                value: maskValue.value,
                name: inputRef.current.name,
                id: inputRef.current.id,
            };
        },
        [maskValue]
    );

    useEffect(
        () => {
            const numsKey = Object.keys(maskTypeEnum);
            if (!numsKey.includes(maskType)) {
                console.error('maskType is error,please check your type');
            }
        },
        // eslint-disable-next-line
        [maskType]
    );

    return (
        <IMaskInput
            {...other}
            {...maskProperty}
            value={maskValue.viewValue}
            inputRef={inputRef}
            onChange={() => {}}
            onAccept={(value, mask, inputEvent) => {
                if (inputEvent) {
                    const { timeStamp } = inputEvent;
                    if (timeStamp - timeStampRef.current > 0 && timeStamp - timeStampRef.current < 20) {
                        setmaskValue((prev) => ({ ...prev }));
                    } else {
                        setmaskValue({ viewValue: value, value: mask.unmaskedValue });
                        onChangeProps && onChangeProps({ target: { value: mask.unmaskedValue } });
                    }
                    timeStampRef.current = timeStamp;
                }
            }}
            {...defaultProps}
        />
    );
});

export default TextInputMask;

import React, {useEffect, useState, useRef} from 'react';
import {Dialog, DialogContent, Slide, Typography} from '@mui/material';
import {GradientColorHeader} from '@Local/Components/Dialog/';
import {Card} from '@Components/';
import {isEmpty} from '@Util/utils';
import {PointsRedeemRecordTable} from '@Local/Points/Redeem';
import {initBookmark} from '@Local/Points/staticData';
import usePointsRewardsApi from '@Apis/usePointsRewardsApi';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PointsRedeemRecordDialog = React.forwardRef((props, ref) => {
    const {open: openProps = false, onClose: onCloseProps, sourceData: memberData = {}} = props;
    if (isEmpty(memberData) || !openProps) return null;
    const {getPointsRewardsRecordApi} = usePointsRewardsApi();
    const [sourceData, setSourceData] = useState([]);
    const [pageModel, setPageModel] = useState({...initBookmark()});
    const {memberNo, displayName, totalPoints} = memberData;
    const preloadRef = useRef(false);
    const handlePageChange = (num) => {
        setPageModel((prev) => ({...prev, pageIndex: num}));
    };

    const getRedeemRecordApi = async (rewardID) => {
        const resp = await getPointsRewardsRecordApi(pageModel, rewardID);
        if (resp) {
            let data = {...resp, rewardID};
            setSourceData(data);
            preloadRef.current = true;
        }
    };

    useEffect(
        () => {
            if (preloadRef.current) {
                getRedeemRecordApi(memberData.rewardID);
            }
        },
        // eslint-disable-next-line
        [pageModel]
    );

    useEffect(
        () => {
            if (isEmpty(sourceData)) {
                getRedeemRecordApi(memberData.rewardID);
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Dialog open={openProps} TransitionComponent={Transition} fullScreen>
            <GradientColorHeader onClose={onCloseProps}>累兌點紀錄</GradientColorHeader>
            <DialogContent>
                <Typography className="font-weight-bold" variant="h4">{`${memberNo} ${displayName} 累積點數：${totalPoints}`}</Typography>
                <Card>
                    <Card.CardContent className={'p-4'}>
                        <PointsRedeemRecordTable sourceData={sourceData} onPageChange={handlePageChange} {...pageModel} />
                    </Card.CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
});

export default PointsRedeemRecordDialog;

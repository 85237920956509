import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@Components/';
import clsx from 'clsx';

const staticClass = `backdrop`;

const Backdrop = React.forwardRef((props, ref) => {
    const { children, className, invisible = false, open, transitionDuration, TransitionComponent = Fade, ...other } = props;
    let Componet = `div`;
    let classNames = staticClass;
    if (invisible) classNames = clsx(classNames, `${staticClass}-invisible`);

    const defaultProperty = {
        className: clsx(classNames, className),
        ref,
    };

    return (
        <TransitionComponent in={open} timeout={transitionDuration} {...other}>
            <Componet {...defaultProperty}>{children}</Componet>
        </TransitionComponent>
    );
});

Backdrop.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * @ignore
     */
    className: PropTypes.string,

    // ----------------------------- Not Use --------------------------------
    // /**                                                                  |
    //  * The components used for each slot inside the Backdrop.            |
    //  * Either a string to use a HTML element or a component.             |
    //  * @default {}                                                       |
    //  */                                                                  |
    // components: PropTypes.shape({                                        |
    //     Root: PropTypes.elementType,                                     |
    // }),                                                                  |
    // /**                                                                  |
    //  * The props used for each slot inside the Backdrop.                 |
    //  * @default {}                                                       |
    //  */                                                                  |
    // componentsProps: PropTypes.object,                                   |
    // ----------------------------------------------------------------------
    /**
     *
     * If `true`, the backdrop is invisible.
     * It can be used when rendering a popover or a custom select component.
     * @default false
     */
    invisible: PropTypes.bool,
    /**
     * If `true`, the component is shown.
     */
    open: PropTypes.bool.isRequired,

    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     */
    transitionDuration: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number,
        }),
    ]),
};

export default Backdrop;

import React, { useState, useRef } from 'react';
import { Box, Typography } from '@Components/';
import { PointsProductGiftSearchBar } from '@Local/Points/ProductGift/';
import { PointsProvider } from '@Local/Points/Components/PointsContext';
import { initBookmark } from '@Local/Points/staticData';
import { PointsProductGiftTable } from '@Local/Points/ProductGift/';
import { PointsDialogBox } from '@Local/Points/Dialog/';

const PointProductGiftItemPage = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState([]);
    const [pageModel, setPageModel] = useState({ ...initBookmark() });
    const dialogRef = useRef(null);
    const refreshRef = useRef(null);

    const childValue = {
        sourceData,
        setSourceData,
        pageModel,
        setPageModel,
        setNextPage: (num) => setPageModel((prev) => ({ ...prev, pageIndex: num })),
        dialogRef,
        refresh: () => typeof refreshRef.current === 'function' && setTimeout(refreshRef.current(), 50),
        setRefreshRef: (el) => (refreshRef.current = el),
    };

    return (
        <PointsProvider value={childValue}>
            <Box className="container main-container-spacing point-product-gift-container">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        兌換品總覽
                    </Typography>
                </header>
                <PointsProductGiftSearchBar />
                <PointsProductGiftTable />
                <PointsDialogBox ref={dialogRef} />
            </Box>
        </PointsProvider>
    );
});

export default PointProductGiftItemPage;

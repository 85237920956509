import React from 'react';

const Loading = ({ size, duration }) => {
    let defaultSize = size || 24;
    let defaultDuration = (duration || 600) / 1000;

    let bar1Duration = `0s`;
    let bar2Duration = `${defaultDuration / 4}s`;
    let bar3Duration = `${defaultDuration / 2}s`;
    let totalDuration = `${defaultDuration}s`;

    const args = {
        attributeType: 'XML',
        repeatCount: 'indefinite',
        dur: totalDuration,
    };

    return (
        <div className="loading-root">
            <svg x="0" y="0" width={defaultSize} height={defaultSize * 1.2} viewBox="0 0 24 30">
                <rect x="0" y="10" width="4" height="10" fill="#aa97d3" opacity="0.2">
                    <animate attributeName="opacity" values="0.2; 1; .2" begin={bar1Duration} {...args} />
                    <animate attributeName="height" values="10; 20; 10" begin={bar1Duration} {...args} />
                    <animate attributeName="y" values="10; 5; 10" begin={bar1Duration} {...args} />
                </rect>
                <rect x="8" y="10" width="4" height="10" fill="#aa97d3" opacity="0.2">
                    <animate attributeName="opacity" values="0.2; 1; .2" begin={bar2Duration} {...args} />
                    <animate attributeName="height" values="10; 20; 10" begin={bar2Duration} {...args} />
                    <animate attributeName="y" values="10; 5; 10" begin={bar2Duration} {...args} />
                </rect>
                <rect x="16" y="10" width="4" height="10" fill="#aa97d3" opacity="0.2">
                    <animate attributeName="opacity" values="0.2; 1; .2" begin={bar3Duration} {...args} />
                    <animate attributeName="height" values="10; 20; 10" begin={bar3Duration} {...args} />
                    <animate attributeName="y" values="10; 5; 10" begin={bar3Duration} {...args} />
                </rect>
            </svg>
        </div>
    );
};

export default Loading;

import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import { GET_CLUBS_CLUBID_PEOPLE_API } from '@Apis/apiPath';

const usePeopleApi = () => {
    const Axios = useAxios();

    const getClubPeopleDataApi = useCallback(
        async (clubID, type) => {
            const resp = await Axios.get(GET_CLUBS_CLUBID_PEOPLE_API(clubID), { type });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getClubPeopleDataApi,
    };
};

export default usePeopleApi;

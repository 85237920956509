import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Points = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="m15.26,8.54v-3.01h-1.45v3.02h-.32c-2.47,0-4.95,0-7.42,0-.7,0-1-.32-1-1.06,0-.38,0-.77,0-1.15,0-.6.33-.95.88-.95,1.21,0,2.42,0,3.71,0-.13-.07-.19-.11-.25-.14-.94-.5-1.44-1.34-1.45-2.46-.01-1.12.48-1.97,1.42-2.47.94-.5,1.86-.42,2.68.28.74.63,1.44,1.31,2.16,1.97.11.1.22.2.34.31.63-.58,1.26-1.16,1.89-1.74.18-.17.36-.35.55-.5.86-.69,1.8-.83,2.76-.32.93.49,1.4,1.36,1.39,2.46-.01,1.15-.53,2-1.52,2.49-.04.02-.09.05-.12.11.53,0,1.06,0,1.59,0,.68,0,1.37,0,2.05,0,.53,0,.83.32.84.89,0,.45,0,.9,0,1.35-.01.58-.33.92-.88.92-2.54,0-5.09,0-7.63,0-.06,0-.12,0-.22-.01Zm.8-4.77c.93,0,1.79.01,2.65,0,.4,0,.74-.37.84-.8.09-.41-.06-.82-.39-1.05-.35-.25-.8-.25-1.12.04-.65.58-1.28,1.18-1.98,1.82Zm-3.04,0c-.7-.64-1.35-1.25-2.02-1.83-.31-.27-.76-.23-1.1.03-.3.22-.44.64-.35,1.04.09.39.42.75.81.76.86.02,1.72,0,2.65,0Zm2.6,16.34c1.98,0,3.97,0,5.95,0,.69,0,.99-.32.99-1.07,0-2.86,0-5.72,0-8.57v-.32h-7.24v9.97h.3Zm-10.82-6.47c2.65,0,4.8,2.29,4.81,5.13,0,2.9-2.13,5.22-4.81,5.22C2.15,24,0,21.69,0,18.83c0-2.85,2.15-5.18,4.8-5.18Zm-1.81,7.27c-.02.4.27.61.59.42.35-.2.69-.4,1.02-.62.16-.1.29-.1.44,0,.32.21.65.4.97.6.17.11.33.14.5,0,.15-.12.17-.29.12-.49-.09-.37-.14-.75-.23-1.12-.05-.22-.01-.38.15-.53.28-.26.55-.55.83-.82.14-.14.22-.32.12-.48-.07-.13-.23-.25-.36-.27-.38-.08-.78-.09-1.16-.16-.12-.02-.25-.13-.31-.25-.18-.37-.32-.77-.5-1.14-.07-.13-.22-.25-.35-.29-.19-.05-.3.11-.38.29-.16.38-.31.77-.49,1.14-.05.11-.18.21-.29.23-.38.07-.76.12-1.14.15-.19.02-.35.08-.42.29-.07.21.02.37.16.51.26.25.5.52.77.75.19.17.25.33.18.6-.1.4-.16.8-.23,1.18Zm3.58-8.83c0,.23.05.32.26.4,2.79,1.05,4.48,3.92,4.17,7.09-.02.17-.04.34-.06.52h2.87v-9.96h-7.24c0,.67,0,1.31,0,1.95Z" />
        </SvgIcon>
    );
});

export default Points;

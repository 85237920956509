import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Plus = React.forwardRef((props, ref) => {
    const { className, ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path d="M16,9.143H9.143V16H6.857V9.143H0V6.857H6.857V0H9.143V6.857H16Z" transform="translate(4 4)"></path>
        </SvgIcon>
    );
});

export default Plus;

import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { Form, Card, AutocompleteField, Button, MenuItem, SelectField, Typography } from '@Components/';
import { PointsRedeemTable } from '@Local/Points/Redeem';
import { PointsDialogBox } from '@Local/Points/Dialog/';
import { PointsProvider, usePointsContext } from '@Local/Points/Components/PointsContext';
import { initBookmark } from '@Local/Points/staticData';
import usePointsRewardsApi from '@Apis/usePointsRewardsApi';
import { isEmpty, isInvaild } from '@Util/utils';
import { head } from 'lodash/array';

const SearchBar = React.memo((props) => {
    const [options, setOptions] = useState({});
    const [clubID, setClubID] = useState(0);
    const peopleIdRef = useRef(null);
    const activityIdRef = useRef(null);
    const { setSourceData, setActivityIdRef, setRefreshRef, bookmark, setClubIDRef, setOptionsRef, dialogRef, setBookmarkNum } = usePointsContext();
    const { getPointsRewardsOverviewOptionsApi, getPointsRewardsOverviewApi, getPointRewardsExportApi, getPointRewardsExportAllApi } = usePointsRewardsApi();
    const { peopleOption = [], activityOption = [], clubOption = [] } = options;
    const preloadRef = useRef(false);

    const getParams = (pageModel = {}) => {
        let defaultActivityID = (head(activityOption) ?? { value: '' }).value;
        let params = {
            clubID: clubID,
            rewardID: peopleIdRef.current?.getResult('id') ?? '',
            activityID: activityIdRef.current.getResult() || defaultActivityID,
            ...bookmark,
        };
        if (!isEmpty(pageModel)) {
            Object.assign(params, pageModel);
        }

        return params;
    };

    const handleSearchOnClick = () => {
        if (isEmpty(options)) return;
        setBookmarkNum(1);
        getOverviewApi(getParams());
    };

    const handleClubOnChange = (targetData = {}) => {
        let value;
        if (isInvaild(targetData)) {
            value = '';
            setSourceData({});
        } else value = targetData.value;
        setClubID(value);
        setClubIDRef(value);
    };

    const handleRedeemAddMemberOnclick = () => {
        const params = getParams();
        dialogRef.current.openRedeemAddMember({ clubID: params.clubID, activityID: params.activityID }, handleSearchOnClick, clubOption);
    };

    const handleExcelOnclick = () => {
        const params = getParams();
        getExcelApiDownloadApi({ clubID: params.clubID, activityID: params.activityID });
    };

    const handleAllExcelOnclick = () => {
        const params = getParams();
        getExcelApiDownloadAllApi({ activityID: params.activityID });
    };

    // 總覽查詢
    const getOverviewApi = async (params) => {
        const resp = await getPointsRewardsOverviewApi(params);
        if (resp) {
            const { list, ...pageModel } = resp;
            setSourceData({ list: [...list], ...pageModel });
            setActivityIdRef(params.activityID);
            preloadRef.current = true;
        }
    };

    const getExcelApiDownloadApi = async (params) => {
        await getPointRewardsExportApi(params);
    };
    
    const getExcelApiDownloadAllApi = async (params) => {
        await getPointRewardsExportAllApi(params);
    }

    // 切換店舖
    const getChangeClubOptions = async () => {
        setBookmarkNum(1);
        const resp = await getPointsRewardsOverviewOptionsApi({ clubID: clubID });
        if (resp) {
            setOptions(resp);
            setOptionsRef(resp);
            preloadRef.current = true;
        }
    };

    const initOptions = async () => {
        const resp = await getPointsRewardsOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
            preloadRef.current = true;
        }
    };

    useEffect(
        () => {
            if (preloadRef.current) {
                preloadRef.current = false;
                const params = getParams(bookmark);
                getOverviewApi(params);
            }
        },
        // eslint-disable-next-line
        [bookmark.pageIndex]
    );

    useEffect(
        () => {
            if (preloadRef.current) {
                getChangeClubOptions();
            }
        },
        // eslint-disable-next-line
        [clubID]
    );

    useEffect(
        () => {
            if (preloadRef.current) {
                handleSearchOnClick();
            }
        },
        // eslint-disable-next-line
        [options, clubID]
    );

    useEffect(
        () => {
            if (isEmpty(options)) {
                initOptions();
            }
        },
        // eslint-disable-next-line
        []
    );

    setRefreshRef(() => {
        getOverviewApi(getParams(bookmark));
    });

    return (
        <Form onSubmit={handleSearchOnClick}>
            <Card>
                <Card.CardContent className="p-4 px-2">
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <AutocompleteField
                                label="店舖名稱"
                                inputProps={{ placeholder: '請輸入店名/店碼' }}
                                options={clubOption}
                                onChange={handleClubOnChange}
                                optionKeys={['text']}
                                primaryKey={'id'}
                                direction="row"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <SelectField
                                key={activityOption.length}
                                ref={activityIdRef}
                                label="兌換時間"
                                defaultValue={activityOption[0]?.value ?? ''}
                                direction="row"
                                displayEmpty
                                required
                                fullWidth
                            >
                                <MenuItem value="" disabled>
                                    <em>請選擇</em>
                                </MenuItem>
                                {activityOption.map(({ text, value, disabled }) => (
                                    <MenuItem key={value} value={value} disabled={disabled}>
                                        {text}
                                    </MenuItem>
                                ))}
                            </SelectField>
                        </Grid>
                        {!!clubID ? (
                            <React.Fragment>
                                <Grid item xs={5}>
                                    <AutocompleteField
                                        label="兌換對象"
                                        inputProps={{ placeholder: '請輸入姓名/手機號碼/會員編號(擇一即可)' }}
                                        ref={peopleIdRef}
                                        options={peopleOption}
                                        optionKeys={['name', 'mobile', 'no']}
                                        primaryKey={'id'}
                                        direction="row"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Button className="ml-2" variant="contained" type="submit">
                                        搜尋
                                    </Button>
                                    <Button className="ml-2" variant="contained" color="info" onClick={handleRedeemAddMemberOnclick}>
                                        新增兌換會員
                                    </Button>
                                    <Button className="ml-2" variant="contained" color="success" onClick={handleExcelOnclick}>
                                        下載Excel
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <Grid item xs={2}>
                                <Button className="ml-2" variant="contained" color="success" onClick={handleAllExcelOnclick}>
                                    下載全店舖Excel
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Card.CardContent>
            </Card>
        </Form>
    );
});

// 點數兌換
const PointsRedeemPage = React.forwardRef((props, ref) => {
    const [sourceData, setSourceData] = useState({});
    const dialogRef = useRef(null);
    const activityIdRef = useRef(null);
    const optionsRef = useRef(null);
    const clubIDRef = useRef(null);
    const refreshRef = useRef(null);
    const [bookmark, setBookmark] = useState({
        ...initBookmark(),
    });

    const childContext = {
        dialogRef: dialogRef,
        setDialogRef: (el) => (dialogRef.current = el),
        activityId: activityIdRef,
        setActivityIdRef: (actID) => (activityIdRef.current = actID),
        clubIDFn: () => clubIDRef.current,
        setClubIDRef: (clubID) => (clubIDRef.current = clubID),
        optionsFn: () => optionsRef.current,
        setOptionsRef: (opts = {}) => (optionsRef.current = opts),
        sourceData,
        setSourceData,
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        refresh: () => typeof refreshRef.current === 'function' && refreshRef.current(),
        setRefreshRef: (fn) => (refreshRef.current = fn),
    };

    return (
        <PointsProvider value={childContext}>
            <div className="container main-container-spacing points-redeem-container">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        會員點數總覽
                    </Typography>
                </header>
                <SearchBar />
                <PointsRedeemTable />
                <PointsDialogBox ref={dialogRef} />
            </div>
        </PointsProvider>
    );
});

export default PointsRedeemPage;

import React, { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, Button, ListItemText, Menu, MenuItem, ClickAwayListener } from '@Components/';
import { localUser } from '@Util/utils';
import { AngleDown as AngleDownIcon, AngleUp as AngleUpIcon } from '@SvgIcon/';
import PasswordResetDialog from '@Local/TopBar/PasswordResetDialog';

const PASSWORD_RESET = 'password-reset';
const TopBarEnd = (props) => {
    const user = localUser.get();
    const [openMenu, setOpenMenu] = useState(false);
    const [openDialog, setOpenDialog] = useState('');
    const anchorRef = useRef();

    const handleToggleMenu = (event) => {
        if (!openMenu) {
            anchorRef.current = event.currentTarget;
        }
        setOpenMenu((old) => !old);
    };

    const handleColesMenu = (e) => {
        setOpenMenu(false);
        anchorRef.current = null;
    };

    const handleColesDialog = () => {
        setOpenDialog('');
    };

    const handleOpenDialog = (target) => {
        setOpenDialog(target);
    };

    const USER_MENU = [
        {
            id: uuid(),
            text: '變更密碼',
            onClick: () => {
                handleOpenDialog(PASSWORD_RESET);
            },
        },
    ];

    return (
        <Box className={'top-bar-end'}>
            <ClickAwayListener onClickAway={handleColesMenu}>
                <Button endIcon={openMenu ? <AngleUpIcon /> : <AngleDownIcon />} onClick={handleToggleMenu}>
                    {`Hi, ${user.displayName}`}
                </Button>
            </ClickAwayListener>
            <Menu anchorEl={anchorRef.current} open={openMenu} onClose={handleColesMenu}>
                {USER_MENU.map((menu) => {
                    const { text, id, onClick } = menu;
                    return (
                        <MenuItem key={id}>
                            <ListItemText onClick={onClick}>{text}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
            <PasswordResetDialog open={openDialog === PASSWORD_RESET} onClose={handleColesDialog} />
        </Box>
    );
};

export default TopBarEnd;

import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, Stack, Typography } from '@Components/';
import { CyclePurchaseProvider } from '@Local/CyclePurchase/Components/CyclePruchaseContext';
import { Grid } from '@mui/material';
import { parseDate } from '@Util/moment';
import { formatCurrencyFn } from '@Util/utils';
import { DateTimeStandard } from '../../Components/pickers/enums/DateTimeStandard';
import CyclePurchaseBillingDetailPaymentFailureTable from '@Local/CyclePurchase/CyclePurchaseBillingDetailPaymentFailureTable';
import useCyclePurchaseApi from '@Apis/useCyclePurchaseApi';

const billingInfo = [
    {
        key: 'taskStatusText',
        text: '狀態',
    },
    {
        key: 'settleDate',
        text: '送件扣款日期',
        formatter: (value) => parseDate(value),
    },
    {
        key: 'shippingMonth',
        text: '出貨月份',
        formatter: (value) => parseDate(value, DateTimeStandard.DisplayDateOfMonth),
    },
    {
        key: 'shippingDayText',
        text: '出貨週期',
        formatter: (value) => `${value}日`,
    },
    {
        key: 'totalAmount',
        text: '總筆數',
    },
    {
        key: 'totalPrice',
        text: '扣款金額',
        formatter: (value) => formatCurrencyFn(value, '$'),
    },
    {
        key: 'settleUserDisplayName',
        text: '扣款人員',
    },
    {
        key: 'settleDate',
        text: '送件扣款日期',
        formatter: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
    },
];

const handleValue = (value, formatter) => {
    if (!formatter) return value;

    if (typeof formatter === 'function') return formatter(value);
};

const CyclePurchaseBillingDetailPage = (props) => {
    const { maskID } = props.match.params || {};
    const { getCyclePurchaseTasksApi } = useCyclePurchaseApi();
    const [sourceData, setSourceData] = useState({});
    const dialogRef = useRef(null);
    const completeCheckout = String(sourceData.taskStatus) === '4' || true;

    const getDetailApi = async (taskID) => {
        const resp = await getCyclePurchaseTasksApi(taskID);
        if (resp) {
            setSourceData(resp);
        }
    };

    const childContext = {
        dialogRef: dialogRef,
        sourceData,
        setSourceData,
    };

    useEffect(
        () => {
            getDetailApi(maskID);
        },
        // eslint-disable-next-line
        [],
    );
    return (
        <CyclePurchaseProvider value={childContext}>
            <Box className={'container main-container-spacing'}>
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        銀行扣款明細
                    </Typography>
                </Box>
                <Card className={'mb-1 px-4 py-3'}>
                    <Card.CardContent>
                        <Grid spacing={2} container>
                            {billingInfo.map((item, i) => {
                                const { key, text, formatter } = item;
                                return (
                                    <Grid key={`${key}-${i}`} xs={3} item>
                                        <Grid container>
                                            <Grid xs={5} item>
                                                <Box className={'font-color-3'}>{text}</Box>
                                            </Grid>
                                            <Grid xs={7} item>
                                                <Box>{handleValue(sourceData[key], formatter)}</Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Card.CardContent>
                </Card>
                {completeCheckout && (
                    <React.Fragment>
                        <Card className={'mb-1 px-4 py-3'}>
                            <Card.CardContent>
                                <Grid spacing={2} container>
                                    <Grid xs={3} item>
                                        <Stack className={'h-100'} alignItems={'center'}>
                                            <Grid container>
                                                <Grid xs={5} item>
                                                    <Box className={'text-error'}>失敗筆數</Box>
                                                </Grid>
                                                <Grid xs={7} item>
                                                    <Box className={'text-error'}>{sourceData.settleFailureAmount}</Box>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                    <Grid xs={3} item>
                                        <Stack className={'h-100'} alignItems={'center'}>
                                            <Grid container>
                                                <Grid xs={5} item>
                                                    <Box className={'text-success'}>成功筆數</Box>
                                                </Grid>
                                                <Grid xs={7} item>
                                                    <Box className={'text-success'}>{sourceData.settleSuccessAmount}</Box>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Stack alignItems={'center'} justifyContent={'end'}>
                                            <Box className={'mr-4 font-color-3'}>
                                                實際扣款金額
                                                <Box component={'span'} className={'h5 ml-2 font-weight-bold font-color-1'}>
                                                    {sourceData.settleSuccessPrice && formatCurrencyFn(sourceData.settleSuccessPrice, '$')}
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Card.CardContent>
                        </Card>
                        <Card className={'p-4'}>
                            <Card.CardContent>
                                <CyclePurchaseBillingDetailPaymentFailureTable />
                            </Card.CardContent>
                        </Card>
                    </React.Fragment>
                )}
            </Box>
        </CyclePurchaseProvider>
    );
};

export default CyclePurchaseBillingDetailPage;

import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, Grid, DialogActions } from '@mui/material';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { SelectField, MenuItem, Button, TextField, DateIntervalField, Switch, FormControlLabel } from '@Components/';
import { refIsRequiredError } from '@Util/utils';
import { initialDate } from '@Util/moment';
import usePromotionPlanApi from "@Apis/usePromotionPlanApi";

const PromotionPlanDialog = React.forwardRef((props, ref) => {
    const { open = false, onClose, sourceData: planData, refresh } = props;
    const { getPromotionPlanOptionsApi, postPromotionPlanApi, putPromotionPlanApi } = usePromotionPlanApi();
    const [options, setOptions] = useState({});
    const { planTypeOption = [], freeTypeOption = []} = options || {};
    const planTypeRef = useRef(null);
    const planNameRef = useRef(null);
    const planDescriptionRef = useRef(null);
    const freeMonthRef = useRef(null);
    const freeDayRef = useRef(null);
    const freeTypeRef = useRef(null);
    const joinFeeRef = useRef(null);
    const dateRangeRef = useRef(null);
    const isEnabledRef = useRef(null);
    const {
        planID = 0,
        planType = "",
        planName = "",
        planDescription = "",
        freeMonth,
        freeDay,
        freeType = 0,
        joinFee,
        startDate:planStartDate,
        endDate:planEndDate,
        isEnabled = true,
    } = planData || {};

    const getParams = () => {
        let result = {};

        if (planTypeRef.current && planTypeRef.current.getResult) {
            Object.assign(result, {planType: planTypeRef.current.getResult()})
        }

        if (planNameRef.current && planNameRef.current.getResult) {
            Object.assign(result, {planName: planNameRef.current.getResult()})
        }

        if (planDescriptionRef.current && planDescriptionRef.current.getResult) {
            Object.assign(result, {planDescription: planDescriptionRef.current.getResult()})
        }

        if (freeMonthRef.current && freeMonthRef.current.getResult) {
            Object.assign(result, {freeMonth: freeMonthRef.current.getResult()})
        }

        if (freeDayRef.current && freeDayRef.current.getResult) {
            Object.assign(result, {freeDay: freeDayRef.current.getResult()})
        }

        if (freeTypeRef.current && freeTypeRef.current.getResult) {
            Object.assign(result, {freeType: freeTypeRef.current.getResult()})
        }

        if (joinFeeRef.current && joinFeeRef.current.getResult) {
            Object.assign(result, {joinFee: joinFeeRef.current.getResult()})
        }

        if (dateRangeRef.current && dateRangeRef.current.getResult) {
            const {startDate, endDate} = dateRangeRef.current.getResult();
            Object.assign(result, {startDate, endDate})
        }

        if (isEnabledRef.current) {
            Object.assign(result, {isEnabled: isEnabledRef.current.checked})
        }

        return result;
    };

    const handlePromotionPlanOptionsApi = async () => {
        const resp = await getPromotionPlanOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    }

    const handlePointsRewardsIdAccumulateApi = async (planID, params) => {
        let resp;

        if (planID) {
            resp = await putPromotionPlanApi(planID, params);
        } else {
            resp = await postPromotionPlanApi(params);
        }

        if (resp) {
            if (typeof refresh === 'function') refresh();
            if (typeof onClose === 'function') onClose();
        }
    }

    const handleSubmitForm = () => {
        const isError = refIsRequiredError(
            planTypeRef,
            planNameRef,
            planDescriptionRef,
            freeMonthRef,
            freeDayRef,
            freeTypeRef,
            joinFeeRef,
            dateRangeRef,
            isEnabledRef,
        )
        if (!isError) {
            const params = getParams();
            handlePointsRewardsIdAccumulateApi(planID, params);
        }

    };

    useEffect(
        () => {
            if (open) handlePromotionPlanOptionsApi();
        },
        // eslint-disable-next-line
        [open]
    );

    return (
        <Dialog open={open}>
            <GradientColorHeader onClose={onClose}>行銷方案</GradientColorHeader>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid xs={6} item>
                        <SelectField key={planType} ref={planTypeRef} label={'項目分類'} defaultValue={planType} displayEmpty fullWidth required>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {planTypeOption.map(({ value, text }) => (
                                <MenuItem key={value} value={value}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField key={planName} ref={planNameRef} label={'項目名稱'} defaultValue={planName} fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField key={planDescription} ref={planDescriptionRef} label={'項目說明'} defaultValue={planDescription} minRows={3} multiline fullWidth />
                    </Grid>
                    <Grid xs={6} item>
                        <SelectField key={freeType} ref={freeTypeRef} label={'贈送類型'} defaultValue={freeType} displayEmpty fullWidth required>
                            <MenuItem disabled value="">
                                <em>請選擇</em>
                            </MenuItem>
                            {freeTypeOption.map(({ value, text }) => (
                                <MenuItem key={value} value={value}>
                                    {text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                    <Grid xs={6} item />
                    <Grid xs={6} item>
                        <TextField key={freeMonth} ref={freeMonthRef} label={'贈送月數'} defaultValue={freeMonth} fullWidth required />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField key={freeDay} ref={freeDayRef} label={'贈送天數'} defaultValue={freeDay} fullWidth required />
                    </Grid>
                    <Grid xs={6} item>
                        <TextField key={joinFee} ref={joinFeeRef} label={'入會費'} defaultValue={joinFee} fullWidth required />
                    </Grid>
                    <Grid xs={12} item>
                        <DateIntervalField
                            key={`${initialDate(planStartDate)}_${initialDate(planEndDate)}`}
                            ref={dateRangeRef}
                            label={'方案生效時間'}
                            startDateProps={{
                                defaultValue: initialDate(planStartDate),
                            }}
                            endDateProps={{
                                defaultValue: initialDate(planEndDate),
                            }}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <FormControlLabel
                            className={'m-0'}
                            control={
                                <Switch key={isEnabled} inputProps={{ ref: isEnabledRef }} defaultChecked={isEnabled} />
                            }
                            prefixLabel={'是否生效'}
                            labelPlacement={'start'}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmitForm}>
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PromotionPlanDialog;

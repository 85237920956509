import React, { useEffect, useState, useRef } from 'react';
import { GradientColorHeader } from '@Local/Components/Dialog/';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Box, Button, TextField, AutocompleteField, Alert } from '@Components/';
import { ReadTextField } from '@Local/Components/';
import { refIsRequiredError } from '@Util/utils';
import usePointsRewardsApi from '@Apis/usePointsRewardsApi';

const PointRedeemAddMemberDialog = React.forwardRef((props, ref) => {
    const { open: openProps, onClose: onCloseProps, sourceData: memberDataProps, options: optionsProps = [], refresh } = props;
    if (!openProps) return null;
    const [isResource, setResource] = useState(false);
    const [option, setOption] = useState([]);
    const memberIDRef = useRef(null);
    const memberNameRef = useRef(null);
    const memberMobileRef = useRef(null);
    const memberNoRef = useRef(null);
    const pointsRef = useRef(null);
    const { postPointRewardsAddMember, getPointsRewardsAppendOptionsApi } = usePointsRewardsApi();
    const { clubID } = memberDataProps;
    const clubName = optionsProps.find(({ value }) => String(value) === String(clubID))?.text ?? '';

    const getParams = () => {
        let params = {
            clubID: clubID,
            totalPoints: pointsRef.current.getResult(),
        };
        if (memberIDRef.current) {
            Object.assign(params, { memberID: memberIDRef.current.getResult('id') });
        }
        if (memberNameRef.current) {
            Object.assign(params, { displayName: memberNameRef.current.getResult() });
            Object.assign(params, { cellPhone: memberMobileRef.current.getResult() });
            Object.assign(params, { memberNo: memberNoRef.current.getResult() });
        }
        return params;
    };

    const handleConfirmClick = () => {
        if (!refIsRequiredError(memberIDRef, memberNameRef, memberMobileRef, memberNoRef, pointsRef)) {
            const params = getParams();
            doAddMemberApi(params);
        }
    };

    const doAddMemberApi = async (params) => {
        const resp = await postPointRewardsAddMember(params);
        if (resp) {
            refresh();
            onCloseProps();
        }
    };

    const getInitApi = async (id) => {
        const resp = await getPointsRewardsAppendOptionsApi({ clubID: id });
        if (resp) {
            const { useResource, peopleOption } = resp;
            if (useResource) {
                setResource(useResource);
                setOption(peopleOption);
            }
        }
    };

    useEffect(
        () => {
            getInitApi(memberDataProps.clubID);
        },
        // eslint-disable-next-line
        [openProps],
    );

    return (
        <Dialog PaperProps={{ className: 'wx-60rem' }} open={openProps} fullWidth>
            <GradientColorHeader onClose={onCloseProps}>新增兌換會員</GradientColorHeader>
            <DialogContent>
                <Box className="p-2">
                    <Grid container spacing={2}>
                        <Grid xs={12} item>
                            <ReadTextField label="店鋪" children={clubName} direction="row" />
                        </Grid>
                        {isResource ? (
                            <Grid xs={12} item>
                                <AutocompleteField
                                    ref={memberIDRef}
                                    label="會員"
                                    inputProps={{ placeholder: '請輸入姓名/編號/電話' }}
                                    options={option}
                                    optionKeys={['name', 'no', 'mobile']}
                                    primaryKey={'id'}
                                    direction="row"
                                    required
                                    fullWidth
                                />
                            </Grid>
                        ) : (
                            <React.Fragment>
                                <Grid xs={12} item>
                                    <TextField ref={memberMobileRef} direction="row" label="手機" maskType="MOB" fullWidth />
                                </Grid>
                                <Grid xs={12} item>
                                    <TextField ref={memberNameRef} direction="row" label="姓名" required fullWidth />
                                </Grid>
                                <Grid xs={12} item>
                                    <TextField ref={memberNoRef} direction="row" label="編號" required fullWidth />
                                </Grid>
                            </React.Fragment>
                        )}
                        <Grid xs={12} item>
                            <TextField ref={pointsRef} direction="row" defaultValue="0" label="點數" maskType="MONEY" required fullWidth />
                        </Grid>
                        {!isResource && (
                            <Grid xs={12} item>
                                <Alert variant="outlined" severity="info" color="info">
                                    康聚系統修改編號將會影響後續累點結果，請確認編號是否正確
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={handleConfirmClick}>
                    確認新增
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PointRedeemAddMemberDialog;

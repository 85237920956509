import { useCallback } from 'react';
import useAxios from '@Apis/useAxios';
import { GET_POINTS_ACTIVITIES_OVERVIEW_API, PUT_POINTS_ACTIVITIES_ACTIVITYID_API, POST_POINTS_ACTIVITIES_API } from '@Apis/apiPath';

// 兌換贈品活動
const usePointsActivityApi = () => {
    const Axios = useAxios();

    const getPointsActivityOverviewApi = useCallback(
        async (params) => {
            const resp = await Axios.get(GET_POINTS_ACTIVITIES_OVERVIEW_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postPointsActivityOverviewApi = useCallback(
        async (params) => {
            const resp = await Axios.post(POST_POINTS_ACTIVITIES_API, params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const putPointsActivityOverviewApi = useCallback(
        async (params, activityID) => {
            const resp = await Axios.put(PUT_POINTS_ACTIVITIES_ACTIVITYID_API(activityID), params);
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return { getPointsActivityOverviewApi, postPointsActivityOverviewApi, putPointsActivityOverviewApi };
};

export default usePointsActivityApi;

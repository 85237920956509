import React from 'react';
import { Box, Card, Stack } from '@Components/';
import { MemberInfoCard } from '@Local/Components';
import { Grid } from '@mui/material';

const InfoBox = (props) => {
    const { label = '', value = '' } = props;
    return (
        <Stack className={'info-box'} justifyContent={'center'} alignItems={'center'} direction={'column'}>
            <Box className={'info-box-label'}>{label}</Box>
            <Box className={'info-box-value'}>{value}</Box>
        </Stack>
    );
};

const MemberPanel = (props) => {
    const { className, sourceData } = props;
    const {
        memberName = '李玉華',
        cellPhone = '0912121123',
        memberNo = 'A000000978',
        membershipStatusText = '有效會籍',
        memberTypeText = '一般會員',
        clubName = '健康快樂店',
    } = sourceData || {};
    return (
        <Card className={className}>
            <Card.CardContent className={'p-3'}>
                <Stack spacing={3} justifyContent={'space-between'}>
                    <MemberInfoCard memberName={memberName} subtitle={cellPhone} memberNo={memberNo} isShowAvatar />
                    <Grid className={'other-content'} container spacing={2}>
                        <Grid item xs={4}>
                            <InfoBox label={'當前狀態'} value={membershipStatusText} />
                        </Grid>
                        <Grid item xs={4}>
                            <InfoBox label={'會員類型'} value={memberTypeText} />
                        </Grid>
                        <Grid item xs={4}>
                            <InfoBox label={'所屬店鋪'} value={clubName} />
                        </Grid>
                    </Grid>
                </Stack>
            </Card.CardContent>
        </Card>
    );
};

export default MemberPanel;

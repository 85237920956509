import React, {useLayoutEffect} from 'react';
import {Switch, useLocation, useHistory} from 'react-router-dom';
import {MessageDialog} from '@Local/Components/Dialog/';
import {NotFoundPage} from '@Local/Error/';
import {PrivateRoute} from '@Components/';
import {HQ_ROOT_ROUTER} from '@Router/routerPath';
import LandingPage from '@Local/Landing/';

const Home = () => {
    const {replace: routeReplace} = useHistory();
    const {pathname: rootPath = ''} = useLocation();


    useLayoutEffect(
        () => {
            const splitPath = rootPath.split('/');
            if (splitPath.length === 2 && splitPath.filter((x) => x).length === 0) {
                routeReplace(HQ_ROOT_ROUTER);
            } else if (splitPath.includes('index') || splitPath.includes('index.html')) {
                routeReplace('/');
            }
        },
        // eslint-disable-next-line
        [rootPath, routeReplace]
    );

    return (
        <React.Fragment>
            <Switch>
                <PrivateRoute static path={HQ_ROOT_ROUTER} as={LandingPage}/>
                <PrivateRoute path="*" as={NotFoundPage}/>
            </Switch>
            {/* <ShowPdfDialog /> */}
            <MessageDialog/>
        </React.Fragment>
    );
};

export default Home;

import React from 'react';
import SvgIcon from '../SvgIcon';
import useForkRef from '@Util/hook/useForkRef';

const Appointed = React.forwardRef((props, ref) => {
    const { className, htmlColor = '#6E4B88', ...others } = props;
    const handleRef = React.useRef(null);
    const svgRef = useForkRef(handleRef, ref);
    const defaultProperty = {
        className,
        ref: svgRef,
        htmlColor,
        ...others,
    };
    return (
        <SvgIcon {...defaultProperty}>
            <path
                d="M11.9,12.2c-0.2,2.8-1.6,4.3-4.3,4.4c-2.7-0.2-4.1-1.6-4.3-4.4c0.1-2.9,1.6-4.4,4.3-4.5
		C10.3,7.8,11.7,9.3,11.9,12.2z M9.7,12.2C9.7,10.4,9,9.4,7.6,9.4c-1.4,0.1-2.1,1-2.2,2.8C5.4,14,6.1,14.9,7.5,15
		C9,14.9,9.7,14,9.7,12.2z"
            />
            <path d="M21.1,16.4h-2.5l-2.5-3.6l-1.3,1.4v2.3h-2V7.8h2v3.9l3.6-3.9h2.4l-3.4,3.6L21.1,16.4z" />
        </SvgIcon>
    );
});

export default Appointed;

import React from 'react';
import clsx from 'clsx';
import { Typography } from '@Components/';

const directionMap = ['column', 'row'];

const ReadTextField = (props) => {
    const { className, label, children, textProps = {}, labelProps = {}, onClick, direction = directionMap[0] } = props;
    let isRow = '';

    if (directionMap.includes(direction) && direction === directionMap[1]) {
        isRow = `read-text-field-row`;
    }

    return (
        <div className={clsx('read-text-field', className, isRow)} onClick={onClick}>
            <Typography {...labelProps} className={clsx('read-text-field-label', labelProps.className)}>
                {label}
            </Typography>
            <Typography {...textProps} className={clsx('read-text-field-text', textProps.className)}>
                {children}
            </Typography>
        </div>
    );
};

export default ReadTextField;
